import "./DropDown.scss";
import {ConfigProvider, Select} from "antd";
import React from "react";

const DropDown = (props)=>{

    return (
        <ConfigProvider
            theme={{
                token: {
                    borderRadius: 12,
                    colorBorder: "#D3D4D5",
                    colorBgContainer: '#fff',
                    fontSize: 15,
                    colorPrimary: "#7688A8"

                },
                components: {
                    Select: {
                        optionFontSize: 15,
                        optionHeight: 48,
                        optionLineHeight: 2
                    }
                }
            }}
        >
            <Select
                className="dateTypeSelect"
                // showSearch
                style={{
                    width: props.width ? props.width : 124,
                    height: props.height ? props.height : 32,
                    fontSize: 13,
                    borderRadius: 6
                }}
                suffixIcon={<img width={15} src="/assets/icons/icon_shevron_down.svg" alt="down" />}
                defaultValue={props.value ?? "1"}
                value={props.value ?? "1"}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) =>
                    (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                }
                onChange={props.onChange}
                options={props.options ?? []}
            />
        </ConfigProvider>
    )
}

export default DropDown;