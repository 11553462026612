import axios from "axios";
import Cookies from "js-cookie";
import auth from "./auth";
import admin from "./admin";
import franchise from "./franchise";
import cru from "./cru";
import common from "./common";
import device from "./device";
import car from "./car";
import main from "./main";
import test from "./test.js";

export const axiosInstance = axios.create({
    baseURL: "https://admin-api.vifense.net:8080",
    // baseURL: "http://localhost:8080",
    // baseURL: "https://admin-api.vifense.com:8080",
    // baseURL: "http://172.30.1.9:8080",
    headers: {
        Authorization: `Bearer ${Cookies.get("access-token")}`
    }
});

export const nonAuthInstance = axios.create({
    baseURL: "https://admin-api.vifense.net:8080",
    // baseURL: "http://localhost:8080",
    // baseURL: "https://admin-api.vifense.com:8080",
    // baseURL: "http://172.30.1.9:8080",
});

export default {
//     서비스들
    auth, admin, franchise, cru, common, device, car, main, test
}