import "./DropDown.scss";
import React from "react";
import {ConfigProvider, Select} from "antd";
const FrnDrop = (props)=>{
    return (
        <ConfigProvider
            theme={{
                token: {
                    borderRadius: 12,
                    colorBorder: "#D3D4D5",
                    colorBgContainer: '#fff',
                    fontSize: 15,
                    colorPrimary: "#7688A8"
                },
                components: {
                    Select: {
                        optionFontSize: 15,
                        optionHeight: 48,
                        optionLineHeight: 2,
                    }
                }
            }}
        >
            <Select
                className="dateTypeSelect"
                // showSearch
                style={{
                    width: props.width ? props.width : 178,
                    height: props.height ? props.height : 48,
                    fontSize: 15
                }}
                suffixIcon={<img src="/assets/icons/icon_shevron_down.svg" alt="down" />}
                value={props.value}
                onChange={props.onChange}
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) => {
                    if (isNaN(optionA.value) && isNaN(optionB.value)) return;

                    if (optionA?.value === 1) {
                        return -1
                    } else if (optionB?.value === 1) {
                        return 1
                    } else {
                        return (optionA?.value ?? '').toString().toLowerCase().localeCompare((optionB?.value ?? '').toString().toLowerCase());
                    }
                }}
                options={props.options ?? []}
                disabled={props.readOnly}
            />
        </ConfigProvider>
    );
}

export default FrnDrop;