import Layout from "../../layout/Layout";
import {useNavigate, useLocation} from "react-router-dom";

const Error = ()=>{
    const navigate = useNavigate();
    return (
        <Layout>
            <div className="error-page-wrap">
                <div className="error-content-wrap">
                    <h1>404</h1>
                    <p>페이지를 찾을 수 없습니다</p>
                    <button onClick={()=>{navigate("/main")}}>홈으로 돌아가기</button>
                </div>

            </div>
        </Layout>
    )
}

export default Error;