import { axiosInstance, nonAuthInstance } from "../index";


const auth = {
    login: async (data) => {
        const result = await nonAuthInstance.post(`/auth/login`, data);
        return result.data.info;
    },
    findId: async (data) => {
        const result = await nonAuthInstance.post(`/admin/user/find/id`, data);
        return result.data.info;
    },
    findPwd: async (data) => {
        const result = await nonAuthInstance.post(`/admin/user/find/pwd`, data);
        return result.data.info;
    },
    findSetPwd: async (data) => {
        const result = await nonAuthInstance.post(`/admin/user/find/setpwd`, data);
        return result.data.info;
    }
}

export default auth;