import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TipInput from "../../components/Input/TipInput";
import SelectBox from "../../components/SelectBox/SelectBox";
import car from "../../services/car";
import TipDateInput from "../../components/Input/TipDateInput";
import TipAttInput from "../../components/Input/TipAttInput";
import franchise from "../../services/franchise";
import common from "../../services/common";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import translator from "../../utils/translator";
import Alert from "../../components/Alert";
import {setCurrentUrl} from "../../utils";

const CarChange = ()=>{
    const userInfo = useRecoilValue(userState);
    // 운전자 등록 데이터 없음
    const initialData = {
        carSeq: null,
        frnSeq: null,
        carNo: "",
        carNmCd: null,
        carColor: "",
        carTypeCd: null,
        carYear: null,
        useStat: "N",
        carRegDt: "",
        carPic: "",
        carSeqList: null
    }
    const { id } = useParams();
    const [file, setFile] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const [carNmList, setCarNmList] = useState([]);
    const [carTypeList, setCarTypeList] = useState([]);
    const [carYearList, setCarYearList] = useState([]);
    const [data, setData] = useState(initialData);
    const navigate = useNavigate();
    const [noneValue, setNoneValue] = useState("");
    const { state } = useLocation();
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        getFranchise();
        getCarCode();
        setNoneValue("");
        if (id === "0") return;
        if (!state || !state.carSeq){
            navigate("/error");
        }
        initialData.carSeq = state.carSeq;
        initialData.frnSeq = state.frnSeq;
        setData(initialData);
    }, [])

    const checkValue = {
        "carNo": "차량번호",
        "frnSeq": "가맹점명",
        "carNmCd": "차종",
        "carTypeCd": "연료타입",
        "carYear": "연식",
    }
    const alertClose = () => {
        setAlertShow("");
        if(alertShow === "alert") {
            navigate("/car/stat");
        }
    }
    const onCheck = () => {
        let emptyValue = [];
        Object.keys(checkValue).forEach((key) => {
            if (key === "frnSeq" || key === "carNmCd" || key === "carTypeCd" || key === "carYear") {
                if (data[key] === 0 || data[key] === null || data[key] === undefined) {
                    emptyValue.push(checkValue[key]);
                }
            } else {
                if (data[key] === "" || data[key] === null || data[key] === undefined) {
                    emptyValue.push(checkValue[key]);
                }
            }
        })

        if (emptyValue.length > 0) {
            setNoneValue(emptyValue[0]);
            setAlertMessage(`${emptyValue[0]}을(를) 입력해주세요.`);
            setAlertShow("alertValue");
            return;
        }

        onModify();
    }

    const onModify = async () => {
        // '수정하시겠습니까?'
        console.log(data)
        try{
            data.useStat = "N";
            await car.CarChange(file, data).then((res) => {
                if (res.info) {
                    // '수정되었습니다'
                    setAlertMessage("등록되었습니다.");
                    setAlertShow("alert");
                }else{
                    // fail
                    // navigate("/car/stat");
                    setAlertMessage("등록 실패하였습니다.");
                    setAlertShow("alert");
                }
            });
        }catch {
            // fail
            // navigate("car/stat");
            setAlertMessage("등록 실패하였습니다.");
            setAlertShow("alert");
        }

    }

    const onCancel = () => {
        navigate("/car/stat");
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onCheck();
        }
    }

    const onChangePic = (data) => {
        setFile(data);
        setData((prev) => {
            return ({
                ...prev,
                carPic: data.name
            })
        })
    }

    const getFranchise = async () => {
        const tempData = {
            page: 1,
            pageSize: 9999,
            prFrnSeq: state?.prFrnSeq,
            useYn: "Y"
        }
        const data = [];
        try {
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        let bool = true;
                        data.forEach((d) => {
                            if (d.value === item.frnSeq) {
                                bool = false;
                            }
                        })
                        if(bool) {
                            data.push({
                                value: item.frnSeq,
                                label: item.bizNm
                            })
                            setSelectList((prev) => prev.concat({
                                value: item.frnSeq,
                                label: item.bizNm
                            }))
                        }
                    })
                }
            });
            console.log(data);
        } catch {
            setSelectList([]);
        }
    }
    const getCarCode = async () => {
        await common.GetCode({cdId: null}).then((res)=>{
            const carTypeList = [];
            const carNmList = [];
            const carYearList = [];
            if(res.datas && res.datas.length > 0){
                res.datas.forEach((item) => {
                    if(item.cdGrp === "C"){
                        carNmList.push({
                            value: item.cdId,
                            label: item.cdVal
                        });
                    }
                    else if(item.cdGrp === "E"){
                        carTypeList.push({
                            value: item.cdId,
                            label: item.cdVal
                        })
                    }else if(item.cdGrp === "Y"){
                        carYearList.push({
                            value: item.cdVal,
                            label: item.cdVal
                        })
                    }
                });
                setCarNmList(carNmList);
                setCarTypeList(carTypeList);
                setCarYearList(carYearList);

            }
        });
    }
    return (
        <Layout>
            <div className="add-page-wrap">
                <h2>
                    신규 차량 등록
                </h2>
                <div className="add-input-wrap">
                    <TipInput
                        label="차량번호"
                        id="carNo"
                        placeholder="차량번호를 입력하세요"
                        none={noneValue === "차량번호" ? true : false}
                        value={data.carNo}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                carNo: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <SelectBox
                        label="가맹점명"
                        id="franchise"
                        placeholder="택시 조합을 선택하세요"
                        none={noneValue === "가맹점명" ? true : false}
                        readOnly={userInfo?.frnGradeCd === "F0003" ? true : false}
                        width={374}
                        height={38}
                        value={data.frnSeq}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                frnSeq: e
                            })
                        })}
                        options={selectList}
                        required
                    />
                    <SelectBox
                        label="차종"
                        id="carNmCd"
                        placeholder="차종을 선택하세요"
                        none={noneValue === "차종" ? true : false}
                        width={374}
                        height={38}
                        value={data.carNmCd}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                carNm: e,
                                carNmCd: e
                            })
                        })}
                        options={carNmList}
                        required
                    />
                    <TipInput
                        label="색상"
                        id="carColor"
                        placeholder="차량 색상을 입력하세요"
                        none={noneValue === "색상" ? true : false}
                        value={data.carColor}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                carColor: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    <SelectBox
                        label="연료타입"
                        id="carTypeCd"
                        placeholder="연료타입을 선택하세요"
                        none={noneValue === "연료타입" ? true : false}
                        width={374}
                        height={38}
                        value={data.carTypeCd}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                carType: e,
                                carTypeCd: e
                            })
                        })}
                        options={carTypeList}
                        required
                    />
                    <SelectBox
                        label="연식"
                        id="carYearCd"
                        placeholder="연식을 선택하세요"
                        none={noneValue === "연식" ? true : false}
                        width={374}
                        height={38}
                        value={data.carYear}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                carYear: e
                            })
                        })}
                        options={carYearList}
                        required
                    />
                    <TipAttInput
                        label="차량등록증"
                        id="carPic"
                        none={noneValue === "차량등록증" ? true : false}
                        value={data.carPic}
                        onChange={onChangePic}
                        text="* 차량등록증의 경우 png, jpg 형식의 파일만 업로드 가능합니다. 최대 10M"
                    />
                </div>
                <div className="btn-center-wrap">
                    <button className={"btn btn-gray"} onClick={onCancel}>
                        취소
                    </button>
                    <button className={"btn btn-navy"} onClick={onCheck}>
                        변경
                    </button>
                </div>
            </div>
            <Alert
                isShow={alertShow === "alert" || alertShow === "alertValue"}
                content={<p>{alertMsg}</p>}
                onClose={alertClose}
            />
        </Layout>
    )
}

export default CarChange;