import "./CheckBox.scss";
import React, { useState } from "react";

const CheckBox = (props) => {
    const [checked, setChecked] = useState(props.checked);

    const handleChange = (e) => {
        setChecked(e.target.checked);
        if (props.onChange) {
            props.onChange(e.target.checked);
        }
    };

    return (
        <span className="checkbox">
            <label>
                <input
                    type="checkbox"
                    name={props.name}
                    onChange={handleChange}
                    defaultChecked={checked ? true : false}
                />
                {props.label}
            </label>
        </span>
    );
}

export default CheckBox;