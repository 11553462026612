import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TipInput from "../../components/Input/TipInput";
import franchise from "../../services/franchise";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import SelectBox from "../../components/SelectBox/SelectBox";
import RadioInput from "../../components/Input/RadioInput";
import TipAttInput from "../../components/Input/TipAttInput";
import {bizRegNumHypen, noneHypen, phoneHypen, trimObject, dateformat, setCurrentUrl} from "../../utils";
import Alert from "../../components/Alert";
import DateInput from "../../components/Input/DateInput";
import dayjs from "dayjs";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const FranchiseAdd = ()=>{
    const userInfo = useRecoilValue(userState);
    const initialData = {
        regDt: "",
        updDt: "",
        frnSeq: null,
        prFrnSeq: userInfo?.frnSeq,
        frnGradeCd: null,
        bizRegNo: "",
        prBizNm: "",
        bizNm: "",
        bizType: "",
        bizPic:"",
        bizSvc: "",
        tel: "",
        fax: "",
        kakaoRegDt: "",
        kakaoRegDtUtc: "",
        kakaoOpenDt: "",
        kakaoOpenDtUtc: "",
        kakaoEndDt: "",
        kakaoEndDtUtc: "",
        useYn: "Y"
    }
    const { id } = useParams();
    const [data, setData] = useState(initialData);
    const [selectList, setSelectList] = useState([]);
    const [noneValue, setNoneValue] = useState("");
    const [myFrnGrade, setFrnGrade] = useState({
        frnGradeCd: "",
        bizNm: "",
    });
    const [files, setFiles] = useState(null);
    const navigate = useNavigate();
    const { state } = useLocation();
    const [showSelect, setShowSelect] = useState(false);
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        setFrnGrade({
            frnGradeCd: userInfo?.frnGradeCd,
            bizNm: userInfo?.bizNm,
        })
        setNoneValue("");
        getFranchise();

        if (id === "0") return;
        if (!state || state.prFrnSeq == null) navigate("/error");

        setData((prev) => {
            return ({
                ...prev,
                prBizNm: state.prBizNm,
                bizNm: state.bizNm,
                bizRegNo: state.bizRegNo,
                bizSvc: state.bizSvc,
                bizType: state.bizType,
                fax: state.fax,
                frnGradeCd: state.frnGradeCd,
                tel: state.tel,
                regDt: state.regDt,
                updDt: state.updDt,
                prFrnSeq: state.prFrnSeq,
                frnSeq: state.frnSeq,
                useYn: state.useYn,
                bizPic: state.bizPic,
                kakaoEndDt: state.kakaoEndDt,
                kakaoRegDt: state.kakaoRegDt,
                kakaoOpenDt: state.kakaoOpenDt
            })
        })
    }, [])

    useDidMountEffect(() => {
        if (id === "0") {
            if (myFrnGrade.frnGradeCd === "F0001") {
                setData((prev) => {
                    return ({
                        ...prev,
                        frnGradeCd: "F0002"
                    })
                })
            } else {
                setData((prev) => {
                    return ({
                        ...prev,
                        frnGradeCd: "F0003"
                    })
                })
            }
        }
    }, [myFrnGrade])

    useDidMountEffect(() => {
        if (id === "0") {
            if (myFrnGrade.frnGradeCd === "F0001" && data.frnGradeCd === "F0002" || myFrnGrade.frnGradeCd !== "F0001") {
                setData((prev) => {
                    return ({
                        ...prev,
                        prFrnSeq: userInfo?.frnSeq,
                    })
                })
                setShowSelect(false);
            } else {
                setData((prev) => {
                    return ({
                        ...prev,
                        prFrnSeq: 2,
                    })
                })
                setShowSelect(true);
            }
        } else {
            setShowSelect(false);
        }
    }, [data.frnGradeCd])

    const getFranchise = async () => {
        const tempData = {
            page: 1,
            pageSize: 9999,
            prFrnSeq: userInfo?.frnSeq,
            frnGradeCd: "F0002",
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        setSelectList((prev) => prev.concat({
                            value: item.frnSeq,
                            label: item.bizNm
                        }))
                    })
                }
            });
        }
        catch {
            setSelectList([]);
        }
    }

    /*const getFranchise = () => {
        const searchData = ["F0001", "F0002"];

        searchData.forEach(async (item) => {
            const tempData = {
                page: 1,
                pageSize: 9999,
                prFrnSeq: userInfo?.frnSeq,
                frnGradeCd: item,
                useYn: "Y"
            }
            try {
                await franchise.GetFranchise(tempData).then((res) => {
                    if (res.datas && res.datas.length > 0) {
                        res.datas.forEach((item) => {
                            setSelectList((prev) => prev.concat({
                                value: item.frnSeq,
                                label: item.bizNm
                            }))
                        })
                    }
                });
            } catch {
                setSelectList([]);
            }
        });
    }*/

    const checkValue = {
        "bizNm": "가맹점명",
        "bizRegNo": "사업자 등록번호",
        "bizType": "업태",
        "bizSvc": "종목",
    }

    const onCheck = () => {
        let emptyValue = [];

        Object.keys(checkValue).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined ) {
                emptyValue.push(checkValue[key]);
            }
        })

        if (emptyValue.length > 0) {
            setNoneValue(emptyValue[0]);
            setAlertMessage(`${emptyValue[0]}을(를) 입력해주세요.`);
            setAlertShow("alert");
            return;
        }

        let tempData = {
            ...data,
            bizRegNo: noneHypen(data.bizRegNo),
            tel: noneHypen(data.tel),
            fax: noneHypen(data.fax)
        }

        tempData = trimObject(tempData);

        if (id === "0") {
            onRegister(tempData);
        } else {
            onModify(tempData);
        }
    }

    const onRegister = async (tempData) => {
        try {
            await franchise.AddFranchise(files, tempData).then((res) => {
                if (res && res.info) {
                    setAlertMessage("등록되었습니다.");
                    setAlertShow("alertMove");
                } else {
                    setAlertMessage("등록 실패하였습니다.");
                    setAlertShow("alert");
                }
            });
        }
        catch {
            setAlertMessage("등록 실패하였습니다.");
            setAlertShow("alert");
        }
    }

    const onModify = async (tempData) => {
        try {
            await franchise.ModifyFranchise(files, tempData).then((res) => {
                if (res && res.info) {
                    setAlertMessage("수정되었습니다.");
                    setAlertShow("alertMove");
                }
                else {
                    setAlertMessage("수정 실패하였습니다.");
                    setAlertShow("alert");
                }
            });
        }
        catch {
            setAlertMessage("수정 실패하였습니다.");
            setAlertShow("alert");
        }
    }

    const alertClose = () => {
        setAlertShow("");
        if(alertShow === "alertMove") {
            navigate("/franchise");
        }
    }

    const onCancel = () => {
        navigate("/franchise");
    }

    const onChangePic = (data) => {
        setFiles(data);
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onCheck();
        }
    }

    return (
        <Layout>
            <div className="add-page-wrap">
                {id === "0" &&
                    <h2>
                        가맹점 등록
                    </h2>
                }
                {id !== "0" &&
                    <h2>
                        가맹점 수정
                    </h2>
                }
                <div className="add-input-wrap">
                    <RadioInput
                        label="가맹점 유형"
                        value={data.frnGradeCd}
                        defaultValue={id === "0" && myFrnGrade.frnGradeCd === "F0001" ? "F0002" : "F0003"}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                frnGradeCd: e.target.value
                            })
                        })}
                        options={myFrnGrade.frnGradeCd === "F0001" ?
                            [
                                {value: "F0002", label: "지역 가맹 본부"},
                                {value: "F0003", label: "법인"},
                                {value: "F0004", label: "개인"},
                            ] : [
                                {value: "F0003", label: "법인"},
                                {value: "F0004", label: "개인"},
                            ]
                        }
                        required
                        readOnly={id !== "0"}
                    />
                    { showSelect ?
                        <SelectBox
                            label="소속"
                            id="prFrnSeq"
                            placeholder="택시 조합을 선택하세요"
                            none={noneValue === "소속" ? true : false}
                            width={374}
                            height={38}
                            value={data.prFrnSeq}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    prFrnSeq: e
                                })
                            })}
                            options={selectList}
                            required
                        /> :
                        <TipInput
                            label="소속"
                            value={id === "0" ? myFrnGrade.bizNm : data.prBizNm}
                            readOnly
                            required
                        />
                    }
                    <TipInput
                        label="가맹점명"
                        id="bizNm"
                        placeholder="가맹점명을 입력하세요"
                        none={noneValue === "가맹점명" ? true : false}
                        value={data.bizNm}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                bizNm: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <TipInput
                        label="사업자 등록번호"
                        id="bizRegNo"
                        placeholder="사업자 등록번호를 입력하세요"
                        none={noneValue === "사업자 등록번호" ? true : false}
                        value={bizRegNumHypen(data.bizRegNo)}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                bizRegNo: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <TipInput
                        label="업태"
                        id="bizType"
                        placeholder="업태를 입력하세요"
                        none={noneValue === "업태" ? true : false}
                        value={data.bizType}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                bizType: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <TipInput
                        label="종목"
                        id="bizSvc"
                        placeholder="종목을 입력하세요"
                        none={noneValue === "종목" ? true : false}
                        value={data.bizSvc}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                bizSvc: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <TipInput
                        label="전화번호"
                        id="tel"
                        placeholder="전화번호를 입력하세요"
                        none={noneValue === "전화번호" ? true : false}
                        value={phoneHypen(data.tel)}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                tel: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    <TipInput
                        label="팩스"
                        id="fax"
                        placeholder="팩스번호를 입력하세요"
                        value={phoneHypen(data.fax)}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                fax: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    <DateInput
                        hasLabel
                        type="wide"
                        label="카카오T블루 개시일"
                        placeholder="카카오T블루 개시일을 입력하세요"
                        value={(data.kakaoOpenDtUtc) ? (data.kakaoOpenDtUtc) : (data.kakaoOpenDt ? dayjs(data.kakaoOpenDt, 'YYYY/MM/DD') : "")}
                        onChange={(e) => setData((prev) => {
                            if (!e) {
                                return ({
                                    ...prev,
                                    kakaoOpenDtUtc: "",
                                    kakaoOpenDt: ""
                                })
                            } else {
                                return ({
                                    ...prev,
                                    kakaoOpenDtUtc: e,
                                    kakaoOpenDt: dateformat(e)
                                })
                            }
                        })}
                    />
                    <DateInput
                        hasLabel
                        type="wide"
                        label="카카오T블루 계약일"
                        placeholder="카카오T블루 계약일을 입력하세요"
                        value={(data.kakaoRegDtUtc) ? (data.kakaoRegDtUtc) : (data.kakaoRegDt ? dayjs(data.kakaoRegDt, 'YYYY/MM/DD') : "")}
                        onChange={(e) => setData((prev) => {
                            if (!e) {
                                return ({
                                    ...prev,
                                    kakaoRegDtUtc: "",
                                    kakaoRegDt: ""
                                })
                            } else {
                                return ({
                                    ...prev,
                                    kakaoRegDtUtc: e,
                                    kakaoRegDt: dateformat(e)
                                })
                            }
                        })}
                    />
                    <DateInput
                        hasLabel
                        type="wide"
                        label="카카오T블루 종료일"
                        placeholder="카카오T블루 종료일을 입력하세요"
                        value={(data.kakaoEndDtUtc) ? (data.kakaoEndDtUtc) : (data.kakaoEndDt ? dayjs(data.kakaoEndDt, 'YYYY/MM/DD') : "")}
                        onChange={(e) => setData((prev) => {
                            if (!e) {
                                return ({
                                    ...prev,
                                    kakaoEndDtUtc: "",
                                    kakaoEndDt: ""
                                })
                            } else {
                                return ({
                                    ...prev,
                                    kakaoEndDtUtc: e,
                                    kakaoEndDt: dateformat(e)
                                })
                            }
                        })}
                    />
                    <TipAttInput
                        label="사업자 등록증"
                        id="bizPic"
                        none={noneValue === "사업자등록증" ? true : false}
                        filePath={data.bizPic}
                        onChange={onChangePic}
                        text="* 사업자등록증의 경우 png, jpg 형식의 파일만 업로드 가능합니다. 최대 10M"
                    />
                </div>
                <div className="btn-center-wrap">
                    <button className={"btn btn-gray"} onClick={onCancel}>
                        취소
                    </button>
                    <button className={"btn btn-navy"} onClick={onCheck}>
                        {id === "0" ? "등록" : "수정"}
                    </button>
                </div>
            </div>
            <Alert
                isShow={alertShow === "alert" || alertShow === "alertMove"}
                content={<p>{alertMsg}</p>}
                onClose={alertClose}
            />
        </Layout>
    )
}

export default FranchiseAdd;