import "./SearchCard.scss";
import FrnDrop from "../DropDown/FrnDrop";
import FrnTypeDrop from "../DropDown/FrnTypeDrop";
import FrnSearchDrop from "../DropDown/FrnSearchDrop";
import DateTypeDrop from "../DropDown/DateTypeDrop";
import Button from "../Button";
import React from "react";

const SearchCard2 = (props)=>{
    return (
        <div className="search-card2-wrap">
            <div className="search-card2-search">
                <h2>기간 검색</h2>
                <DateTypeDrop
                    options={props.dateList}
                    value={props.dateValue}
                    onChangeType={props.onChangeDateType}
                    onChange={props.onChangeDate}
                    startValue={props.startValue}
                    endValue={props.endValue}
                />
                <Button buttonType="normal" content="적용" onClick={props.onClick} />
            </div>
            <div className="search-card2-score">
                <div className="score-block">
                    <div>안전 운전 점수</div>
                    <p><span className="color-blue">{props.value.driveScore}</span>점</p>
                </div>
                <div className="score-block">
                    <div>공기 관리 점수</div>
                    <p><span className="color-green">{props.value.dustScore}</span>점</p>
                </div>
                <div className="score-block">
                    <div>운행거리</div>
                    <p><span className="color-purple">{props.value.distance}</span>km</p>
                </div>
                <div className="score-block">
                    <div>운행시간</div>
                    <p><span className="color-purple">{props.value.termTime}</span>시간</p>
                </div>
                <div className="score-block">
                    <div>운행횟수</div>
                    <p><span className="color-purple">{props.value.driveCnt}</span>회</p>
                </div>
            </div>
            <div className="search-card2-warning">
                <div className="warning-block warning-title">
                    <div>위험 운전</div>
                    <p><span>{props.value.dangerousScore}</span>회</p>
                </div>
                <div className="warning-block warning-item">
                    <div>
                        <p>급가속</p>
                        <p><span>{props.value.sdAcceleration}</span>회</p>
                    </div>
                    <div>
                        <p>급감속</p>
                        <p><span>{props.value.sdBreak}</span>회</p>
                    </div>
                </div>
                <div className="warning-block warning-item">
                    <div>
                        <p>급출발</p>
                        <p><span>{props.value.sdStart}</span>회</p>
                    </div>
                    <div>
                        <p>급정지</p>
                        <p><span>{props.value.sdStop}</span>회</p>
                    </div>
                </div>
                <div className="warning-block warning-item">
                    <div>
                        <p>급진로변경</p>
                        <p><span>{props.value.sdLane}</span>회</p>
                    </div>
                    <div>
                        <p>급좌우회전</p>
                        <p><span>{props.value.sdSpin}</span>회</p>
                    </div>
                </div>
                <div className="warning-block warning-item">
                    <div>
                        <p>급U턴</p>
                        <p><span>{props.value.sdUturn}</span>회</p>
                    </div>
                    <div style={{ height: "24px" }}>
                        {/*<p>급앞지르기</p>
                        <p><span>{props.value.sdOvertaking}</span>회</p>*/}
                    </div>

                </div>
            </div>
        </div>
    )
}

export default SearchCard2;