import Header from "../components/Header";
//props.children: ReactNode;
const Layout = (props)=>{
    return (
        <div className="common-layout">
            <Header />
            <main>
                {props.children}
            </main>
            {/*<Footer />*/}
        </div>
    )
}

export default Layout;