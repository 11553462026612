import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import {Table} from "antd";
import translator from "../../utils/translator";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import ContentCard from "../../components/ContentCard";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import {
    dateSorter,
    getGraphData,
    getGraphLabel,
    numberFormat,
    phoneHypen, setCurrentUrl,
    stringSorter,
    timeFormat
} from "../../utils";
import {useLocation, useNavigate} from "react-router-dom";
import SearchCard2 from "../../components/SearchCard/SearchCard2";
import CruModal from "../../components/Modal/CruModal";
import dayjs from "dayjs";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import main from "../../services/main";
import cru from "../../services/cru";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const driveHeader = ["aggDay", "carNo", "driveScore", "sd" , "distance"];
const childDriveHeader = ["sdAcceleration", "sdStart", "sdBreak", "sdStop", "sdLane", "sdSpin", "sdUturn"];
const dustHeader = ["aggDay", "carNo", "dustScore", "termTime", "air"];
const childAirHeader = ["미세먼지", "VOC"];
const childVocHeader = ["voc1", "voc2" , "voc3"];
const childDustHeader = ["dust1", "dust2", "dust3"];
const driveExcelHeaders = ["날짜", "차량번호", "안전운전점수(점)", "급가속", "급출발", "급감속", "급정지", "급진로변경", "급좌우회전", "급U턴", "운행거리(km)"];
const airExcelHeaders = ["날짜", "차량번호", "공기관리점수(점)", "운행시간(시간)", "미세먼지(좋음)", "미세먼지(보통)", "미세먼지(나쁨)", "VOC(좋음)", "VOC(보통)", "VOC(나쁨)"];

const CruDetail = ()=>{
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    const initialData = {
        regDt: "",
        updDt: "",
        cruSeq: null,
        carSeq: null,
        frnSeq: null,
        cruNm: "",
        cruHp: "",
        kakaoId: "",
        uniformSize: "",
        minNum: "",
        postNum: "",
        address1: "",
        address2: "",
        email: "",
        licenseNum: "",
        bankNm: "",
        accountNm: "",
        accountNum: "",
        useYn: "",
        rownum: null,
        frnGradeCd: "",
        bizNm: "",
        prFrnSeq: null,
        prBizNm: "",
        carNo: "",
    }
    const initialDriveAndDust = {
        sdAcceleration: 0,
        sdStart: 0,
        sdBreak: 0,
        sdStop: 0,
        sdLane: 0,
        sdOvertaking: 0,
        sdSpin: 0,
        sdUturn: 0,
        distance: 0, //주행거리
        driveScore: 0, //안전 운전 점수
        dustScore: 0, //공기질 점수
        termTime: 0, //주행시간
        dangerousScore: 0, //위험운전횟수
        driveCnt: 0 //주행횟수
    }
    const navigate = useNavigate();
    const [data, setData] = useState(initialData);
    const [date, setDate] = useState({
        startAggDay: dayjs(yesterday).format("YYYY-MM-DD"),
        endAggDay: dayjs(yesterday).format("YYYY-MM-DD"),
    });
    const [searchData, setSearchData] = useState(initialDriveAndDust);
    const [modalData, setModalData] = useState({});
    const [driveList,setDriveList] = useState([]);
    const [dustList,setDustList] = useState([]);
    const [driveGraph, setDriveGraph] = useState({
        labels: [],
        datasets: []
    });
    const [airGraph, setAirGraph] = useState({
        labels: [],
        datasets: []
    });
    const [driveGraphAgg, setDriveGraphAgg] = useState(0);
    const [airGraphAgg, setAirGraphAgg] = useState(0);
    const [selectDateType, setDateType] = useState('daily');
    const [tab, setTab] = useState("drive");
    const [excelData, setExcel] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [modalShow, setModalShow] = useState(false);
    const [page, setPageNum] = useState(1);
    const { state } = useLocation();

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        if(!state || !state.cruSeq || state.cruSeq == ""){
            navigate("/error");
        }else{
            setData(state);
            const temp = {
                ...date,
                frnSeq: state.frnSeq,
                cruSeq: state.cruSeq,
                carSeq: state.carSeq,
                prFrnSeq: state.prFrnSeq,
            }
            fetchDriveScoreCru(temp);
            fetchAirScoreCru(temp);
            fetchDriveData(temp);
            fetchDustData(temp);
            fetchDriveScore(temp);
            fetchDustScore(temp);
            fetchDriveCnt(temp);
        }
    }, [])

    useDidMountEffect(() => {
        setSelectedRowKeys([]);
    }, [page])

    useDidMountEffect(() => {
        setDate((prev) => {
            return ({
                ...prev,
                startAggDay: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01"),
                endAggDay: dayjs(yesterday).format("YYYY-MM-DD")
            })
        })
    }, [selectDateType])

    const searchList = (page) => {
        const temp = {
            ...date,
            frnSeq: state.frnSeq,
            cruSeq: state.cruSeq,
            carSeq: state.carSeq,
            prFrnSeq: state.prFrnSeq,
        }
        setPageNum(page);
        fetchDriveData(temp);
        fetchDustData(temp);
        fetchDriveScore(temp);
        fetchDustScore(temp);
        fetchDriveCnt(temp);
    }

    const fetchDriveScoreCru = async (dashTemp) => {
        const endAggDay = new Date(dashTemp.endAggDay);
        const beforeAweek = new Date(endAggDay.setDate(endAggDay.getDate() - 7));
        const temp = {
            ...dashTemp,
            startAggDay: dayjs(beforeAweek).format("YYYY-MM-DD"),
        }
        try {
            let dataSet = [];
            await cru.safetyDriveScoreCru(temp).then((res) => {
                if (res?.info?.cru.length > 0) {
                    // 안전 운전 그래프 (운수사)
                    const bizData = getGraphData(res.info.cru, temp.startAggDay);
                    dataSet.push({
                        ...bizData[0],
                        backgroundColor: "#39ADFF",
                        borderColor: ["#39ADFF"],
                        borderWidth: 1,
                        pointBorderWidth: 2
                    });
                }
                if (res?.info?.frn.length > 0) {
                    // 운수사 평균값
                    const aggData = getGraphData(res.info.frn, temp.startAggDay);
                    dataSet.push({
                        label: state.bizNm,
                        data: aggData[0].data,
                        backgroundColor: "#e7e7e7", // 그라데이션을 배경색으로 설정
                        borderColor: ["#e7e7e7"],
                        borderWidth: 1,
                        pointBorderWidth: 2
                    });
                }
            });

            // 전체 평균값
            await cru.safetyDriveScoreSum(temp).then((res) => {
                if (res?.info) {
                    setDriveGraphAgg(Math.round(res.info.driveScore) ?? "0");
                } else {
                    setDriveGraphAgg("0");
                }
            });

            // labels
            const label = getGraphLabel(temp.startAggDay, temp.endAggDay);
            setDriveGraph({
                labels: label,
                datasets: dataSet
            })
        } catch {
            setDriveGraph({
                labels: [],
                datasets: []
            })
        }
    }

    const fetchAirScoreCru = async (dashTemp) => {
        const endAggDay = new Date(dashTemp.endAggDay);
        const beforeAweek = new Date(endAggDay.setDate(endAggDay.getDate() - 7));
        const temp = {
            ...dashTemp,
            startAggDay: dayjs(beforeAweek).format("YYYY-MM-DD"),
        }
        try {
            let dataSet = [];
            await cru.safetyAirScoreCru(temp).then((res) => {
                if (res?.info?.cru.length > 0) {
                    // 안전 운전 그래프 (운수사)
                    const bizData = getGraphData(res.info.cru, temp.startAggDay);
                    dataSet.push({
                        ...bizData[0],
                        backgroundColor: "#42d55a",
                        borderColor: ["#42d55a"],
                        borderWidth: 1,
                        pointBorderWidth: 2
                    });
                }
                if (res?.info?.frn.length > 0) {
                    // 운수사 평균값
                    const aggData = getGraphData(res.info.frn, temp.startAggDay);
                    dataSet.push({
                        label: state.bizNm,
                        data: aggData[0].data,
                        backgroundColor: "#e7e7e7", // 그라데이션을 배경색으로 설정
                        borderColor: ["#e7e7e7"],
                        borderWidth: 1,
                        pointBorderWidth: 2
                    });
                }
            });
            // 전체 평균값
            await cru.safetyAirScoreSum(temp).then((res) => {
                if (res?.info) {
                    setAirGraphAgg(Math.round(res.info.dustScore) ?? "0");
                } else {
                    setAirGraphAgg("0");
                }
            });
            // labels
            const label = getGraphLabel(temp.startAggDay, temp.endAggDay);
            setAirGraph({
                labels: label,
                datasets: dataSet
            })
        } catch {
            setAirGraphAgg("0");
            setAirGraph({
                labels: [],
                datasets: []
            })
        }
    }

    const fetchDriveData = async (temp) => {
        try {
            await cru.safetyDriveScoreSelect(temp).then(async (res) => {
                if (res.datas && res.datas.length > 0) {
                    setDriveList(res.datas.map((item) => {
                        return {
                            ...item,
                            key: item.aggDay,
                            carNo: state.carNo
                        }
                    }));
                } else {
                    setDriveList([]);
                }
            });
        } catch {
            setDriveList([]);
        }
        setSelectedRowKeys([]);
        setExcel([]);
    }

    const fetchDustData = async (temp) => {
        try {
            await cru.safetyAirScoreSelect(temp).then(async (res) => {
                if (res.datas && res.datas.length > 0) {
                    setDustList(res.datas.map((item, i) => {
                        return {
                            ...item,
                            key: item.aggDay,
                            carNo: state.carNo
                        }
                    }));
                } else {
                    setDustList([]);
                }
            });
        } catch {
            setDustList([]);
        }
        setSelectedRowKeys([]);
        setExcel([]);
    }

    const fetchDriveScore = async (data) => {
        try {
            await cru.safetyDriveScoreSum(data).then((res) => {
                setSearchData((prev) => {
                    return ({
                        ...prev,
                        sdAcceleration: numberFormat(res.info?.sdAcceleration ?? 0),
                        sdStart: numberFormat(res.info?.sdStart ?? 0),
                        sdBreak: numberFormat(res.info?.sdBreak ?? 0),
                        sdStop: numberFormat(res.info?.sdStop ?? 0),
                        sdLane: numberFormat(res.info?.sdLane ?? 0),
                        // sdOvertaking: numberFormat(res.info?.sdOvertaking ?? 0),
                        sdSpin: numberFormat(res.info?.sdSpin ?? 0),
                        sdUturn: numberFormat(res.info?.sdUturn ?? 0),
                        distance: numberFormat(res.info?.distance ?? 0),
                        driveScore: numberFormat(Math.round(res.info?.driveScore ?? 0)),
                        dangerousScore: sumSd(res.info ?? {}),
                    })
                })
            });
        } catch {
            setSearchData(initialDriveAndDust);
        }
    }

    const fetchDustScore = async (data) => {
        try {
            await cru.safetyAirScoreSum(data).then((res) => {
                if (res?.info) {
                    setSearchData((prev) => {
                        return ({
                            ...prev,
                            dustScore: numberFormat(Math.round(res.info.dustScore)),
                            termTime: numberFormat(Math.floor(res.info.termTime / 60 / 60)),
                        })
                    })
                } else {
                    setSearchData((prev) => {
                        return ({
                            ...prev,
                            dustScore: 0,
                            termTime: 0,
                        })
                    })
                }
            });
        } catch {
            setSearchData(initialDriveAndDust);
        }
    }

    const fetchDriveCnt = async (temp) => {
        try{
            await cru.driveStatCru(temp).then((res)=>{
                if(res?.datas && res.datas.length > 0) {
                    setSearchData((prev) => {
                        return ({
                            ...prev,
                            driveCnt: numberFormat(Math.round(res.datas[0]?.numCnt)),
                        })
                    })
                } else {
                    setSearchData((prev) => {
                        return ({
                            ...prev,
                            driveCnt: 0,
                        })
                    })
                }
            });
        }catch (e){
            setSearchData((prev) => {
                return ({
                    ...prev,
                    driveCnt: 0,
                })
            })
        }
    }

    const onSearch = () => {
        searchList(1);
    }

    const sumSd = (sd) => {
        let cnt = 0;
        Object.keys(sd).forEach((key) => {
            if (key.includes("sd")) {
                cnt += sd[key];
            }
        })
        return cnt;
    }

    const onShowModal = () => {
        if (data.carSeq > 0) {
            navigate('/car/detail/'+data.carSeq, { state:data });
        }
    }

    const onExcelDownload = async () => {
        try {
            const wb = new Excel.Workbook();
            const sheet = wb.addWorksheet(tab === "drive" ? `안전운전기록(${data.cruNm})` : `공기관리기록(${data.cruNm})`);
            const headerRow = sheet.addRow(tab === "drive" ? driveExcelHeaders : airExcelHeaders);
            headerRow.height = 30.75;
            headerRow.eachCell((cell, colNum) => {
                sheet.getColumn(colNum).width = 20;
                cell.font = {
                    bold: true,
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });

            let selectedData = excelData;
            if (selectedRowKeys.length === 0) {
                if (tab === "drive") {
                    selectedData = driveList;
                } else {
                    selectedData = dustList;
                }
            }

            const datas = [];
            let headers = [];
            if (tab === "drive") {
                headers = ["aggDay", "carNo", "driveScore", "sdAcceleration", "sdStart", "sdBreak", "sdStop", "sdLane", "sdSpin", "sdUturn" , "distance"];
            } else {
                headers = ["aggDay", "carNo", "dustScore", "termTime", "dust1", "dust2", "dust3", "voc1", "voc2" , "voc3" ]
            }

            selectedData.forEach((v)=>{
                const row = {};
                Object.keys(v).forEach((key)=>{
                    if(headers.includes(key)){
                        if (key === "termTime") {
                            row[key] = timeFormat(v[key]);
                        } else if (key.includes("Score")) {
                            row[key] = `${v[key]}점`;
                        } else if (key.includes("voc")) {
                            row[key] = `${Math.round(v[key] * 100 / (v.termTime-v.voc0))}%`;
                        } else if (key.includes("dust")) {
                            row[key] = `${Math.round(v[key] * 100 / (v.termTime-v.dust0))}%`;
                        } else {
                            row[key] = v[key];
                        }
                    }
                });
                datas.push(row);
            });

            datas.forEach((item) => {
                const rowDatas = headers.map(key => item[key]);
                const appendRow = sheet.addRow(rowDatas);
                appendRow.eachCell((cell, colNum) => {
                    cell.alignment = {
                        horizontal: "center",
                    }
                });
            });

            const fileData = await wb.xlsx.writeBuffer();
            const blob = new Blob([fileData], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, tab === "drive" ? `안전운전기록(${data.cruNm})` : `공기관리기록(${data.cruNm})`);
        } catch (error) {
        }
    }

    const onClickList = async (record) => {
        setModalData(record);
        setModalShow(true);
    }

    const onPageCar = (row) => {
        navigate('/car/detail/'+data.carSeq, { state : row });
    }

    const onChangeTab = (value) => {
        setPageNum(1);
        setExcel([]);
        switch (value) {
            case "drive" :
                setTab("drive");
                break;
            case "air" :
                setTab("air");
                break;
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, data) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setExcel(data);
        },
        fixed: true
    };

    const driveColumns = driveHeader.map((key) => {
        if (key === "aggDay") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onClickList(record);
                        },
                    };
                },
            }
        } else if (key === "carNo") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (v, d) => {
                    return <div className="table-text-blue" onClick={() => onPageCar(d)}>{v}</div>
                }
            }
        } else if (key === "sd") {
            return {
                title: translator[key],
                children: childDriveHeader.map((k) => {
                    return {
                        title: translator[k],
                        dataIndex: k,
                        key : k,
                        width: '110px',
                        sorter: (a, b) => Number(a[k]) - Number(b[k]),
                        align: 'center',
                        onCell: (record) => {
                            return {
                                onClick: () => {
                                    onClickList(record);
                                },
                            };
                        },
                        render: (v) => {
                            return <div>{v}회</div>
                        }
                    }
                }),
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '110px',
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onClickList(record);
                        },
                    };
                },
                render: (v) => {
                    if (key.includes("Score"))  {
                        return <div>{numberFormat(Math.round(v))}점</div>
                    } else {
                        return <div>{numberFormat(Math.round(v))}km</div>
                    }
                }
            }
        }
    });

    const childVocAndDustHeader = (key) => {
        if (key === "VOC") {
            return childVocHeader;
        } else {
            return childDustHeader;
        }
    }

    const dustColumns = dustHeader.map((key) => {
        if (key === "aggDay") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '130px',
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onClickList(record);
                        },
                    };
                },
            }
        } else if (key === "carNo") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '130px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (v, d) => {
                    return <div className="table-text-blue" onClick={() => onPageCar(d)}>{v}</div>
                }
            }
        } else if (key === "dustScore") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '110px',
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onClickList(record);
                        },
                    };
                },
                render: (v) => <div>{numberFormat(Math.round(v))}점</div>
            }
        } else if (key === "termTime") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '110px',
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onClickList(record);
                        },
                    };
                },
                render: (v) => <div>{timeFormat(v)}</div>
            }
        } else if (key === "air") {
            return {
                title: translator[key],
                children: childAirHeader.map((k) => {
                    return {
                        title: k,
                        children: childVocAndDustHeader(k).map((keyItem) => {
                            return {
                                title: translator[keyItem],
                                dataIndex: keyItem,
                                key : keyItem,
                                width: '110px',
                                sorter: (a, b) => {
                                    let aVal, bVal;
                                    if (keyItem.includes("voc")) {
                                        aVal = a[keyItem] * 100 / (a.termTime-a.voc0) ?? 0;
                                        bVal = b[keyItem] * 100 / (b.termTime-b.voc0) ?? 0;
                                    } else {
                                        aVal = a[keyItem] * 100 / (a.termTime-a.dust0) ?? 0;
                                        bVal = b[keyItem] * 100 / (b.termTime-b.dust0) ?? 0;
                                    }
                                    return Number(isNaN(aVal) ? 0 : aVal) - Number(isNaN(bVal) ? 0 : bVal)
                                },
                                align: 'center',
                                onCell: (record) => {
                                    return {
                                        onClick: () => {
                                            onClickList(record);
                                        },
                                    };
                                },
                                render: (v, d) => {
                                    if (v === 0) {
                                        return <div>0%</div>
                                    }
                                    if (keyItem.includes("voc")) {
                                        return <div>{(v * 100 / (d.termTime-d.voc0)).toFixed(1)}%</div>
                                    } else {
                                        return <div>{(v * 100 / (d.termTime-d.dust0)).toFixed(1)}%</div>
                                    }
                                }
                            }
                        }),
                    }
                }),
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '110px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onClickList(record);
                        },
                    };
                },
            }
        }
    });

    return (
        <Layout>
            <h2 className="detail-title">기사 상세 보기</h2>
            <div className="profile-wrap">
                <div className="profile-picture">
                    <img src="/assets/images/defaultCru.png"/>
                </div>
                <div className="profile-detail">
                    <div>
                        <div className="profile-title" style={{ borderTopLeftRadius: '16px' }}>소속</div>
                        <div>{data.prBizNm}</div>
                    </div>
                    <div>
                        <div className="profile-title">가맹점명</div>
                        <div>{data.bizNm}</div>
                    </div>
                    <div>
                        <div className="profile-title">이름</div>
                        <div>{data.cruNm}</div>
                    </div>
                    <div>
                        <div className="profile-title">연락처</div>
                        <div>{phoneHypen(data.cruHp)}</div>
                    </div>
                    <div>
                        <div className="profile-title">차량번호</div>
                        {data.carSeq > 0 ?
                            <div className="text-blue" onClick={onShowModal}>{data.carNo}</div>
                            : <div></div>
                        }
                    </div>
                    <div>
                        <div className="profile-title">자격번호</div>
                        <div>{data.licenseNum}</div>
                    </div>
                    <div>
                        <div className="profile-title">등록일</div>
                        <div>{dayjs(data.regDt).format("YYYY년 MM월 DD일")}</div>
                    </div>
                    <div>
                        <div className="profile-title">주소</div>
                        <div style={{ padding: '0px 24px' }}>
                            {data?.postNum && `[${data.postNum}] ${data.address1} ${data.address2}`}
                        </div>
                    </div>
                    <div className="bottom-none">
                        <div className="profile-title" style={{ borderBottomLeftRadius: '16px' }}>제복사이즈</div>
                        <div>{data.uniformSize}</div>
                    </div>
                    <div className="bottom-none">
                        <div className="profile-title">계좌정보</div>
                        <div>{data?.accountNm && `${data.accountNm} | ${data.bankNm} ${data.accountNum}`}</div>
                    </div>
                </div>
            </div>
            <div className="cru-list-wrap">
                <ContentCard
                    cardType="wide-graph"
                    title="안전 운전 그래프"
                    graphData={driveGraph}
                    aggValue={driveGraphAgg}
                    tooltipColor="#a0d7ff"
                    color="#39ADFF"
                />
                <ContentCard
                    cardType="wide-graph"
                    title="공기 관리 그래프"
                    graphData={airGraph}
                    aggValue={airGraphAgg}
                    tooltipColor="#6bee98"
                    color="#42D55A"
                />
            </div>
            <SearchCard2
                onClick={onSearch}
                value={searchData}
                dateList={[
                    {
                        value: 'monthly',
                        label: '월간',
                    },
                    {
                        value: 'daily',
                        label: '일간',
                    },
                ]}
                dateValue={selectDateType}
                onChangeDateType={(value) => {
                    setDateType(value);
                }}
                startValue={date.startAggDay}
                endValue={date.endAggDay}
                onChangeDate={(value, dateString) => {
                    setDate((prev) => {
                        if (value) {
                            let endDt = dayjs(value[1]);
                            var lastDay = new Date(endDt.format("YYYY"), endDt.format("MM"), 0);
                            return ({
                                ...prev,
                                startAggDay: dateString[0].split("-").length == 2 ? dateString[0]+"-01" : dateString[0],
                                endAggDay: dateString[1].split("-").length == 2 ? dayjs(lastDay).format("YYYY-MM-DD") : dateString[1]
                            })
                        } else {
                            return ({
                                ...prev,
                                startAggDay: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01"),
                                endAggDay: dayjs(yesterday).format("YYYY-MM-DD")
                            })
                        }
                    })
                }}
            />
            <div className="cru-field-wrap" style={{ marginTop: '20px' }}>
                <div className="tap-menu-wrap">
                    <div className={tab === "drive" ? "active" : ""} onClick={() => onChangeTab("drive")}>안전운전기록</div>
                    <div className={tab === "air" ? "active" : ""} onClick={() => onChangeTab("air")}>공기관리기록</div>
                </div>
                <div className="cru-wrap">
                    <p className="cru-total-count">총 <span>{tab === "drive" ? driveList.length : dustList.length}</span>회</p>
                    <div className="cru-btn-wrap">
                        <button className={"btn-small btn-line-gray"} onClick={onExcelDownload}>
                            <img src="/assets/icons/icon_download_excel.svg"></img>
                            <span>엑셀다운로드</span>
                        </button>
                    </div>
                </div>
                <div className="cru-detail-table">
                    { tab === "drive" ?
                        <Table
                            size={"small"}
                            dataSource={driveList}
                            columns={driveColumns}
                            style={{ cursor: "pointer" }}
                            pagination={{
                                current: page,
                                onChange: (page) => setPageNum(page),
                                pageSize: 10,
                                total: driveList.length,
                                position: ["bottomCenter"],
                                showSizeChanger: false,
                            }}
                            rowSelection={rowSelection}
                        />
                        :
                        <Table
                            dataSource={dustList}
                            size={"small"}
                            columns={dustColumns}
                            style={{ cursor: "pointer" }}
                            pagination={{
                                current: page,
                                onChange: (page) => setPageNum(page),
                                pageSize: 10,
                                total: dustList.length,
                                position: ["bottomCenter"],
                                showSizeChanger: false,
                            }}
                            rowSelection={rowSelection}
                        />
                    }
                </div>
            </div>
            <CruModal
                isShow={modalShow}
                onClose={(func) => {
                    func();
                    setModalShow(false);
                }}
                data={modalData}
                tab={tab}
            />
        </Layout>
    )
}

export default CruDetail;