import "./SearchCard.scss";
import FrnDrop from "../DropDown/FrnDrop";
import FrnTypeDrop from "../DropDown/FrnTypeDrop";
import FrnSearchDrop from "../DropDown/FrnSearchDrop";
import DateTypeDrop from "../DropDown/DateTypeDrop";
import Button from "../Button";
import React from "react";

const SearchCard3 = (props)=>{
    return (
        <div className="search-card3-wrap">
            <div className="search-card3-search">
                <h2>기간 검색</h2>
                <DateTypeDrop
                    options={props.dateList}
                    value={props.dateValue}
                    onChangeType={props.onChangeDateType}
                    onChange={props.onChangeDate}
                    startValue={props.startValue}
                    endValue={props.endValue}
                />
                <Button buttonType="normal" content="적용" onClick={props.onClick} />
            </div>
            <div className="search-card3-score">
                <div className="score-block">
                    <div>공기 관리 점수</div>
                    <p><span className="color-green">{props.airScore ? props.airScore : 0}</span>점</p>
                </div>
                <div className="score-block">
                    <div>운행거리</div>
                    <p><span className="color-purple">{props.distance? props.distance.toLocaleString() : 0}</span>km</p>
                </div>
                <div className="score-block">
                    <div>운행시간</div>
                    <p><span className="color-purple">{props.driveTime ? props.driveTime : 0}</span>시간</p>
                </div>
                <div className="score-block">
                    <div>진단이력</div>
                    <p><span className="color-red">{props.dtcCount? props.dtcCount.toLocaleString() : 0}</span>건</p>
                </div>
            </div>
        </div>
    )
}

export default SearchCard3;