import { axiosInstance } from "../index";


const cru = {
    GetCru: async (data) => {
        const result = await axiosInstance.post(`/cru/get`, data);
        return result.data;
    },
    AddCru: async (data) => {
        const result = await axiosInstance.post(`/cru/add`, data);
        return result.data;
    },
    ModifyCru: async (data) => {
        const result = await axiosInstance.post(`/cru/modify`, data);
        return result.data;
    },
    DeleteCru: async (data) => {
        const result = await axiosInstance.post(`/cru/delete`, data);
        return result.data;
    },
    InitCar: async (data) => {
        const result = await axiosInstance.post(`/cru/initCar`, data);
        return result.data;
    },
    ExcelUpload: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key)=>{
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post(`/cru/excel/upload`, formData);
        return result.data;
    },
    safetyDriveScoreSum: async (data) => {
        const result = await axiosInstance.post("/agg/safetydrivescore/sum", data);
        return result.data;
    },
    safetyDriveScoreSelect: async (data) => {
        const result = await axiosInstance.post("/agg/safetydrivescore/select", data);
        return result.data;
    },
    safetyDriveList: async (data) => {
        const result = await axiosInstance.post("/agg/safetydrive/list", data);
        return result.data;
    },
    safetyAirScoreSum: async (data) => {
        const result = await axiosInstance.post("/agg/airqualityscore/sum", data);
        return result.data;
    },
    safetyAirScoreSelect: async (data) => {
        const result = await axiosInstance.post("/agg/airqualityscore/select", data);
        return result.data;
    },
    airQualityList: async (data) => {
        const result = await axiosInstance.post("/agg/airquality/list", data);
        return result.data;
    },
    safetyDriveScoreFrn: async (data) => {
        const result = await axiosInstance.post("/agg/safetydrivescore/frn", data);
        return result.data;
    },
    safetyDriveScoreCru: async (data) => {
        const result = await axiosInstance.post("/agg/safetydrivescore/cru", data);
        return result.data;
    },
    safetyAirScoreFrn: async (data) => {
        const result = await axiosInstance.post("/agg/airqualityscore/frn", data);
        return result.data;
    },
    safetyAirScoreCru: async (data) => {
        const result = await axiosInstance.post("/agg/airqualityscore/cru", data);
        return result.data;
    },
    airDriveFrn: async (data) => {
        const result = await axiosInstance.post("/agg/airdrive/frn", data);
        return result.data;
    },
    airDriveCru: async (data) => {
        const result = await axiosInstance.post("/agg/airdrive/cru", data);
        return result.data;
    },
    driveStatFrn: async (data) => {
        const result = await axiosInstance.post("/agg/drivestat/frn", data);
        return result.data;
    },
    driveStatCru: async (data) => {
        const result = await axiosInstance.post("/agg/drivestat/cru", data);
        return result.data;
    },
    lowScoreFrn: async (data) => {
        const result = await axiosInstance.post("/agg/lowscore/frn", data);
        return result.data;
    },
    lowScoreCru: async (data) => {
        const result = await axiosInstance.post("/agg/lowscore/cru", data);
        return result.data;
    },
}

export default cru;