import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import CustomTable from "../../components/Table/CustomTable";
import common from "../../services/common";
import Input from "../../components/Input/Input";
import Alert from "../../components/Alert";
import {checkLen2, checkLen5, hexToNum, numToHex2Byte, numToHex4Byte, setCurrentUrl} from "../../utils";
import AlertMessage from "../../components/Alert/AlertMessage";

const translateTitle = {
    lowScore: "최저 점수",
    highScore: "최고 점수",
    lowRatio: "최소 비율 (%)",
    highRatio: "최대 비율 (%)",
    lowDust: "최저 미세먼지 농도",
    highDust: "최고 미세먼지 농도",
    text: "텍스트",
    voc: "가중치",
    type: "종류",
    version: "버전",
    file: "파일"
}
const textArr = ["우수", "양호", "주의"];

const SettingPage = ()=>{
    const [driveRange, setDriveRange] = useState([]);
    const [dustSetPac, setDustSetPac] = useState([]);
    const [dustRangePac, setDustRangePac] = useState([]);
    const [version, setVersion] = useState([]);
    const [dustSet, setDustSet] = useState({
        time: "",
        list: []
    });
    const [voc, setVoc] = useState([]);
    const [loc, setLoc] = useState({
        distance: "",
        time: ""
    })
    const [driveSet, setDriveSet] = useState({
        distance: "",
        accel: "",
        decel: "",
        start:"",
        stop: "",
        path: "",
        rotation: "",
        passing: "",
        uturn:""
    });
    const [obdFile, setObdFile] = useState(null);
    const [dustFile, setDustFile] = useState(null);
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        fetchSetting();
    }, []);

    const fetchSetting = async () => {
        try {
            await common.GetAppConfig().then((res) => {
                if (res) {
                    setDriveRange(getScoreData(res.driveScoreRange, 100));
                    setDustSetPac(getScoreData(res.dustScoreSetPacket, 100));
                    setDustRangePac(getScoreRangeData(res.dustScoreRangePacket));
                    setVoc(getVoc(res.vocScoreRangePacket));
                    setLoc(getLocTerm(res.locTerm));
                    setDriveSet(getDriveSet(res.driveScoreSet));
                    setDustSet(getRatioData(res.dustScoreSet, 100));
                    setVersion(getVersion(res));
                } else {
                    setAlertMessage("값을 불러올 수 없습니다.");
                    setAlertShow("alert");
                }
            });
        } catch {
            setAlertMessage("오류가 발생하였습니다.");
            setAlertShow("alert");
        }
    }

    const onCheckSave = () => {
        let notValue = [];

        if(!isCorrectTable(driveRange, 100))
            notValue.push("안전 운전 점수 구분 설정값을 확인해주세요.");
        if(!isCorrectTable(dustSetPac, 100))
            notValue.push("공기 관리 점수 구분 설정값을 확인해주세요.");
        if(!isCorrectDustRangePac(dustRangePac, 1000))
            notValue.push("실내 공기 점수 구분 설정값을 확인해주세요.");
        if(!isCorrectVoc(voc, 1))
            notValue.push("가스 센서 가중치 설정값을 확인해주세요.");
        if(!isCorrectObject(loc, 65000))
            notValue.push("위치 정보 전송 주기 설정값은 65000까지 입력할 수 있습니다.");
        if(!isCorrectDriveData(driveSet))
            notValue.push("안전 운전 점수 배점 설정값을 확인해주세요.");
        if(!isCorrectRatio(dustSet))
            notValue.push("공기 관리 점수 배점 설정값을 확인해주세요.");

        if (notValue.length > 0) {
            setAlertMessage(notValue[0]);
            setAlertShow("alert");
            return;
        }

        setAlertShow("alertMessage");
    }

    const saveSetting = async () => {
        const tempData = {
            appSeq: 1,
            driveScoreRange: setScoreData(driveRange, "lowScore"),
            driveScoreSet: setDriveData(driveSet),
            dustScoreRangePacket: setScore4byte(dustRangePac, "highDust"),
            dustScoreSetPacket: setScoreData(dustSetPac, "lowScore"),
            vocScoreRangePacket: setScoreData(voc, "voc"),
            dustScoreSet: numToHex4Byte(dustSet.time) + setScoreData(dustSet.list, "lowRatio"),
            locTerm: setObject(loc),
            dustVer: version[0]?.version,
            // dustVerPath: version[0]?.filePath,
            obdVer: version[1]?.version,
            // obdVerPath: version[1]?.filePath,
            appVer: version[2]?.version
        }

        const formData = new FormData();
        if (dustFile) formData.append("fileDust", dustFile);
        if (obdFile) formData.append("fileObd", obdFile);

        try {
            await common.ModifyAppConfig(formData, tempData).then((res) => {
                if (res) {
                    setAlertMessage("저장되었습니다.");
                    setAlertShow("alert");
                } else {
                    setAlertMessage("저장 실패하였습니다.");
                    setAlertShow("alert");
                }
            });
        }
        catch {
            setAlertMessage("오류가 발생하였습니다.");
            setAlertShow("alert");
        }
        onCancel();
    }
    const onCancel = () => {
        setDriveRange([]);
        setDustSetPac([]);
        setDustRangePac([]);
        setVoc([]);
        setDustSet({
            time: "",
            list: []
        });
        setLoc({
            distance: "",
            time: ""
        });
        setDriveSet({
            distance: "",
            accel: "",
            decel: "",
            start:"",
            stop: "",
            path: "",
            rotation: "",
            passing: "",
            uturn:""
        })
        setVersion([]);
        fetchSetting();
    }

    /* 유효성 검사 */
    const isCorrectTable = (arr, max) => {
        let bool = true;
        arr.forEach((item) => {
            let compareValue = item.key === 0 ? max : arr[item.key - 1].lowScore;
            if (isNaN(item.lowScore) || parseInt(item.lowScore) >= parseInt(compareValue)) {
                bool = false;
            }
        })
        return bool;
    }

    const isCorrectDustRangePac = (arr, max) => {
        let bool = true;
        arr.forEach((item) => {
            let compareValue = item.key === 2 ? max : arr[item.key + 1].highDust;

            if (isNaN(item.highDust) || parseInt(item.highDust) >= parseInt(compareValue)) {
                bool = false;
            }
        })
        return bool;
    }
    const isCorrectVoc = (arr, max) => {
        let bool = true;
        arr.forEach((item) => {
            if (isNaN(item.voc) || item.voc >= max) {
                bool = false;
            }
        })
        return bool;
    }
    const isCorrectObject = (value, max) => {
        let bool = true;
        Object.keys(value).forEach((key) => {
            if (isNaN(value[key]) || parseInt(value[key]) > parseInt(max)) {
                bool = false;
            }
        })
        return bool;
    }
    const isCorrectDriveData = (list) => {
        let bool = true;
        Object.keys(list).forEach((key) => {
            if (!bool) return;
            if (key === "distance") {
                bool = isCorrect(list[key], 65000);
            } else {
                bool = isCorrect(list[key], 99);
            }
        })
        return bool;
    }
    const isCorrectRatio = (list) => {
        if (!isCorrect(list.time, 65000)) return false;

        let bool = true;
        list.list.forEach((item) => {
            let compareValue = item.key === 0 ? 100 : list.list[item.key - 1].lowRatio;
            if (isNaN(item.lowRatio) || parseInt(item.lowRatio) >= parseInt(compareValue)) {
                bool = false;
            }
        })
        return bool;
    }
    const isCorrect = (value, max) => {
        let bool = true;
        if (isNaN(value) || parseInt(value) > parseInt(max)) {
            bool = false;
        }
        return bool;
    }

    /* DB에서 받아온 데이터 변환 */
    const getScoreData = (score, max) => {
        const num = hexToNum(score, 2);
        const scoreData = [];
        for(let i = 0; i < 3; i++) {
            const data = {
                key: i,
                lowScore: num[i],
                highScore: i === 0 ? max : num[i - 1] - 1,
                text: textArr[i],
            }
            scoreData.push(data);
        }
        return scoreData;
    }
    const getRatioData = (ratio, max) => {
        let arr = [];
        arr.push(ratio.substr(0,4));
        arr.push(ratio.substr(4,2));
        arr.push(ratio.substr(6,2));
        arr.push(ratio.substr(8,2));

        const setArr = arr.map((item) => {
            return parseInt(item, 16).toString();
        });

        const ratioData = [];
        for(let i = 1; i < 4; i++) {
            const data = {
                key: i - 1,
                lowRatio: setArr[i],
                highRatio: i === 1 ? max : setArr[i - 1] - 1,
                text: textArr[i - 1],
            }
            ratioData.push(data);
        }

        return {
            time: setArr[0],
            list: ratioData
        };
    }
    const getScoreRangeData = (score) => {
        const num = hexToNum(score, 4);
        const scoreData = [];
        for(let i = 0; i < 3; i++) {
            const data = {
                key: i,
                lowDust: i === 0 ? 0 : parseInt(num[i - 1]) + 1,
                highDust: num[i],
                text: textArr[i],
            }
            scoreData.push(data);
        }
        return scoreData;
    }
    const getVoc = (score) => {
        const num = hexToNum(score, 2);
        const scoreData = [];
        for(let i = 0; i < 3; i++) {
            const data = {
                key: i,
                voc: num[i] / 100,
                text: textArr[i],
            }
            scoreData.push(data);
        }
        return scoreData;
    }
    const getLocTerm = (score) => {
        const num = hexToNum(score, 4);
        return {
            distance: num[0],
            time: num[1]
        };
    }
    const getDriveSet = (score) => {
        let arr = [];
        arr.push(score.substr(0,4));

        for(let i = 4; i < 20;) {
            arr.push(score.substr(i,2));
            i += 2;
        }

        const setArr = arr.map((item) => {
            return parseInt(item, 16).toString();
        });

        return {
            distance: setArr[0],
            accel: setArr[1],
            decel: setArr[2],
            start: setArr[3],
            stop: setArr[4],
            path: setArr[5],
            rotation: setArr[6],
            passing: setArr[7],
            uturn: setArr[8]
        }
    }
    const getVersion = (list) => {
        const versionList = [{
            key: 0,
            type: "컨트롤러",
            version: list.dustVer,
            file: list.dustVerPath ? "등록완료" : "", // 파일명으로 변경 필요
            filePath: list.dustVerPath
        }, {
            key: 1,
            type: "OBD",
            version: list.obdVer,
            file: list.obdVerPath ? "등록완료" : "", // 파일명으로 변경 필요
            filePath: list.obdVerPath
        }, {
            key: 2,
            type: "안드로이드 앱",
            version: list.appVer,
            file: "파일없음", // 파일명으로 변경 필요
            filePath: ""
        }];
        return versionList;
    }

    /* DB에 보낼 데이터 변환 */
    const setScoreData = (score, name) => {
        let data = "";
        score.forEach((item) => {
            if (name === "voc") {
                data += numToHex2Byte(item[name] * 100);
            } else {
                data += numToHex2Byte(item[name]);
            }
        })
        return data;
    }
    const setScore4byte = (score, name) => {
        let data = "";
        score.forEach((item) => {
            data += numToHex4Byte(item[name]);
        })
        return data;
    }
    const setObject = (list) => {
        let data = "";
        Object.keys(list).forEach((key) => {
            data += numToHex4Byte(list[key]);
        })
        return data;
    }
    const setDriveData = (list) => {
        let data = "";
        Object.keys(list).forEach((key) => {
            if (key === "distance") {
                data += numToHex4Byte(list[key])
            } else {
                data += numToHex2Byte(list[key]);
            }
        })
        return data;
    }

    /* 점수 구분 설정 테이블 */
    const scoreHeader = ["lowScore", "highScore", "text"];
    const scoreColumns = scoreHeader.map((key) => {
        return {
            title: translateTitle[key],
            dataIndex: key,
            key,
            width: "340px",
            editable: key === "lowScore",
        }
    });

    /* 실내공기 점수 구분 설정 테이블 */
    const dustHeader = ["lowDust", "highDust", "text"];
    const dustColumns = dustHeader.map((key) => {
        return {
            title: translateTitle[key],
            dataIndex: key,
            key,
            width: "340px",
            editable: key === "highDust",
        }
    });

    /* 가중치 설정 테이블 */
    const vocHeader = ["voc", "text"];
    const vocColumns = vocHeader.map((key) => {
        return {
            title: translateTitle[key],
            dataIndex: key,
            key,
            width: "510px",
            editable: key !== "text",
        }
    });

    /* 비율 테이블 */
    const ratioHeader = ["lowRatio", "highRatio", "text"];
    const ratioColumns = ratioHeader.map((key) => {
        return {
            title: translateTitle[key],
            dataIndex: key,
            key,
            width: "340px",
            editable: key === "lowRatio",
        }
    });

    /* 펌웨어 버전 테이블 */
    const versionHeader = ["type", "version", "file"];
    const versionColumns = versionHeader.map((key) => {
        return {
            title: translateTitle[key],
            dataIndex: key,
            key,
            width: "340px",
            editable: key !== "type",
        }
    });

    const saveDriveRange = (row) => {
        // 안전 운전 점수 구분 설정
        let newData = [];

        driveRange.forEach((item) => {
            if (item.key === row.key) {
                newData.push(row);
            } else if (item.key === row.key + 1) {
                newData.push({
                    ...item,
                    highScore: row.lowScore - 1,
                })
            } else {
                newData.push(item);
            }
        })
        setDriveRange(newData);
    };
    const saveDustSetPac = (row) => {
        let newData = [];
        dustSetPac.forEach((item) => {
            if (item.key === row.key) {
                newData.push(row);
            } else {
                newData.push(item);
            }
        })
        setDustSetPac(newData);
    };
    const saveDustRangePac = (row) => {
        let newData = [];
        dustRangePac.forEach((item) => {
            if (item.key === row.key) {
                newData.push(row);
            } else {
                newData.push(item);
            }
        })
        setDustRangePac(newData);
    };
    const saveVoc = (row) => {
        let newData = [];
        voc.forEach((item) => {
            if (item.key === row.key) {
                newData.push(row);
            } else {
                newData.push(item);
            }
        })
        setVoc(newData);
    };
    const saveRatio = (row) => {
        let newData = [];
        dustSet.list.forEach((item) => {
            if (item.key === row.key) {
                newData.push(row);
            } else {
                newData.push(item);
            }
        })
        setDustSet((prev) => {
            return ({
                ...prev,
                list: newData
            })
        })
    }
    const saveVersion = (row) => {
        let newData = [];
        version.forEach((item) => {
            if (item.key === row.key) {
                newData.push({
                    ...item,
                    version: row.version,
                });
            } else {
                newData.push(item);
            }
        })
        setVersion(newData);
    }
    const saveFile = (fileData, row) => {
        let newData = [];
        version.forEach((item) => {
            if (item.key === row.key) {
                newData.push({
                    ...row,
                    file: fileData.name
                });
            } else {
                newData.push(item);
            }
        })
        setVersion(newData);

        if (row.type === "컨트롤러") {
            setDustFile(fileData);
        } else {
            setObdFile(fileData);
        }
    }

    return (
        <Layout>
            <div className="setting-block">
                <h2>안전 운전 점수 구분 설정</h2>
                <div className="setting-table-wrap">
                    <CustomTable
                        columns={scoreColumns}
                        data={driveRange}
                        onEditComplete={saveDriveRange}
                    />
                </div>
            </div>
            <div className="setting-block">
                <h2>공기 관리 점수 구분 설정</h2>
                <div className="setting-table-wrap">
                    <CustomTable
                        columns={scoreColumns}
                        data={dustSetPac}
                        onEditComplete={saveDustSetPac}
                    />
                </div>
            </div>
            <div className="setting-block">
                <h2>실내 공기 점수 구분 설정</h2>
                <div className="setting-table-wrap">
                    <CustomTable
                        columns={dustColumns}
                        data={dustRangePac}
                        onEditComplete={saveDustRangePac}
                    />
                </div>
            </div>
            <div className="setting-block">
                <h2>가스센서 가중치 설정</h2>
                <div className="setting-table-wrap">
                    <CustomTable
                        columns={vocColumns}
                        data={voc}
                        onEditComplete={saveVoc}
                    />
                </div>
            </div>
            <div className="setting-block">
                <h2>위치 정보 전송 주기</h2>
                <div className="setting-table-wrap setting-flex">
                    <div className="setting-value">
                        <Input
                            hasLabel
                            label="거리 간격"
                            value={checkLen5(loc.distance)}
                            onChange={(e) => setLoc((prev) => {
                                return ({
                                    ...prev,
                                    distance: e.target.value
                                })
                            })}
                        />
                        <p className="setting-loc-text">m</p>
                    </div>
                    <div className="setting-value">
                        <Input
                            hasLabel
                            label="시간"
                            value={checkLen5(loc.time)}
                            onChange={(e) => setLoc((prev) => {
                                return ({
                                    ...prev,
                                    time: e.target.value
                                })
                            })}
                        />
                        <p className="setting-loc-text">sec</p>
                    </div>
                </div>
            </div>
            <div className="setting-block">
                <h2>안전 운전 점수 배점 설정</h2>
                <div className="setting-table-wrap setting-drive">
                    <div className="setting-drive-set">
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="1회 운행 거리"
                                value={checkLen5(driveSet.distance)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        distance: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">km</p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="setting-drive-set grid-4">
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="급가속"
                                value={checkLen2(driveSet.accel)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        accel: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">점</p>
                        </div>
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="급출발"
                                value={checkLen2(driveSet.start)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        start: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">점</p>
                        </div>
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="급진로변경"
                                value={checkLen2(driveSet.path)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        path: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">점</p>
                        </div>
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="급U턴"
                                value={checkLen2(driveSet.uturn)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        uturn: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">점</p>
                        </div>
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="급감속"
                                value={checkLen2(driveSet.decel)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        decel: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">점</p>
                        </div>
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="급정지"
                                value={checkLen2(driveSet.stop)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        stop: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">점</p>
                        </div>
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="급좌우회전"
                                value={checkLen2(driveSet.rotation)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        rotation: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">점</p>
                        </div>
                        <div className="setting-value">
                            {/*  <Input
                                hasLabel
                                label="급앞지르기"
                                value={checkLen2(driveSet.passing)}
                                onChange={(e) => setDriveSet((prev) => {
                                    return ({
                                        ...prev,
                                        passing: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">점</p>*/}
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="setting-block">
                <h2>공기질 점수 배점 설정</h2>
                <div className="setting-table-wrap setting-drive">
                    <div className="setting-drive-set">
                        <div className="setting-value">
                            <Input
                                hasLabel
                                label="1회 운행 시간"
                                value={checkLen5(dustSet.time)}
                                onChange={(e) => setDustSet((prev) => {
                                    return ({
                                        ...prev,
                                        time: e.target.value
                                    })
                                })}
                            />
                            <p className="setting-loc-text">sec</p>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="setting-drive-set">
                        <CustomTable
                            columns={ratioColumns}
                            data={dustSet.list}
                            onEditComplete={saveRatio}
                        />
                    </div>
                </div>
            </div>*/}
            <div className="setting-block" style={{ marginBottom: "160px" }}>
                <h2>펌웨어 버전 업로드</h2>
                <div className="setting-table-wrap">
                    <CustomTable
                        columns={versionColumns}
                        data={version}
                        onEditComplete={saveVersion}
                        onSaveFile={saveFile}
                    />
                </div>
            </div>
            <div className="setting-button-wrap">
                <button className="btn btn-line-gray" onClick={onCancel}>취소</button>
                <button className="btn btn-navy" onClick={onCheckSave}>저장</button>
            </div>
            <Alert
                isShow={alertShow === "alert"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
            />
            <AlertMessage
                isShow={alertShow === "alertMessage"}
                height="240px"
                title="!! 경고 !!"
                content={
                    <div className="alert-message">
                        <p>설정값 변경 시에는 안전 운전 점수 및 공기 관리 점수 값이</p>
                        <p>이전과 다를 수 있습니다.</p>
                        <p>저장하시겠습니까?</p>
                    </div>}
                onClose={()=>{
                    setAlertShow("");
                }}
                onOk={saveSetting}
            />
        </Layout>
    )
}

export default SettingPage;