import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TipInput from "../../components/Input/TipInput";
import SelectBox from "../../components/SelectBox/SelectBox";
import cru from "../../services/cru";
import PostInput from "../../components/Input/PostInput";
import franchise from "../../services/franchise";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import {bizRegNumHypen, checkEmail, minNumHypen, noneHypen, phoneHypen, setCurrentUrl} from "../../utils";
import Alert from "../../components/Alert";
import car from "../../services/car";

const CruAdd = ()=>{
    const userInfo = useRecoilValue(userState);
    const initialData = {
        cruNm: "",
        frnSeq: userInfo?.frnGradeCd === "F0003" ? userInfo?.frnSeq : null,
        prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? (userInfo.frnGradeCd === "F0001" ? 2 : userInfo?.frnSeq) : userInfo?.prFrnSeq,
        // prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? userInfo?.frnSeq : userInfo?.prFrnSeq,
        cruHp: "",
        minNum: "",
        postNum: "",
        address1: "",
        address2: "",
        email: "",
        licenseNum: "",
        uniformSize: "",
        bankNm: "",
        accountNm: "",
        accountNum: "",
        carSeq: null,
    }
    const { id } = useParams();
    const [data, setData] = useState(initialData);
    const navigate = useNavigate();
    const [frnList, setFrnList] = useState([]);
    const [bizList, setBizList] = useState([]);
    const [selectCarList, setCarList] = useState([]);
    const [noneValue, setNoneValue] = useState("");
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const [actualMin, setActualMin] = useState("");
    const [originHp, setOriginHp] = useState("");
    const { state } = useLocation();

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        getOptionFranchise();
        setNoneValue("");
        if (id === "0") return;
        if (!state || !state.cruSeq || state.cruSeq == "") navigate("/error");
        setData(state);
        setActualMin(state.minNum);
        setOriginHp(state.cruHp);
    }, []);

    useEffect(() => {
        getCarNoList();
    }, [data.frnSeq])

    useEffect(() => {
        const temp = {
            ...data,
            frnSeq: userInfo?.frnGradeCd === "F0003" ? userInfo?.frnSeq : null
        }
        getOptionBiz(temp);
        if (id === "0" && userInfo?.frnGradeCd !== "F0003") {
            setData((prev) => {
                return ({
                    ...prev,
                    frnSeq: null,
                })
            })
        }
    }, [data.prFrnSeq]);

    const getOptionFranchise = async () => {
        const tempData = {
            page: 1,
            pageSize: 9999,
            frnGradeCd: "F0002",
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        setFrnList((prev) => prev.concat({
                            value: item.frnSeq,
                            label: item.bizNm
                        }))
                    })
                }
            });
        } catch {
            setFrnList([]);
        }
    }

    /*const getOptionFranchise = () => {
        const searchData = ["F0001", "F0002"];

        searchData.forEach(async (item) => {
            const tempData = {
                page: 1,
                pageSize: 9999,
                frnGradeCd: item,
                useYn: "Y"
            }
            try {
                await franchise.GetFranchise(tempData).then((res) => {
                    if (res.datas && res.datas.length > 0) {
                        res.datas.forEach((item) => {
                            setFrnList((prev) => prev.concat({
                                value: item.frnSeq,
                                label: item.bizNm
                            }))
                        })
                    }
                });
            } catch {

            }
        });
    }*/

    const getOptionBiz = async (temp) => {
        const tempData = {
            ...temp,
            page: 1,
            pageSize: 9999,
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then(async (res) => {
                const data = [];
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        if(item.frnGradeCd === "F0002" || item.frnGradeCd === "F0001"){

                        }else{
                            data.push({
                                value: item.frnSeq,
                                label: item.bizNm + "(" + bizRegNumHypen(item.bizRegNo) + ")"
                            });
                        }
                    })
                }
                setBizList(data);
            });
        } catch {

        }
    }

    const getCarNoList = async () => {
        if (!data.frnSeq) return;
        try {
            await car.GetCar({ frnSeq: data.frnSeq }).then((res) => {
                if (res.datas) {
                    const carList = [];
                    if (data.carSeq === null && res.datas.length > 0) {
                        carList.push({
                            value: "",
                            label: "선택안함"
                        })
                    }
                    res.datas.forEach((item) => {
                        if (item.useStat !== "D") {
                            carList.push({
                                value: item.carSeq,
                                label: item.carNo
                            })
                        } else {
                            carList.push({
                                value: item.carSeq,
                                label: item.carNo + "(사용중지)",
                                disabled: true,
                            })
                        }
                    })
                    setCarList(carList);
                }
            });
        } catch {
            setCarList([]);
        }
    }

    const checkValue = {
        "cruNm": "기사명",
        "frnSeq": "가맹점명",
        "cruHp": "연락처",
    }

    const onCheck = async () => {
        let emptyValue = [];
        Object.keys(checkValue).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined ) {
                emptyValue.push(checkValue[key]);
            }
        });
        
        if(data.email.length > 0 && !checkEmail(data.email)) {
            setNoneValue("이메일");
            setAlertMessage("이메일이 올바르지 않습니다.");
            setAlertShow("alertValue");
            return;
        }

        if (emptyValue.length > 0) {
            setNoneValue(emptyValue[0]);
            setAlertMessage(`${emptyValue[0]}을(를) 입력해주세요.`);
            setAlertShow("alertValue");
            return;
        }

        const check = await onCheckInfo();
        if (!check) {
            setAlertMessage("입력하신 기사 정보와 일치하는 이름, 연락처가 존재합니다.");
            setAlertShow("alertValue");
            return;
        }

        const tempData = {
            ...data,
            minNum: actualMin,
            cruHp: noneHypen(data.cruHp),
            accountNum: noneHypen(data.accountNum),
        }

        // tempData.cruHp와 originHp가 다를 경우 kakaoId 삭제
        if(tempData.cruHp !== originHp) {
            tempData.kakaoId = "";
        }

        if (id === "0") {
            onRegister(tempData);
        } else {
            onModify(tempData);
        }
    }

    const onRegister = async (tempData) => {
        try {
            await cru.AddCru(tempData).then((res) => {
                if (res.info) {
                    setAlertMessage("등록되었습니다.");
                    setAlertShow("alert");
                }
                else {
                    setAlertMessage("등록 실패하였습니다.");
                    setAlertShow("alert");
                }
            });
        } catch {
            setAlertMessage("등록 실패하였습니다.");
            setAlertShow("alert");
        }
    }

    const onModify = async (tempData) => {
        try {
            await cru.ModifyCru(tempData).then((res) => {
                if (res.info) {
                    setAlertMessage("수정되었습니다.");
                    setAlertShow("alert");
                }
                else {
                    setAlertMessage("수정 실패하였습니다.");
                    setAlertShow("alert");
                }
            });
        } catch {
            setAlertMessage("수정 실패하였습니다.");
            setAlertShow("alert");
        }
    }

    const onCheckInfo = async () => {
        let bool = true;
        if (id === "0" || !(data.cruNm === state?.cruNm && noneHypen(data.cruHp) === state?.cruHp)) {
            const tempData = {
                cruNm: data.cruNm,
                cruHp: noneHypen(data.cruHp),
                useYn: "Y"
            }
            try {
                await cru.GetCru(tempData).then((res) => {
                    if (res && res.totalCnt > 0) {
                        bool = false;
                    }
                });
            } catch {}
        }

        return bool;
    }

    const alertClose = () => {
        setAlertShow("");
        if(alertShow === "alert") {
            navigate("/cru");
        }
    }

    const onCancel = () => {
        navigate("/cru");
    }

    const onSettingAddress = (data) => {
        setData((prev) => {
            return ({
                ...prev,
                postNum: data.zonecode,
                address1: data.fullAddress,
                address2: "",
            })
        })
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onCheck();
        }
    }

    return (
        <Layout>
            <div className="add-page-wrap">
                {id === "0" &&
                    <h2>
                        기사 등록
                    </h2>
                }
                {id !== "0" &&
                    <h2>
                        기사 수정
                    </h2>
                }
                <div className="add-input-wrap">
                    <TipInput
                        label="기사명"
                        id="cruNm"
                        placeholder="기사명을 입력하세요"
                        none={noneValue === "기사명" ? true : false}
                        value={data.cruNm}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                cruNm: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <SelectBox
                        label="소속"
                        id="prFrnSeq"
                        placeholder="택시 조합을 선택하세요"
                        readOnly={(id !== "0")||(userInfo?.frnGradeCd !== "F0001") ? true : false}
                        none={noneValue === "소속" ? true : false}
                        width={374}
                        height={38}
                        value={data.prFrnSeq}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                prFrnSeq: e
                            })
                        })}
                        options={frnList}
                        required
                    />
                    {(id !== "0")||(userInfo?.frnGradeCd === "F0003") ?
                        <TipInput
                            label="가맹점명"
                            id="franchise"
                            value={bizList.map((item) => {
                                if (item.value === data.frnSeq) {
                                    return item.label;
                                }
                            })}
                            readOnly={true}
                            required
                        />
                        : <SelectBox
                            label="가맹점명"
                            id="franchise"
                            placeholder="택시 조합을 선택하세요"
                            none={noneValue === "가맹점명" ? true : false}
                            width={374}
                            height={38}
                            value={userInfo?.frnGradeCd === "F0003" ? userInfo.frnSeq : data.frnSeq}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    frnSeq: e,
                                    carSeq: null,
                                })
                            })}
                            options={bizList}
                            required
                        />}
                    <TipInput
                        label="연락처"
                        id="cruHp"
                        placeholder="연락처를 입력하세요"
                        none={noneValue === "연락처" ? true : false}
                        value={phoneHypen(data.cruHp)}
                        onChange={e => {
                            if (e.target.value.length > 13) return;
                            setData((prev) => {
                                return ({
                                    ...prev,
                                    cruHp: e.target.value
                                })
                            })
                        }}
                        onEnter={onEnter}
                        required
                    />
                    <TipInput
                        label="주민번호"
                        id="minNum"
                        placeholder="주민번호를 입력하세요"
                        value={minNumHypen(data.minNum)}
                        onChange={e => {
                            setData((prev) => {
                                return ({
                                    ...prev,
                                    minNum: e.target.value
                                })
                            })
                        }}
                        onKeyUp={e => {
                            if (e.key === "Process") return;
                            if (e.key === "Backspace") setActualMin((prev) => prev.slice(0, e.target.value.length));
                            if (actualMin.length > 12) return;
                            if (!isNaN(e.key)) setActualMin((prev) => prev + e.key);
                        }}
                        onEnter={onEnter}
                    />
                    <PostInput
                        label="주소"
                        id="address2"
                        placeholder="상세주소를 입력하세요"
                        postNum={data.postNum}
                        add1Value={data.address1}
                        value={data.address2}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                address2: e.target.value
                            })
                        })}
                        onKeyPress={onEnter}
                        onSetting={onSettingAddress}
                    />
                    <TipInput
                        label="이메일"
                        id="email"
                        placeholder="이메일을 입력하세요"
                        none={noneValue === "이메일" ? true : false}
                        tipContent="이메일이 올바르지 않습니다."
                        value={data.email}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                email: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    <TipInput
                        label="자격번호"
                        id="licenseNum"
                        placeholder="자격번호를 입력하세요"
                        value={data.licenseNum}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                licenseNum: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    <TipInput
                        label="제복사이즈"
                        id="uniformSize"
                        placeholder="제복사이즈를 입력하세요"
                        value={data.uniformSize}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                uniformSize: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    <TipInput
                        label="은행"
                        id="bankNm"
                        placeholder="은행을 입력하세요"
                        value={data.bankNm}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                bankNm: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    <TipInput
                        label="예금주명"
                        id="accountNm"
                        placeholder="예금주명을 입력하세요"
                        value={data.accountNm}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                accountNm: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    <TipInput
                        label="계좌번호"
                        id="accountNum"
                        placeholder="계좌번호를 입력하세요"
                        value={data.accountNum}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                accountNum: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                    />
                    {data.frnSeq &&
                        <SelectBox
                            label="차량선택"
                            id="carSeq"
                            placeholder="차량을 선택하세요"
                            width={374}
                            height={38}
                            value={data.carSeq}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    carSeq: e
                                })
                            })}
                            options={selectCarList}
                        />
                    }

                </div>
                <div className="btn-center-wrap">
                    <button className={"btn btn-gray"} onClick={onCancel}>
                        취소
                    </button>
                    <button className={"btn btn-navy"} onClick={onCheck}>
                        {id === "0" ? "등록" : "수정"}
                    </button>
                </div>
            </div>
            <Alert
                isShow={alertShow === "alert" || alertShow === "alertValue"}
                content={<p>{alertMsg}</p>}
                onClose={alertClose}
            />
        </Layout>
    )
}

export default CruAdd;

