import CryptoJS, { SHA512 } from 'crypto-js';
//TODO process.env.~~~ 사용하여 secret key 사용하도록 교체 (env는 gitignore에 추가)
const SECRET_KEY = "vmsm-12345x1234567890x67890-msmv";
const IV = "vmsm12345678vmsm";
export const encryptoAes = (strData) => {   //양방향 aes 256 암호화
    try {
        const result = CryptoJS.AES.encrypt(strData, CryptoJS.enc.Utf8.parse(SECRET_KEY), {
            iv: CryptoJS.enc.Utf8.parse(IV),
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        }).toString();

        return result; // 암호문과 IV를 함께 반환
    } catch (error) {
        throw new Error('aes 암호화 과정에서 오류가 발생하였습니다.');
    }
};

export const decryptoAes = (strData) => {  //aes 복호화
    try{
        const result = CryptoJS.AES.decrypt(strData, CryptoJS.enc.Utf8.parse(SECRET_KEY), {
            iv: CryptoJS.enc.Utf8.parse(IV),
            padding: CryptoJS.pad.Pkcs7,
            mode: CryptoJS.mode.CBC,
        }).toString(CryptoJS.enc.Utf8);
        return result;
    } catch (error) {
        throw new Error('aes 복호화 과정에서 오류가 발생하였습니다.');
    }
};

export const encryptoSha = (strData) => {
    try{
        const result = SHA512(strData, SECRET_KEY).toString();
        return result;
    }catch (error) {
        throw new Error('sha 암호화 과정에서 오류가 발생하였습니다.');
    }
}

export default Crypto;
