import React from "react";
import { ConfigProvider, DatePicker, Space } from 'antd';
// import koKR from 'antd/locale/ko_KR'; // 로케일 파일 경로 확인
import koKR from 'antd/lib/locale/ko_KR';
import dayjs from 'dayjs';
import 'dayjs/locale/ko';

dayjs.locale('ko');
const DateInput = (props) => {
    const inputPropsOnly = {
        ...props,
    };
    delete inputPropsOnly.hasLabel;

    return (
        <div className={(props?.type && props?.type==="wide")?"input-wrap wide-date" : "input-wrap"}>
            {props.hasLabel && (
                <span className="input-label">
                    {props.label}
                </span>
            )}
            <ConfigProvider
                locale={koKR}
                theme={{
                    token: {
                        borderRadius: 8,
                        colorBorder: "#D3D4D5",
                        colorPrimaryHover: "#D3D4D5",
                        colorBgContainer: '#ffffff',
                        fontSize: 14,
                        transition: 'all 0.5s',
                        lineHeight: 1,
                        colorTextPlaceholder: '#7C7F81',
                        colorPrimary: '#7688A8',
                    },
                    components: {
                        Select: {
                            optionFontSize: 15,
                            optionHeight: 48,
                            optionLineHeight: 2
                        }
                    }
                }}
            >
                <Space direction="vertical" locale={koKR} >
                    {props?.type && props?.type === "wide" &&
                        <DatePicker
                            {...inputPropsOnly}
                            defaultValue={dayjs(props.default, 'YYYY/MM/DD')}
                            style={{width:373, height: 37 ,padding: "8px 16px"}}
                            locale={koKR}
                            suffixIcon={<img src="/assets/icons/icon_Calendar.svg" alt="down" width={22} />}
                        />
                    }
                    {!props?.type &&
                        <DatePicker
                            {...inputPropsOnly}
                            style={{width:227, height: 37}}
                            locale={koKR}
                            suffixIcon={<img src="/assets/icons/icon_Calendar.svg" alt="down" width={22} />}
                        />
                    }
                </Space>
            </ConfigProvider>
        </div>
    );
};

export default DateInput;
