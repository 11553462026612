import { axiosInstance } from "../index";


const franchise = {
    GetFranchise: async (data) => {
        const result = await axiosInstance.post("/frn/get", data);
        return result.data;
    },
    AddFranchise: async (data, params) => {
        const formData = new FormData();
        if (data) formData.append("file", data);

        const result = await axiosInstance.post("/frn/add", formData, { params });
        return result.data;
    },
    ModifyFranchise: async (data, params) => {
        const formData = new FormData();
        if (data) formData.append("file", data);

        const result = await axiosInstance.post("/frn/modify", formData, { params });
        return result.data;
    },
    DeleteFranchise: async (data) => {
        const result = await axiosInstance.post("/frn/delete", data);
        return result.data;
    },
    ExcelUpload: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key)=>{
            formData.append(key, data[key]);
        })
        const result = await axiosInstance.post("/frn/excel/upload", formData);
        return result.data;
    },
}

export default franchise;

