import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {setCurrentUrl} from "../../utils";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const DeviceDetail = ()=>{
    const initialData = {
        regDt: "",
        deviceSeq: null,
        carSeq: null,
        deviceMac: "",
        deviceSn: "",
        dustVer: "",
        obdVer: "",
        carNo: "",
        bizNm: "",
        prBizNm: ""
    }
    const [data, setData] = useState(initialData);
    const { state } = useLocation();
    const navigate = useNavigate();
    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        if(!state || !state.carSeq || state.carSeq == ""){
            navigate("/error");
        }else {
            setData(state);
        }
    }, [])
   
    return (
        <Layout>
            <div className="detail-page">
                <h2 className="detail-title">기기 상세 보기</h2>
                <div className="device-wrap">
                    <div className="device-picture">
                        <img src="/assets/images/defaultDevice.png" width="100%" height="100%"/>
                    </div>
                    <div className="device-detail">
                        <div>
                            <div className="device-title" style={{ borderTopLeftRadius: '16px' }}>소속</div>
                            <div>{data.prBizNm}</div>
                        </div>
                        <div>
                            <div className="device-title">가맹점명</div>
                            <div>{data.bizNm}</div>
                        </div>
                        <div>
                            <div className="device-title">차량번호</div>
                            <div>{data.carNo}</div>
                        </div>
                        <div>
                            <div className="device-title">기기 맥주소</div>
                            <div>{data.deviceMac}</div>
                        </div>
                        <div>
                            <div className="device-title">컨트롤러 F/W</div>
                            {data.dustVer ?
                                <div>{data.dustVer}</div> :
                                <div className="text-red">정보없음</div>
                            }

                        </div>
                        <div>
                            <div className="device-title">OBD F/W</div>
                            {data.obdVer ?
                                <div>{data.obdVer}</div> :
                                <div className="text-red">정보없음</div>
                            }
                        </div>
                        <div className="bottom-none right-none">
                            <div className="device-title" style={{ borderBottomLeftRadius: '16px' }}>등록일</div>
                            <div>{dayjs(data.regDt).format("YYYY년 MM월 DD일")}</div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default DeviceDetail;