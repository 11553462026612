import React from 'react';
import { Navigate, Outlet, useLocation } from 'react-router';
import {userState} from "../../stores/UserStore";
import {useRecoilState} from "recoil";

//FIXME 권한별 페이지 접근 제한
export default function RoleRoute(props){
    const location = useLocation();
    const [userInfo, setUserInfo] = useRecoilState(userState);
    const userGradeCd = parseInt(userInfo.frnGradeCd.slice(1));

    if(props.frnGradeCd < userGradeCd){
        // TODO alert 띄우기?
        return <Navigate to ="/main" />;
    }
    return <Outlet />
};