import { useCallback, useReducer } from "react";

const inputReducer = (state, action) => {
    if (action.files) {
        return {
            ...state,
            [action.name]: action.files[0].name,
        };
    } else {
        return {
            ...state,
            [action.name]: action.value,
        };
    }
};

export const useInputs = (initialForm) => {
    const [state, dispatch] = useReducer(inputReducer, initialForm);
    const onChange = (e) => {
        dispatch(e.target);
    };

    const initialize = () => {
        Object.keys(initialForm).forEach((key) => {
            dispatch({
                name: key,
                value: initialForm[key],
            });
        });
    };

    const onCustomChange = useCallback((name, value) => {
        dispatch({
            name,
            value,
        });
    }, []);

    return [state, onChange, initialize, onCustomChange];
};
