import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import services, { axiosInstance } from "../services/index";
import Button from "../components/Button";
import Tip from "../components/Tip";
import Cookies from "js-cookie";
import {jwtDecode} from "jwt-decode";
import {userState} from "../stores/UserStore";
const FindPwd = () => {
    const [userInfo, setUserInfo] = useRecoilState(userState);
    // f = 정보 조회 실패 상태 / t = 정보 조회 성공 상태
    const [findStatus, setFindStatus] = useState(false);
    const [findFail, setFindFail] = useState(false);
    const [findFailMsg, setFindFailMsg] = useState("입력하신 정보로 조회된 데이터가 없습니다.");
    const navigate = useNavigate();

    const [userId, setUserId] = useState("");
    const [userHp, setUserHp] = useState("");

    const [smsPwd, setSmsPwd] = useState("");
    const [userPwd, setUserPwd] = useState("");
    const [userPwdCheck, setUserPwdCheck] = useState("");

    const [formStatus, setFormStatus] = useState("active");
    const [updateFormStatus, setUpdateFormStatus] = useState("active");
    const [userIdNone, setUserIdNone] = useState(false);
    const [userHpNone, setUserHpNone] = useState(false);

    const [smsPwdNone, setSmsPwdNone] = useState(false);
    const [userPwdNone, setUserPwdNone] = useState(false);
    const [userPwdCheckNone, setUserPwdCheckNone] = useState(false);

    useEffect(() => {
        formStatusUpdate();
    }, [userId, userHp]);
    useEffect(()=>{

    }, [userIdNone, userHpNone]);
    useEffect(()=>{
       updateFormStatusUpdate();
    }, [smsPwd, userPwd, userPwdCheck]);
    useEffect(()=>{

    }, [userPwdNone, userPwdCheckNone, smsPwdNone]);
    const formStatusUpdate = () => {
        if(!userId || !userHp){setFormStatus(""); return;}

        if (userId.length > 1 && userHp.length > 1) {
            setFormStatus("active");
        } else {
            setFormStatus("");
        }
    }

    const updateFormStatusUpdate = () => {
        if(!smsPwd || !userPwd || !userPwdCheck){setFormStatus(""); return;}

        if (smsPwd.length > 1 && userPwd.length > 1 && userPwdCheck.length > 1) {
            setFormStatus("active");
        } else {
            setFormStatus("");
        }
    }

    const userIdHandler = (value) => {
        setUserId(value);
    }
    const userHpHandler = (value) => {
        setUserHp(value);
    }
    const smsPwdHandler = (value) => {
        setSmsPwd(value);
    }
    const userPwdHandler = (value) => {
        setUserPwd(value);
    }
    const userPwdCheckHandler = (value) => {
        setUserPwdCheck(value);
    }

    const inputValidate = ()=>{
        let pass = true;
        if(!userId || userId.length < 1){
            setUserIdNone(true);
            pass = false;
        }
        if(!userHp || userHp.length < 1){
            setUserHpNone(true);
            pass = false;
        }
        return pass;
    }
    const updateInputValidate = ()=>{
        let pass = true;
        if(!smsPwd || smsPwd.length < 1){
            setSmsPwdNone(true);
            pass = false;
        }
        if(!userPwd || userPwd.length < 1){
            setUserPwdNone(true);
            pass = false;
        }
        if(!userPwdCheck || userPwdCheck.length < 1){
            setUserPwdCheckNone(true);
            pass = false;
        }
        return pass;
    }

    const findHandler = async (e) => {
        e.preventDefault();
        setFindStatus(false);
        setFindFail(false);
        if(!inputValidate()){
            setTimeout(()=>{
                setUserIdNone(false);
                setUserHpNone(false);
            }, 5000);
            return ;
        };

        try {
            // TODO pwd찾기 API 나오면 주소 변경
            const result = await services.auth.findPwd({
                userId: userId,
                userHp: userHp,
            });

            if (!result) {
                throw new Error("ID Find Fail: Result is Null");
            }else{
                setFindStatus(true);
            }

        } catch (err) {
            setFindFail(true);
            setFindStatus(false);
        }

    }
    const updateFormHandler = async (e)=>{
        e.preventDefault();
        // setFindStatus(false);
        setFindFail(false);
        if(!updateInputValidate()){
            setTimeout(()=>{
                setUserPwdNone(false);
                setUserPwdCheckNone(false);
                setSmsPwdNone(false);
            }, 5000);
            return ;
        };
        if(userPwd != userPwdCheck){
            setFindFail(true);
            setFindFailMsg("비밀번호 확인이 일치하지 않습니다.")
            return;
        }
        try {
            const result = await services.auth.findSetPwd({
                userId: userId,
                smsPwd: smsPwd,
                userPwd: userPwd
            });
            console.log(result);
            if (!result) {
                setFindFail(true);
                setFindFailMsg("비밀번호 변경에 실패하였습니다. 임시 비밀번호를 확인하세요.")
            }else{
                // setFindStatus(true);
                // TODO Login 바로 진행해주는 로직 추가
                const token = await services.auth.login({ userId: userId, userPwd: userPwd, ip: "172.30.1.54" });
                console.log(1)

                await Cookies.set("access-token", token);
                axiosInstance.interceptors.request.use((config) => {
                    config.headers["Authorization"] = `Bearer ${token}`;
                    return config;
                }, (err) => {
                    return Promise.reject(err);
                });
                console.log(2)

                const decodeToken = jwtDecode(token);
                //TODO 메인 페이지로 이동 + 유저 정보 상태관리에 저장해야함
                await setUserInfo(prevUserInfo => ({
                    ...prevUserInfo,
                    userId: decodeToken.id,
                    role: decodeToken.role,
                    userNm: decodeToken.nm,
                    frnSeq: decodeToken.frnSeq,
                    prFrnSeq: decodeToken.prFrnSeq,
                    frnGradeCd: decodeToken.frnGradeCd,
                    bizNm: decodeToken.bizNm,
                    iat: decodeToken.iat,
                    exp: decodeToken.exp
                }));
                console.log(3)
                navigate("/main");
            }

        } catch (err) {
            // setFindFail(true);
            // setFindStatus(false);
        }
    }
    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            findHandler();
        }
    }

    return (
        <div className="login-wrap">
            <div className="logo">
                <img src="/assets/images/Logo_ifense.svg" alt="ifense 로고" onClick={()=>{
                    navigate("/login");
                }} />
            </div>
            <div className="login-infomation-wrap">
                <h2>비밀번호 변경</h2>
                {!findStatus && (
                    <div>
                        <div className="info-field">
                            <span>아이디</span>
                            <input
                                type="text"
                                id="bizRegNum"
                                className="input-text input-full"
                                placeholder="아이디를 입력하세요."
                                onChange={e => userIdHandler(e.target.value)}
                                onKeyPress={e => onEnter(e)}
                                style={userIdNone ? { border: "1px solid #F04E3E" } : {}}
                            />
                            {userIdNone && (
                                <div className="tipWrap">
                                    <Tip
                                        content="필수 입력 사항 입니다."
                                        size={12}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="info-field">
                            <span>핸드폰 번호</span>
                            <input
                                type="text"
                                id="ph"
                                className="input-text input-full"
                                placeholder="핸드폰 번호를 입력하세요."
                                onChange={e => userHpHandler(e.target.value)}
                                onKeyPress={e => onEnter(e)}
                                style={userHpNone ? { border: "1px solid #F04E3E" } : {}}
                            />
                            {userHpNone && (
                                <div className="tipWrap">
                                    <Tip
                                        content="필수 입력 사항 입니다."
                                        size={12}
                                    />
                                </div>
                            )}
                        </div>
                        <Button
                            buttonType="login"
                            content="휴대폰 번호로 임시 비밀번호 받기"
                            onClick={findHandler}
                            formStatus={formStatus}
                        />
                    </div>
                )}

                {
                    findStatus &&
                    <div className="">
                        {/*<div className="find-text">*/}
                        {/*    <p className="findContent">가입하신 휴대폰 번호로</p>*/}
                        {/*    <p className="findContent">임시비밀번호를 전송하였습니다.</p>*/}
                        {/*</div>*/}
                        {/*<Button*/}
                        {/*    buttonType="login"*/}
                        {/*    content="로그인 하러가기"*/}
                        {/*    formStatus="active"*/}
                        {/*    onClick={()=>{navigate("/login");}}*/}
                        {/*/>*/}
                            <div className="info-field">
                                <span>임시비밀번호</span>
                                <input
                                    type="text"
                                    id="smsPwd"
                                    className="input-text input-full"
                                    placeholder="임시비밀번호를 입력하세요."
                                    onChange={e => smsPwdHandler(e.target.value)}
                                    onKeyPress={e => onEnter(e)}
                                    style={smsPwdNone ? { border: "1px solid #F04E3E" } : {}}
                                />
                                {smsPwdNone && (
                                    <div className="tipWrap">
                                        <Tip
                                            content="필수 입력 사항 입니다."
                                            size={12}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="info-field">
                                <span>신규비밀번호</span>
                                <input
                                    type="password"
                                    id="userPwd"
                                    className="input-text input-full"
                                    placeholder="신규비밀번호를 입력하세요."
                                    onChange={e => userPwdHandler(e.target.value)}
                                    onKeyPress={e => onEnter(e)}
                                    style={userPwdNone ? { border: "1px solid #F04E3E" } : {}}
                                />
                                {userPwdNone && (
                                    <div className="tipWrap">
                                        <Tip
                                            content="필수 입력 사항 입니다."
                                            size={12}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="info-field">
                                <span>비밀번호확인</span>
                                <input
                                    type="password"
                                    id="userPwdCheck"
                                    className="input-text input-full"
                                    placeholder="비밀번호를 한번더 입력하세요."
                                    onChange={e => userPwdCheckHandler(e.target.value)}
                                    onKeyPress={e => onEnter(e)}
                                    style={userPwdCheckNone ? { border: "1px solid #F04E3E" } : {}}
                                />
                                {userPwdCheckNone && (
                                    <div className="tipWrap">
                                        <Tip
                                            content="필수 입력 사항 입니다."
                                            size={12}
                                        />
                                    </div>
                                )}
                            </div>
                            <Button
                                buttonType="login"
                                content="비밀번호 변경"
                                onClick={updateFormHandler}
                                formStatus={updateFormStatus}
                            />
                    </div>

                }
                <div className="find-helper-wrap">
                    <Link to="/login">로그인 이동</Link> | <Link to="/findId">아이디 찾기</Link>
                </div>
                {
                    findFail &&
                    <div className="login-fail-msg">
                        <Tip
                            content={findFailMsg}
                            size={15}
                        />
                    </div>
                }
            </div>
        </div>

    )
}

export default FindPwd;
