import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TipInput from "../../components/Input/TipInput";
import SelectBox from "../../components/SelectBox/SelectBox";
import car from "../../services/car";
import TipDateInput from "../../components/Input/TipDateInput";
import TipAttInput from "../../components/Input/TipAttInput";
import franchise from "../../services/franchise";
import common from "../../services/common";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import Alert from "../../components/Alert";
import {bizRegNumHypen, setCurrentUrl} from "../../utils";

const CarAdd = ()=>{
    const userInfo = useRecoilValue(userState);
    // 운전자 등록 데이터 없음
    const initialData = {
        carSeq: null,
        frnSeq: userInfo?.frnGradeCd === "F0003" ? userInfo?.frnSeq : null,
        prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? (userInfo.frnGradeCd === "F0001" ? 2 : userInfo?.frnSeq) : userInfo?.prFrnSeq,
        // prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? userInfo?.frnSeq : userInfo?.prFrnSeq,
        carNmCd: null,
        carColor: "",
        carTypeCd: null,
        carYear: null,
        useStat: "N",
        carRegDt: "",
        carPic: ""
    }
    const { id } = useParams();
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const [frnList, setFrnList] = useState([]);
    const [bizList, setBizList] = useState([]);
    const [carNmList, setCarNmList] = useState([]);
    const [carTypeList, setCarTypeList] = useState([]);
    const [carYearList, setCarYearList] = useState([]);
    const [data, setData] = useState(initialData);
    const [files, setFiles] = useState(null);
    const navigate = useNavigate();
    const [noneValue, setNoneValue] = useState("");
    const { state } = useLocation();

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        getOptionFranchise();
        getCarCode();
        setNoneValue("");
        if (id === "0") return;
        if (!state || !state.carSeq || state.carSeq == "") navigate("/error");
        if (state.useStat === "D"){
            setAlertShow("alert");
            setAlertMessage("사용 중지된 차량은 수정이 불가능합니다.");
        }
        setData(state);
    }, [])

    useEffect(() => {
        const temp = {
            ...data,
            frnSeq: userInfo?.frnGradeCd === "F0003" ? userInfo?.frnSeq : null
        }
        getOptionBiz(temp);
        if (id === "0" && userInfo?.frnGradeCd !== "F0003") {
            setData((prev) => {
                return ({
                    ...prev,
                    frnSeq: null,
                })
            })
        }
    }, [data.prFrnSeq]);


    const checkValue = {
        "carNo": "차량번호",
        "frnSeq": "가맹점명",
        "carNmCd": "차종",
        "carTypeCd": "연료타입",
        "carYear": "연식",
    }
    const alertClose = () => {
        setAlertShow("");
        if(alertShow === "alert") {
            navigate("/car");
        }
    }
    const onCheck = () => {
        let emptyValue = [];
        Object.keys(checkValue).forEach((key) => {
            if (key === "frnSeq" || key === "carNmCd" || key === "carTypeCd" || key === "carYear") {
                if (data[key] === 0 || data[key] === null || data[key] === undefined) {
                    emptyValue.push(checkValue[key]);
                }
            } else {
                if (data[key] === "" || data[key] === null || data[key] === undefined) {
                    emptyValue.push(checkValue[key]);
                }
            }
        })

        if (emptyValue.length > 0) {
            setNoneValue(emptyValue[0]);
            setAlertMessage(`${emptyValue[0]}을(를) 입력해주세요.`);
            setAlertShow("alertValue");
            return;
        }

        let tempData = {
            ...data
        }
        delete tempData.carPic;
        if (id === "0") {
            onRegister(tempData);
        } else {
            onModify(tempData);
        }

    }

    const onRegister = async (tempData) => {
        // '등록하시겠습니까?'
        // 사용중인 아이디인지 확인 필요
        try {
            await car.AddCar(files, tempData).then((res) => {
            if (res.info) {
                // '등록되었습니다'
                setAlertMessage("등록되었습니다.");
                setAlertShow("alert");
            } else {
                // '등록 실패하였습니다'
                setAlertMessage("등록 실패하였습니다.");
                setAlertShow("alert");
            }});
        } catch {
            // '등록 실패하였습니다'
            setAlertMessage("등록 실패하였습니다.");
            setAlertShow("alert");
        }
    }

    const onModify = async (tempData) => {
        // '수정하시겠습니까?'
        try {
            await car.ModifyCar(files, tempData).then((res) => {
                if (res.info) {
                    // '수정되었습니다'
                    setAlertMessage("수정되었습니다.");
                    setAlertShow("alert");
                }else {
                    // '수정 실패하였습니다'
                    setAlertMessage("수정 실패하였습니다.");
                    setAlertShow("alert");
                }})
        } catch {
            // '수정 실패하였습니다'
            setAlertMessage("수정 실패하였습니다.");
            setAlertShow("alert");
        }

    }

    const onCancel = () => {
        navigate("/car");
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onCheck();
        }
    }

    const onChangePic = (data) => {
        setFiles(data);
        setData((prev) => {
            return ({
                ...prev,
                carPic: data.name
            })
        })
    }
    const onCheckCarNo = async (value) => {
        console.log(value);
        if(value.length < 1) return;
        try{
            await car.GetCar({carNo : value, useStat : ""}).then((res)=>{
                console.log(res)
                if(res && res.datas.length > 0){
                    setNoneValue("sameCarNo");
                } else{
                    setNoneValue("");
                }
            })
        } catch {}
    }

    const getOptionFranchise = async () => {
        const tempData = {
            page: 1,
            pageSize: 9999,
            frnGradeCd: "F0002",
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        setFrnList((prev) => prev.concat({
                            value: item.frnSeq,
                            label: item.bizNm
                        }))
                    })
                }
            });
        } catch {
            setFrnList([]);
        }
    }

    /*const getOptionFranchise = () => {
        const searchData = ["F0001", "F0002"];

        searchData.forEach(async (item) => {
            const tempData = {
                page: 1,
                pageSize: 9999,
                frnGradeCd: item,
                useYn: "Y"
            }
            try {
                await franchise.GetFranchise(tempData).then((res) => {
                    if (res.datas && res.datas.length > 0) {
                        res.datas.forEach((item) => {
                            setFrnList((prev) => prev.concat({
                                value: item.frnSeq,
                                label: item.bizNm
                            }))
                        })
                    }
                });
            } catch {

            }
        });
    }*/

    const getOptionBiz = async (temp) => {
        const tempData = {
            ...temp,
            page: 1,
            pageSize: 9999,
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then(async (res) => {
                const data = [];
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        if(item.frnGradeCd === "F0002" || item.frnGradeCd === "F0001"){

                        }else{
                            data.push({
                                value: item.frnSeq,
                                label: item.bizNm + "(" + bizRegNumHypen(item.bizRegNo) + ")"
                            });
                        }
                    })
                }
                setBizList(data);
            });
        } catch {

        }
    }
    const getCarCode = async () => {
        await common.GetCode({cdId: null}).then((res)=>{
            const carTypeList = [];
            const carNmList = [];
            const carYearList = [];
            if(res.datas && res.datas.length > 0){
                res.datas.forEach((item) => {
                    if(item.cdGrp === "C"){
                        carNmList.push({
                            value: item.cdId,
                            label: item.cdVal
                        });
                    }
                    else if(item.cdGrp === "E"){
                        carTypeList.push({
                            value: item.cdId,
                            label: item.cdVal
                        })
                    }else if(item.cdGrp === "Y"){
                        carYearList.push({
                            value: item.cdVal,
                            label: item.cdVal
                        })
                    }
                });
                setCarNmList(carNmList);
                setCarTypeList(carTypeList);
                setCarYearList(carYearList);
            }
        });
    }

    return (
        <Layout>
            <div className="add-page-wrap">
                {id === "0" &&
                    <h2>
                        차량 등록
                    </h2>
                }
                {id !== "0" &&
                    <h2>
                        차량 수정
                    </h2>
                }
                <div className="add-input-wrap">
                    <TipInput
                        label="차량번호"
                        id="carNo"
                        placeholder="차량번호를 입력하세요"
                        none={noneValue === "차량번호" || noneValue === "sameCarNo" ? true : false}
                        tipContent={noneValue === "sameCarNo" ? "중복된 차량번호가 존재합니다." : "필수 입력 사항 입니다."}
                        value={data.carNo}
                        onChange={e => setData((prev) => {
                            onCheckCarNo(e.target.value.toString().trim());
                            return ({
                                ...prev,
                                carNo: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        readOnly={id !== "0"}
                        required
                    />
                    <SelectBox
                        label="소속"
                        id="prFrnSeq"
                        placeholder="택시 조합을 선택하세요"
                        readOnly={(id !== "0")||(userInfo?.frnGradeCd !== "F0001") ? true : false}
                        none={noneValue === "소속" ? true : false}
                        width={374}
                        height={38}
                        value={data.prFrnSeq}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                prFrnSeq: e
                            })
                        })}
                        options={frnList}
                        required
                    />
                    <SelectBox
                        label="가맹점명"
                        id="franchise"
                        placeholder="택시 조합을 선택하세요"
                        none={noneValue === "가맹점명" ? true : false}
                        readOnly={(id !== "0")||(userInfo?.frnGradeCd === "F0003") ? true : false}
                        width={374}
                        height={38}
                        value={userInfo?.frnGradeCd === "F0003" ? userInfo.frnSeq : data.frnSeq}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                frnSeq: e
                            })
                        })}
                        options={bizList}
                        required
                    />
                    <SelectBox
                        label="차종"
                        id="carNmCd"
                        placeholder="차종을 선택하세요"
                        none={noneValue === "차종" ? true : false}
                        width={374}
                        height={38}
                        value={data.carNmCd}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                carNmCd: e
                            })
                        })}
                        options={carNmList}
                        required
                        readOnly={data.useStat === "D" ? true : false}
                    />
                    <TipInput
                        label="색상"
                        id="carColor"
                        placeholder="차량 색상을 입력하세요"
                        none={noneValue === "색상" ? true : false}
                        value={data.carColor}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                carColor: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        readOnly={data.useStat === "D" ? true : false}
                    />
                    <SelectBox
                        label="연료타입"
                        id="carTypeCd"
                        placeholder="연료타입을 선택하세요"
                        none={noneValue === "연료타입" ? true : false}
                        width={374}
                        height={38}
                        value={data.carTypeCd}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                carTypeCd: e
                            })
                        })}
                        options={carTypeList}
                        required
                        readOnly={data.useStat === "D" ? true : false}
                    />
                    <SelectBox
                        label="연식"
                        id="carYearCd"
                        placeholder="연식을 선택하세요"
                        none={noneValue === "연식" ? true : false}
                        width={374}
                        height={38}
                        value={data.carYear}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                carYear: e
                            })
                        })}
                        options={carYearList}
                        required
                        readOnly={data.useStat === "D" ? true : false}
                    />
                    {data.useStat !== "D" &&
                        <TipAttInput
                            label="차량등록증"
                            id="carPic"
                            none={noneValue === "차량등록증" ? true : false}
                            filePath={data.carPic}
                            onChange={onChangePic}
                            text="* 차량등록증의 경우 png, jpg 형식의 파일만 업로드 가능합니다. 최대 10M"
                        />
                    }

                </div>
                <div className="btn-center-wrap">
                    <button className={"btn btn-gray"} onClick={onCancel}>
                        취소
                    </button>
                    <button className={"btn btn-navy"} onClick={onCheck}>
                        {id === "0" ? "등록" : "수정"}
                    </button>
                </div>
            </div>
            <Alert
                isShow={alertShow === "alert" || alertShow === "alertValue"}
                content={<p>{alertMsg}</p>}
                onClose={alertClose}
            />
        </Layout>
    )
}

export default CarAdd;