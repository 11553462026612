import "./DropDown.scss";
import React from "react";
import {ConfigProvider, Select} from "antd";
const FrnTypeDrop = (props)=>{
    return (
        <ConfigProvider
            theme={{
                token: {
                    borderRadius: 12,
                    colorBorder: "#D3D4D5",
                    colorBgContainer: '#fff',
                    fontSize: 15,
                    colorPrimary: "#7688A8"

                },
                components: {
                    Select: {
                        optionFontSize: 15,
                        optionHeight: 48,
                        optionLineHeight: 2
                    }
                }
            }}
        >
            <Select
                className="dateTypeSelect"
                // showSearch
                style={{
                    width: 110,
                    height: 48,
                    fontSize: 15
                }}
                suffixIcon={<img src="/assets/icons/icon_shevron_down.svg" alt="down" />}
                value={props.value}
                onChange={props.onChange}
                // placeholder="기간 유형 선택..."
                optionFilterProp="children"
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                // filterSort={(optionA, optionB) =>
                //     (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                // }
                disabled={props.readOnly}
                options={props.options ??[
                    {
                        value: '',
                        label: '전체',
                    },
                    {
                        value: 'F0003',
                        label: '법인',
                    },
                    {
                        value: 'F0004',
                        label: '개인',
                    },
                ]}
            />
        </ConfigProvider>
    );
}

export default FrnTypeDrop;