import React, {useEffect, useState} from "react";
import Input from "../../components/Input/Input";
import Layout from "../../layout/Layout";
import {Table} from "antd";
import translator from "../../utils/translator";
import admin from "../../services/admin";
import {useNavigate} from "react-router-dom";
import DateInput from "../../components/Input/DateInput";
import dayjs from "dayjs";
import {
    dateSorter, getPreviousUrl, getSearchData,
    phoneHypen, removeSearchData,
    setCurrentUrl,
    setSearchData,
    stringSorter,
    trimObject,
    utcToLocalformat
} from "../../utils";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import franchise from "../../services/franchise";
import SearchSelect from "../../components/SelectBox/SearchSelect";
import common from "../../services/common";
import Alert from "../../components/Alert";
import AlertMessage from "../../components/Alert/AlertMessage";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const AdminList = ()=>{
    const userInfo = useRecoilValue(userState);
    const initialData = {
        page: 1,
        pageSize: 10,
        useYn : "Y",
        prFrnSeq : userInfo?.frnGradeCd === "F0001" ? null : userInfo?.frnSeq,
        userId: "",
        userNm:"",
        userHp:"",
        frnSeq: null,
        frnGradeCd: null,
        bizNm: "",
        regDtStart: "",
        regDtEnd: ""
    }

    const [data, setData] = useState(initialData);
    const [total, setTotal] = useState(0);
    const [list,setList] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [page, setPageNum] = useState(1);
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        let currentUrl = window.location.pathname;
        if(getPreviousUrl() === currentUrl) {
            let searchData = getSearchData();
            if(searchData) {
                searchData = {
                    ...searchData,
                    regDtStartUtc : searchData.regDtStartUtc ? dayjs(searchData.regDtStartUtc, "YYYY/MM/DD").add(1, 'day') : ""
                }
                setData(searchData);
                fetchData(searchData);
            } else {
                searchList(1);
            }
        } else {
            searchList(1);
        }
        removeSearchData();
        setCurrentUrl(currentUrl);
        getFrnGradeCode();
    }, []);

    useDidMountEffect(() => {
        searchList(page);
    }, [page])

    const searchList = (page) => {
        const tempData = {
            ...data,
            page,
        }
        setData(trimObject(tempData));
        setPageNum(page);
        fetchData(tempData);
    }

    const fetchData = async (data) => {
        const tempdata = {};
        Object.keys(data).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined ) {
            } else {
                tempdata[key] = data[key]
            }
        })

        let totalCnt = 0;

        try {
            const result = await admin.GetAdminUser(tempdata).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    totalCnt = res.totalCnt;
                    return res.datas.map((item) => {
                        return {
                            ...item,
                            key: item?.userId
                        }
                    })
                } else {
                    totalCnt = 0;
                    return [];
                }
            });
            // if(userInfo?.frnGradeCd !== "F0001"){
            //     const temp = {...tempdata};
            //     temp.frnSeq = userInfo.frnSeq;
            //     temp.prFrnSeq = "";
            //     await admin.GetAdminUser(temp).then((res)=>{
            //         if(res.datas && res.datas.length > 0){
            //             totalCnt += 1;
            //             result.unshift({
            //                 ...res.datas[0],
            //                 rownum: 0,
            //                 key: res.datas[0].userId
            //             })
            //         }
            //     });
            // }
            setTotal(totalCnt);
            setList(result);
        } catch {
            setTotal(0);
            setList([]);
        }
        setSelectedRowKeys([]);
    }

    const getFrnGradeCode = async () => {
        try {
            await common.GetCode({cdGrp: "F"}).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    const data = [];
                    data.push({
                        value: "",
                        label: "전체"
                    })
                    res.datas.forEach((item) => {
                        if (item.cdId !== "F0004") {
                            data.push({
                                value: item.cdId,
                                label: item.cdVal
                            })
                        }
                    });
                    setSelectList(data);
                }
            });
        } catch {
            setSelectList([]);
        }
    }

    const onSearch = () => {
        searchList(1);
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onSearch();
        }
    }

    const onClear = () => {
        setData(initialData);
        if(page === 1) {
            fetchData(initialData);
        } else {
            setPageNum(1);
        }
    }
    
    const onRegister = () => {
        setSearchData(data);
        navigate("/admin/0");
    }

    const onModify = (user) => {
        setSearchData(data);
        navigate('/admin/'+user.userId, { state : user });
    }
    
    const onDelete = () => {
        if (selectedRowKeys.length < 1) {
            setAlertMessage("선택한 항목이 없습니다.");
            setAlertShow("alert");
            return;
        }
        setAlertMessage("삭제하시겠습니까?");
        setAlertShow("alertMessage");
    }

    const deleteAdmin = () => {
        if(selectedRowKeys.includes(userInfo.userId) || selectedRowKeys.includes("admin")){ // 자기자신 삭제 막기
            setAlertMessage("최고 권한의 ID는 삭제할 수 없습니다.");
            setAlertShow("alert");
            searchList(1);
            return;
        }
        selectedRowKeys.map(async (userId) => {
            let frnSeq = 0;
            list.forEach((item) => {
                if (item.userId === userId) {
                    frnSeq = item.frnSeq;
                }
            })
            const temp = {
                frnSeq,
                userId,
                useYn: "N"
            }
            try {
                await admin.DeleteAdminUser(temp).then((res) => {
                    if (res && res.info) {
                        setAlertMessage("삭제되었습니다.");
                        setAlertShow("alert");
                        searchList(1);
                    } else {
                        setAlertMessage("다시 시도해주세요.");
                        setAlertShow("alert");
                        searchList(1);
                    }
                });
            } catch {
                setAlertMessage("오류가 발생하였습니다.");
                setAlertShow("alert");
                searchList(1);
            }
        })
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    const header = ["rownum", "frnGradeCd", "bizNm", "userId", "userNm", "userHp", "regDt"];
    const columns = header.map((key) => {
        if (key === "rownum") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
            }
        } else if (key === "frnGradeCd") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (value) => {
                    let returnValue = "";
                     selectList.map((item) => {
                        if (item.value === value) {
                            returnValue = item.label;
                        }
                    })
                    return returnValue
                }
            }
        } else if (key === "userHp") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    return phoneHypen(val);
                }
            }
        } else if (key === "regDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    return dayjs(val).format("YYYY-MM-DD");
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    });

    return (
        <Layout>
            <div className="field-wrap">
                <div className="search-field-wrap">
                    <h2>
                        회원 검색
                    </h2>
                    <div className="search-filter">
                        <Input
                            hasLabel
                            label="회원ID"
                            placeholder="회원ID를 입력하세요."
                            value={data.userId}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    userId: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                        <Input
                            hasLabel
                            label="이름"
                            placeholder="이름을 입력하세요."
                            value={data.userNm}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    userNm: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                        <Input
                            hasLabel
                            label="연락처"
                            placeholder="연락처를 입력하세요."
                            value={data.userHp}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    userHp: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                        <SearchSelect
                            label="소속 유형"
                            id="frnGradeCd"
                            placeholder="소속 유형을 선택하세요"
                            value={data.frnGradeCd}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    frnGradeCd: e
                                })
                            })}
                            options={selectList}
                            /*TODO: 로그인한 사람이 가맹본부가 아닐 경우 자기 소속으로 픽스*/
                            /*disabled={}*/
                        />
                        <Input
                            hasLabel
                            label="가맹점명"
                            placeholder="가맹점명을 입력하세요."
                            value={data.bizNm}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    bizNm: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                        <DateInput
                            hasLabel
                            label="등록일"
                            placeholder="날짜를 선택하세요."
                            value={data.regDtStartUtc}
                            onChange={(date) => setData((prev) => {
                                return ({
                                    ...prev,
                                    regDtStartUtc: date ?? "",
                                    regDtStart: utcToLocalformat(date)
                                })
                            })}
                        />
                    </div>
                </div>
                <div className="divider"></div>
                <div className="btn-between-wrap">
                    <div></div>
                    <div className="search-btn-center">
                        <button className={"btn btn-gray"} onClick={onClear}>
                            초기화
                        </button>
                        <button className={"btn btn-navy"} onClick={onSearch}>
                            검색하기
                        </button>
                    </div>
                    <div>
                        <button className={"btn btn-line-navy"} onClick={onRegister}>
                            + 회원등록
                        </button>
                    </div>
                </div>
            </div>
            <div className="division" />
            <div className="list-field-wrap">
                <h2>
                    회원 검색 결과
                </h2>
                <div className="count-and-button">
                    <p className="total-count">총 <span>{total}</span>건</p>
                    <div className="list-btn-wrap">
                        <button className={"btn-small btn-line-gray"} onClick={onDelete}>
                            삭제
                        </button>
                    </div>
                </div>
                <div className="table-wrap">
                    <Table
                        dataSource={list}
                        columns={columns}
                        size={"small"}
                        style={{ cursor: "pointer" }}
                        pagination={{
                            current: page,
                            onChange: (page) => setPageNum(page),
                            pageSize: 10,
                            total: total,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                        }}
                        onRow={(record) => {
                            return {
                                onClick: () => {
                                    onModify(record);
                                },
                            };
                        }}
                        rowSelection={rowSelection}
                    />
                </div>
            </div>
            <Alert
                isShow={alertShow === "alert"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
            />
            <AlertMessage
                isShow={alertShow === "alertMessage"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
                onOk={deleteAdmin}
            />
        </Layout>
    )
}

export default AdminList;