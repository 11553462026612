import React, {useState} from "react";
import "./Input.scss";
import Tip from "../Tip";
import {useDaumPostcodePopup} from "react-daum-postcode";

const PostInput = (props)=>{
    const inputPropsOnly = {
        ...props,
    };

    delete inputPropsOnly.onSetting;
    delete inputPropsOnly.postNum;
    delete inputPropsOnly.add1Value;

    const initialData = {
        zonecode: "",
        fullAddress: "",
    }

    const [data, setData] = useState(initialData);

    const open = useDaumPostcodePopup("https://t1.daumcdn.net/mapjsapi/bundle/postcode/prod/postcode.v2.js");

    const handleClick = () => {
        open({
            onComplete: handleComplete,
            popupTitle: "주소입력",
            defaultQuery: '',
            top: Math.ceil((window.screen.height - 600)/2),
            left: Math.ceil((window.screen.width - 500)/2),
            width: 500,
            height: 600
        });
    };

    const handleComplete = (data) => {
        let fullAddress = data.address;
        let extraAddress = '';

        if (data.addressType === 'R') {
            if (data.bname !== '') {
                extraAddress += data.bname;
            }
            if (data.buildingName !== '') {
                extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
            }
            fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
        }

        const tempData = {
            zonecode: data.zonecode,
            fullAddress: fullAddress,
        }

        setData(tempData);
        props.onSetting(tempData);
    };

    return (
        <div className="post-input-wrap">
            <label htmlFor={props.id}>{props.label}</label>
            {!props.readOnly &&
                <div className="post-flex-col">
                    <div className="post-address-num">
                        <input 
                            placeholder="우편번호"
                            value={props.postNum ?? data.zonecode}
                            readOnly
                            style={props.none ? { border: "1px solid #F04E3E" } : {}}
                        />
                        <button className={"btn btn-navy"} onClick={handleClick}>
                            우편번호
                        </button>
                    </div>
                    <input
                        placeholder="주소"
                        value={props.add1Value ?? data.fullAddress}
                        readOnly
                        style={props.none ? { border: "1px solid #F04E3E" } : {}}
                    />
                    <input
                        {...inputPropsOnly}
                        style={props.none ? { border: "1px solid #F04E3E" } : {}}
                    />
                    {props.none &&
                        <div className="tipWrap">
                            <Tip
                                content={props.tipContent ?? "필수 입력 사항입니다."}
                                size={12}
                            />
                        </div>
                    }
                </div>
            }
            {props.readOnly &&
                <div className="post-input-text">{props.value}</div>
            }
        </div>
    )
}

export default PostInput;