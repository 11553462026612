import { axiosInstance } from "../index";


const car = {
    GetCar: async (data) => {
        const result = await axiosInstance.post(`/car/get`, data);
        return result.data;
    },
    AddCar: async (data, params) => {
        const formData = new FormData();
        if (data) formData.append("file", data);

        const result = await axiosInstance.post(`/car/add`, formData, { params });
        return result.data;
    },
    ModifyCar: async (data, params) => {
        const formData = new FormData();
        if (data) formData.append("file", data);

        const result = await axiosInstance.post(`/car/modify`, formData, { params });
        return result.data;
    },
    SelectModifyCar: async (data) => {
        const result = await axiosInstance.post(`/car/modify/select`, data);
        return result.data;
    },
    AllModifyCar: async (data) => {
        const result = await axiosInstance.post(`/car/modify/all`, data);
        return result.data;
    },
    ExcelUpload: async (data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key)=>{
            formData.append(key, data[key]);
        });
        const result = await axiosInstance.post(`/car/excel/upload`, formData);
        return result.data;
    },
    DeleteCar: async (data) => {
        const result = await axiosInstance.post(`/car/delete`, data);
        return result.data;
    },
    CarChange: async (data, params) => {
        const formData = new FormData();
        formData.append("file", data);

        const result = await axiosInstance.post(`/car/change`, formData, { params });
        return result.data;
    },
    GetHierarchy: async (data) => {
        const result = await axiosInstance.post(`/car/dash/hierarchy`, data);
        return result.data;
    },
    GetEventOdo: async (data) => {
        const result = await axiosInstance.post(`/agg/event/odo`, data);
        return result.data;
    }
}

export default car;