import React, { useEffect, useState } from "react";
import services from "../services";
import { Table } from "antd";
import SelectBox from "../components/SelectBox/SelectBox";
import common from "../services/common";
import TipInput from "../components/Input/TipInput";
import Alert from "../components/Alert";
import car from "../services/car";
import admin from "../services/admin";

const ObdTestPage = () => {
    const [obdList, setObdList] = useState([]);
    const [carNmList, setCarNmList] = useState([]);
    const [carTypeList, setCarTypeList] = useState([]);
    const [carYearList, setCarYearList] = useState([]);
    const initialData = {
        carNo: "",
        carNmCd: null,
        carTypeCd: null,
        carYear: null,
        // useStat: "N",
    }
    const [data, setData] = useState(initialData);

    const alertClose = () => {
        setAlertShow("");
    }
    useEffect(() => {
        getObdList();
        getCarCode();
    }, []);
    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onCheck();
        }
    }
    const onModify = async () => {
        // '수정하시겠습니까?'
        console.log(data)
        try{
            await services.test.SetCar(data).then((res) => {
                // if (res.info) {
                    // '수정되었습니다'
                    setAlertMessage("변경 완료");
                    setAlertShow("alert");
                // }else{
                //     // fail
                //     // navigate("/car/stat");
                //     setAlertMessage("등록 실패하였습니다.");
                //     setAlertShow("alert");
                // }
            });
        }catch {
            // fail
            // navigate("car/stat");
            setAlertMessage("등록 실패하였습니다.");
            setAlertShow("alert");
        }

    }

    const checkValue = {
        "carNo": "차량번호",
        "carNmCd": "차종",
        "carTypeCd": "연료타입",
        "carYear": "연식",
    }
    const [noneValue, setNoneValue] = useState("");
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const onCheck = () => {
        let emptyValue = [];
        Object.keys(checkValue).forEach((key) => {
            if (key === "frnSeq" || key === "carNmCd" || key === "carTypeCd" || key === "carYear") {
                if (data[key] === 0 || data[key] === null || data[key] === undefined) {
                    emptyValue.push(checkValue[key]);
                }
            } else {
                if (data[key] === "" || data[key] === null || data[key] === undefined) {
                    emptyValue.push(checkValue[key]);
                }
            }
        })

        if (emptyValue.length > 0) {
            setNoneValue(emptyValue[0]);
            setAlertMessage(`${emptyValue[0]}을(를) 입력해주세요.`);
            setAlertShow("alertValue");
            return;
        }

        onModify();
    }
    const getCarCode = async () => {
        await common.GetCode({cdId: null}).then((res)=>{
            const carTypeList = [];
            const carNmList = [];
            const carYearList = [];
            if(res.datas && res.datas.length > 0){
                res.datas.forEach((item) => {
                    if(item.cdGrp === "C"){
                        carNmList.push({
                            value: item.cdId,
                            label: item.cdVal
                        });
                    }
                    else if(item.cdGrp === "E"){
                        carTypeList.push({
                            value: item.cdId,
                            label: item.cdVal
                        })
                    }else if(item.cdGrp === "Y"){
                        carYearList.push({
                            value: item.cdVal,
                            label: item.cdVal
                        })
                    }
                });
                setCarNmList(carNmList);
                setCarTypeList(carTypeList);
                setCarYearList(carYearList);

            }
        });
    }
    const getObdList = async () => {
        await services.test.GetObdList().then((res) => {
            console.log(res);
            setObdList(res);
        });
    }

    const header = ["carSeq", "carNo", "carVin", "carNm", "carType", "carYear", "obdUpdateDt", "dustVer", "obdVer", "appVer", "speed", "rpm", "steering", "odo", "safetyCarUpdateDt", "coolantTemp", "inTemp", "outTemp", "batteryCapa", "batteryVolt", "batteryCharge", "batteryTemp", "tpmsPressFl", "tpmsPressFr", "tpmsPressRl", "tpmsPressRr", "tpmsTempFl", "tpmsTempFr", "tpmsTempRl", "tpmsTempRr"];

    const columns = header.map((key) => {
        if (key === "carSeq") {
            return {
                title: key,
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                onCell: (record) => ({
                    onClick: () => {
                        // onModify(record)
                    },
                })
            };
        } else if (key === "obdUpdateDt" || key === "safetyCarUpdateDt") {
            return {
                title: key,
                dataIndex: key,
                key,
                sorter: (a, b) => new Date(a[key]) - new Date(b[key]),
                align: 'center',
                onCell: (record) => ({
                    onClick: () => {
                        // onModify(record)
                    },
                })
            };
        } else {
            return {
                title: key,
                dataIndex: key,
                key,
                align: 'center',
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                onCell: (record) => ({
                    onClick: () => {
                        // onModify(record)
                    },
                })
            };
        }
    });

    return (
        <div style={{overflow: "scroll", padding: "20px"}}>
            <h3>차량 옵션 변경</h3>
            <TipInput
                label="차량번호"
                id="carNo"
                placeholder="차량번호를 입력하세요"
                // none={noneValue === "차량번호" ? true : false}
                value={data.carNo}
                onChange={e => setData((prev) => {
                    return ({
                        ...prev,
                        carNo: e.target.value
                    })
                })}
                onEnter={onEnter}
                required
            />
            <SelectBox
                label="차종"
                id="carNmCd"
                placeholder="차종을 선택하세요"
                // none={noneValue === "차종" ? true : false}
                width={374}
                height={38}
                value={data.carNmCd}
                onChange={(e) => setData((prev) => {
                    return ({
                        ...prev,
                        carNm: e,
                        carNmCd: e
                    })
                })}
                options={carNmList}
                required
            />
            <SelectBox
                label="연료타입"
                id="carTypeCd"
                placeholder="연료타입을 선택하세요"
                // none={noneValue === "연료타입" ? true : false}
                width={374}
                height={38}
                value={data.carTypeCd}
                onChange={(e) => setData((prev) => {
                    return ({
                        ...prev,
                        carType: e,
                        carTypeCd: e
                    })
                })}
                options={carTypeList}
                required
            />
            <SelectBox
                label="연식"
                id="carYearCd"
                placeholder="연식을 선택하세요"
                // none={noneValue === "연식" ? true : false}
                width={374}
                height={38}
                value={data.carYear}
                onChange={(e) => setData((prev) => {
                    return ({
                        ...prev,
                        carYear: e
                    })
                })}
                options={carYearList}
                required
            />
            <div className="btn-center-wrap">
                <button className={"btn btn-navy"} onClick={onCheck}>
                    변경
                </button>
            </div>
            <div style={{
                width: "100%",
                height: "2px",
                background: "#333",
                margin: "10px 0px 10px 0px"
            }}></div>
            <h3>OBD DATE 목록</h3>
            <Table
                dataSource={obdList}
                size={"small"}
                columns={columns}
                style={{cursor: "pointer", width: "fit-content"}}
                pagination={{
                    pageSize: 999999,
                    total: obdList.length,
                    showSizeChanger: false,
                }}
                scroll={{
                    y: 700, // 테이블 본문 높이를 설정합니다. 이 값을 조정하여 스크롤 영역을 조절할 수 있습니다.
                    x: 4000
                }}
            />

            <Alert
                isShow={alertShow === "alert" || alertShow === "alertValue"}
                content={<p>{alertMsg}</p>}
                onClose={alertClose}
            />
        </div>
    );
}

export default ObdTestPage;
