import { axiosInstance } from "../index";


const device = {
    GetDevice: async (data) => {
        const result = await axiosInstance.post(`/device/get`, data);
        return result.data;
    },
    AddDevice: async (data) => {
        const result = await axiosInstance.post(`/device/add`, data);
        return result.data;
    },
    ModifyDevice: async (data) => {
        const result = await axiosInstance.post(`/device/modify`, data);
        return result.data;
    },
    DeleteDevice: async (data) => {
        const result = await axiosInstance.post(`/device/delete`, data);
        return result.data;
    }
}

export default device;