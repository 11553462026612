import "./MapSearch.scss";
import {CustomOverlayMap, Map, MapMarker, Polyline, useMap} from "react-kakao-maps-sdk";
import React, { useMemo, useState, useEffect, useCallback, useRef } from 'react';
import { Input, Tree, ConfigProvider } from 'antd';
import dayjs from 'dayjs';

import 'dayjs/locale/ko';

dayjs.locale('ko');


const getParentKey = (key, tree) => {
    let parentKey;
    for (let i = 0; i < tree.length; i++) {
        const node = tree[i];
        if (node.children) {
            if (node.children.some((item) => item.key === key)) {
                parentKey = node.key;
            } else if (getParentKey(key, node.children)) {
                parentKey = getParentKey(key, node.children);
            }
        }
    }
    return parentKey;
};
const EventMarkerContainer = ({position, content}) => {
    const map = useMap();
    const [isVisible, setIsVisible] = useState(false);
    const [address, setAddress] = useState("");
    let addressInfo;

    const getAddress = useCallback((lat, lng) => {
        if(addressInfo) return;
        const geocoder = new window.kakao.maps.services.Geocoder();
        const coord = new window.kakao.maps.LatLng(lat, lng);
        const callback = function (
            result,
            status
        ) {
            if (status === window.kakao.maps.services.Status.OK) {

                setAddress(result[0].address.address_name);
                addressInfo = {
                    lat: lat,
                    lng: lng,
                    addr: result[0].address.address_name
                }
            }
        };
        geocoder.coord2Address(coord.getLng(), coord.getLat(), callback);
    }, []);
    return (
        <>
            <MapMarker
                position={position}
                onClick={(marker)=>{
                    map.panTo(marker.getPosition());
                }}
                onMouseOver={()=>{
                    getAddress(position.lat, position.lng);
                    setIsVisible(true);
                }}
                onMouseOut={()=>setIsVisible(false)}
                image={{
                    src: "https://dev-image.static.vifense.com/dev/img/marker.svg",
                    size: {
                        width: 34,
                        height: 38
                    }
                }}
            >
                {/*{isVisible && content}*/}
            </MapMarker>
            <CustomOverlayMap
                position={position}
                yAnchor={1}
                xAnchor={1}
            >
                {isVisible &&
                    <div className={"marker-content-wrap"}>
                    <div className={"marker-title"}>
                        {content.carNo}
                    </div>
                    <div className={"marker-content"}>
                        <h3>{content.cruNm} 기사님</h3>
                        <h5>마지막 위치</h5>
                        <p>{address}</p>
                        <h5>마지막 운행시간</h5>
                        <p>{dayjs(content.dt).format("YYYY년 MM월 DD일 HH시 mm분")}</p>
                    </div>
                    </div>}

            </CustomOverlayMap>
        </>

    )
}
const MapSearch = (props) => {
    const [expandedKeys, setExpandedKeys] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [autoExpandParent, setAutoExpandParent] = useState(true);
    const [defaultData, setDefaultData] = useState([]);
    const [centerLat, setCenterLat] = useState("35.8771097");
    const [centerLnt, setCenterLnt] = useState("128.6675965");
    useEffect(() => {
        setList();
    }, []);
    useEffect(()=>{
        if(!props.targetData || props.targetData.length < 1) return;
        setCenterLat(props.targetData[props.targetData.length - 1].latitude);
        setCenterLnt(props.targetData[props.targetData.length - 1].longitude);
    }, [props.targetData]);
    useEffect(() => {
        setList();
    }, [props.list]);
    useEffect(()=>{
        if(props?.locationList && props.locationList?.length > 0 && !props.targetData ){
            setCenterLat(props.locationList[0].latitude);
            setCenterLnt(props.locationList[0].longitude);
        } else return;
    }, [props.locationList]);
    const setList = () => {
        if (!props.list) return;

        const formattedList = props.list.map((v, index) => {
            v.title = v.bizNm;
            v.key = "frnSeq" + v.frnSeq + "i" + index;
            v.children = v.child?.map((i, index2) => {
                i.key = "carSeq" + i.carSeq + "i" + index2 + "f" + i.frnSeq + "cruSeq" + i.cruSeq;
                i.title = i.carNo + " - " + (i.cruNm ? i.cruNm : "기사 없음");
                i.isLeaf = true;
                return i;
            });
            return v;
        });
        setDefaultData(formattedList);
        setExpandedKeys(formattedList.map((v)=>{
            return v.key;
        }));
    };

    const dataList = useMemo(() => {
        const result = [];
        const generateList = (data) => {
            for (let i = 0; i < data.length; i++) {
                const node = data[i];
                const { key } = node;
                result.push({
                    key,
                    title: node.title,
                });
                if (node.children) {
                    generateList(node.children);
                }
            }
        };
        generateList(defaultData);
        return result;
    }, [defaultData]);

    const treeData = useMemo(() => {
        const loop = (data) =>
            data.map((item) => {
                const strTitle = item.title;
                const index = strTitle.indexOf(searchValue);
                const beforeStr = strTitle.substring(0, index);
                const afterStr = strTitle.slice(index + searchValue.length);
                const title =
                    index > -1 ? (
                        <span>
                            {beforeStr}
                            <span className="site-tree-search-value">
                                {searchValue}
                            </span>
                            {afterStr}
                        </span>
                    ) : (
                        <span>{strTitle}</span>
                    );
                if (item.children) {
                    return {
                        title,
                        key: item.key,
                        children: loop(item.children),
                    };
                }

                let marked = false;
                if(props.locationList && props.locationList.length > 0){
                    for(let i=0; i<props.locationList.length; i++){
                        if(props.locationList[i].carSeq === Number(item.key.split("i")[0].slice(6))
                        ){
                            marked = true;
                            break;
                        }
                    }
                }
                return {
                    title,
                    key: item.key,
                    // icon:  ({selected}) => (selected ? <div>1</div> : <div>2</div>)
                    //props.locationList 에 key 의 카시퀀스가 존재하면 다른 아이콘?
                    icon:  marked ? <div style={{width: "100%", height: "100%", display: "flex", alignItems: "center", justifyContent: "center"}}><img src={"/assets/icons/marker.svg"} alt='' /></div> : <div></div>
                };
            });
        return loop(defaultData);
    }, [searchValue, defaultData, props.locationList]);

    const onExpand = (newExpandedKeys) => {
        setExpandedKeys(newExpandedKeys);
        setAutoExpandParent(false);
    };

    const onChange = (e) => {
        const { value } = e.target;
        const newExpandedKeys = dataList
            .map((item) => {
                if (item.title.indexOf(value) > -1) {
                    return getParentKey(item.key, defaultData);
                }
                return null;
            })
            .filter((item, i, self) => !!(item && self.indexOf(item) === i));
        setExpandedKeys(newExpandedKeys);
        setSearchValue(value);
        setAutoExpandParent(true);
    };

    const onSelect = (key, info)=>{
        //TODO 여기서 경로 찍기
        props.onSelect(key, info);
    }
    return (
        <div className="map-search-wrap" ref={props.useRef}>
            <div className="car-search-wrap">
                <div className="car-search">
                    <ConfigProvider
                        theme={{
                            token: {
                                borderRadius: 12,
                                colorBorder: "#D3D4D5",
                                colorBgContainer: '#fff',
                                fontSize: 15,
                                controlHeight: 48,
                                colorPrimary: "#7688A8",
                            },
                            components: {
                                Select: {
                                    optionFontSize: 15,
                                    optionHeight: 48,
                                    optionLineHeight: 2,
                                },
                            },
                        }}
                    >
                        <Input
                            style={{
                                width: 247,
                            }}
                            suffix={<img src="/assets/icons/icon_search.svg" alt="search" />}
                            placeholder="차량 번호를 입력하세요"
                            onChange={onChange}
                        />
                    </ConfigProvider>
                </div>
                <div className="car-category">
                    <ConfigProvider
                        theme={{
                            token: {
                                borderRadius: 0,
                            },
                            components: {
                                Tree: {
                                    titleHeight: 32,
                                },
                            },
                        }}
                    >
                        <Tree
                            showIcon
                            // switcherIcon={<div>3</div>} -> 카테고리 아이콘
                            // defaultExpandAll
                            onExpand={onExpand}
                            expandedKeys={expandedKeys}
                            autoExpandParent={autoExpandParent}
                            treeData={treeData}
                            onSelect={onSelect}
                            blockNode
                        />
                    </ConfigProvider>
                </div>
            </div>
            <div className="car-map-wrap">
                <Map
                    id="map"
                    center={{
                        lat: centerLat,
                        lng: centerLnt,
                    }}
                    style={{
                        width: "100%",
                        height: "100%",
                    }}
                    level={6}
                >
                    {props?.targetData &&
                        <Polyline
                            path={props.targetData.map((v)=>{return {lat: v.latitude, lng: v.longitude}})}
                            strokeWeight={5}
                            strokeColor={"#ff6a00"}
                            strokeOpacity={0.8}
                            strokeStyle={"solid"}
                        />
                    }
                    {props?.locationList &&
                        props.locationList.map((v)=>(
                            <EventMarkerContainer
                                key={`EMK-${v.latitude}-${v.longitude}`}
                                position={{lat: v.latitude, lng: v.longitude}}
                                content={v}
                            />
                        ))
                    }
                </Map>
            </div>
        </div>
    );
};

export default MapSearch;
