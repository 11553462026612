import "./Tip.scss";

const Tip = (props)=>{
    return (
        <div className="Tip">
            <img src="/assets/images/exclamation_mark.svg" height="100%" />
            <span style={{ fontSize: props.size ? props.size + "px" : "12px" }}>{props.content}</span>
        </div>
    )
}

export default Tip;