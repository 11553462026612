import React, {useEffect, useState} from "react";
import Input from "../../components/Input/Input";
import Layout from "../../layout/Layout";
import {Table} from "antd";
import translator from "../../utils/translator";
import {useNavigate} from "react-router-dom";
import { saveAs } from "file-saver";
import dayjs from "dayjs";
import {
    dateSorter,
    getPreviousUrl,
    getSearchData, removeSearchData,
    setCurrentUrl,
    setSearchData,
    stringSorter,
    trimObject
} from "../../utils";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import device from "../../services/device";
import SearchSelect from "../../components/SelectBox/SearchSelect";
import Alert from "../../components/Alert";
import AlertMessage from "../../components/Alert/AlertMessage";
import franchise from "../../services/franchise";
import * as Excel from "exceljs";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const optionGrade = [
    {
        value: '',
        label: '전체',
    },
    {
        value: 'F0003',
        label: '법인',
    },
    {
        value: 'F0004',
        label: '개인',
    },
];

const DeviceList = ()=>{
    const userInfo = useRecoilValue(userState);
    const initialData = {
        page: 1,
        pageSize: 10,
        prFrnSeq : userInfo?.frnGradeCd === "F0001" ? null : (userInfo?.frnGradeCd === "F0002" ? userInfo?.frnSeq : userInfo?.prFrnSeq),
        frnSeq: null,
        deviceMac: "",
        carNo: "",
        bizNm: "",
        installDtStart: "",
        installDtEnd: "",
        regDtStart: "",
        regDtEnd: "",
        useYn: "Y"
    }

    const [data, setData] = useState(initialData);
    const [total, setTotal] = useState(0);
    const [list,setList] = useState([]);
    const [frnList, setFrnList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [page, setPageNum] = useState(1);
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const [excelData, setExcel] = useState([]);
    const excelHeaders = ["순번", "소속", "가맹점명", "MAC주소", "차량번호", "컨트롤러", "OBD", "등록일"];

    const navigate = useNavigate();

    useEffect(() => {
        let currentUrl = window.location.pathname;
        if(getPreviousUrl() === currentUrl) {
            let searchData = getSearchData();
            if(searchData) {
                setData(searchData);
                fetchData(searchData);
            } else {
                searchList(1);
            }
        } else {
            searchList(1);
        }
        removeSearchData();
        setCurrentUrl(currentUrl);
        getFranchise();
    }, []);

    useDidMountEffect(() => {
        searchList(page);
    }, [page])

    const searchList = (page) => {
        const tempData = {
            ...data,
            page,
        }
        setData(trimObject(tempData));
        setPageNum(page);
        fetchData(tempData);
    }

    const fetchData = async (data) =>  {
        try {
            const result = await device.GetDevice(data).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    setTotal(res.totalCnt);
                    return res.datas.map((item) => {
                        return {
                            ...item,
                            key: item.deviceSeq
                        }
                    })
                } else {
                    setTotal(0);
                }
            });
            setList(result);
        } catch {
            setTotal(0);
            setList([]);
        }
        setSelectedRowKeys([]);
        setExcel([]);
    }

    const getFranchise = async () => {
        const tempData = {
            page: 1,
            pageSize: 9999,
            frnGradeCd: "F0002",
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        setFrnList((prev) => prev.concat({
                            value: item.frnSeq,
                            label: item.bizNm
                        }))
                    })
                }
            });
        } catch {
            setFrnList([]);
        }
    }

/*    const getFrnGradeCode = async () => {
        await common.GetCode({cdGrp: "F"}).then((res) => {
            if (res.datas && res.datas.length > 0) {
                const data = [];
                data.push({
                    value: "",
                    label: "전체"
                })
                res.datas.forEach((item) => {
                    data.push({
                        value: item.cdId,
                        label: item.cdVal
                    })
                })
                setSelectList(data);
            } else {
                return res;
            }
        });
    }*/

    const onSearch = () => {
        searchList(1);
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onSearch();
        }
    }

    const onClear = () => {
        setData(initialData);
        if(page === 1) {
            fetchData(initialData);
        } else {
            setPageNum(1);
        }
    }

    const onDetail = (device) => {
        setSearchData(data);
        navigate('/device/'+device.deviceSeq, { state : device });
    }

    const onDetailCar = (device) => {
        setSearchData(data);
        navigate('/car/detail/'+device.carSeq, { state : device });
    }

    const onDelete = () => {
        if (selectedRowKeys.length < 1) {
            setAlertMessage("선택한 항목이 없습니다.");
            setAlertShow("alert");
            return;
        }
        setAlertMessage("삭제하시겠습니까?");
        setAlertShow("alertMessage");
    }

    const deleteDevice = () => {
        selectedRowKeys.map(async (deviceSeq) => {
            const temp = {
                deviceSeq,
                useYn: "N"
            }
            try {
                await device.DeleteDevice(temp).then((res) => {
                    if (res.info) {
                        setAlertMessage("삭제되었습니다.");
                        setAlertShow("alert");
                        searchList(1);
                    } else {
                        setAlertMessage("다시 시도해주세요.");
                        setAlertShow("alert");
                        searchList(1);
                    }
                });
            } catch {
                setAlertMessage("오류가 발생하였습니다.");
                setAlertShow("alert");
                searchList(1);
            }
        })
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, data) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setExcel(data);
        },
        fixed: true
    };

    const header = ["rownum", "prBizNm", "bizNm", "deviceMac", "carNo", "dustVer", "obdVer", "regDt"];

    const columns = header.map((key) => {
        if (key === "rownum") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onDetail(record)
                        },
                    };
                }
            }
        } else if (key === "carNo") {
             return {
                 title: translator[key],
                 dataIndex: key,
                 key,
                 sorter: (a, b) => stringSorter(a, b, key),
                 align: 'center',
                 render: (value, data) => {
                     return <div className="table-text-blue" onClick={() => onDetailCar(data)}>{value}</div>
                 }
             }
         } else if (key === "regDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    return dayjs(val).format("YYYY-MM-DD HH:mm:ss");
                },
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onDetail(record)
                        },
                    };
                }
            }
        } else if (key === "dustVer" || key === "obdVer") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onDetail(record)
                        },
                    };
                },
                render: (val) => {
                    if (val) {
                        return <div>{val}</div>
                    } else {
                        return <div className="table-text-red">정보없음</div>
                    }
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onDetail(record)
                        },
                    };
                }
            }
        }
    });
    const onExcelDownload = async () => {
        try {
            const wb = new Excel.Workbook();
            const sheet = wb.addWorksheet(`기기관리`);

            const headerRow = sheet.addRow(excelHeaders);
            headerRow.height = 30.75;
            headerRow.eachCell((cell, colNum) => {
                sheet.getColumn(colNum).width = 20;
                cell.font = {
                    bold: true,
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            let temp = {
                ...data,
                pageSize: 999999,
                page: 1
            }
            let excelList;
            await device.GetDevice(temp).then((res)=>{
                if(res.datas && res.datas.length > 0){
                    excelList = res.datas;
                }
            })
            const selectedData = selectedRowKeys.length === 0 ? excelList : excelData;

            const datas = [];

            const column = ["rownum", "prBizNm", "bizNm", "deviceMac", "carNo", "dustVer", "obdVer", "regDt"];
            selectedData.forEach((v)=>{
                const row = {};
                Object.keys(v).forEach((key)=>{
                    if(header.includes(key)){
                        if (key === "regDt") {
                            row[key] = dayjs(v[key]).format("YYYY-MM-DD HH:mm:ss");
                        }
                        else {
                            row[key] = v[key];
                        }
                    }
                });
                datas.push(row);
            });
            datas.forEach((item) => {
                const rowDatas = column.map(key => item[key]);
                const appendRow = sheet.addRow(rowDatas);
                appendRow.eachCell((cell, colNum) => {
                    cell.alignment = {
                        horizontal: "center",
                    }
                });
            });
            const fileData = await wb.xlsx.writeBuffer();
            const blob = new Blob([fileData], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `기기관리`);
        } catch (error) {
        }
    }

    return (
        <Layout>
            <div className="field-wrap">
                <div className="search-field-wrap">
                    <h2>
                        기기 검색
                    </h2>
                    <div className="search-filter">
                        <Input
                            hasLabel
                            label="MAC주소"
                            placeholder="MAC주소를 입력하세요."
                            value={data.deviceMac}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    deviceMac: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                        <Input
                            hasLabel
                            label="차량번호"
                            placeholder="차량번호를 입력하세요."
                            value={data.carNo}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    carNo: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                        <div></div>
                        {/*<DateInput
                            hasLabel
                            label="장착일"
                            placeholder="날짜를 선택하세요."
                            value={data.installDtStartUtc}
                            onChange={(date) => setData((prev) => {
                                return ({
                                    ...prev,
                                    installDtStartUtc: date ?? "",
                                    installDtStart: utcToLocalformat(date),
                                })
                            })}
                        />*/}
                        {userInfo?.frnGradeCd === "F0001" &&
                            <SearchSelect
                                label="소속"
                                id="prFrnSeq"
                                placeholder="소속을 선택하세요"
                                value={data.prFrnSeq}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        prFrnSeq: e
                                    })
                                })}
                                options={frnList}
                            />
                        }
                        <SearchSelect
                            label="소속 유형"
                            id="frnGradeCd"
                            placeholder="소속 유형을 선택하세요"
                            value={data.frnGradeCd}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    frnGradeCd: e
                                })
                            })}
                            options={optionGrade}
                            /*TODO: 로그인한 사람이 가맹본부가 아닐 경우 자기 소속으로 픽스*/
                            /*disabled={}*/
                        />
                        <Input
                            hasLabel
                            label="가맹점명"
                            placeholder="가맹점명을 입력하세요."
                            value={data.bizNm}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    bizNm: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                    </div>
                </div>
                <div className="divider"></div>
                <div className="search-btn-center-wrap">
                    <button className={"btn btn-gray"} onClick={onClear}>
                        초기화
                    </button>
                    <button className={"btn btn-navy"} onClick={onSearch}>
                        검색하기
                    </button>
                </div>
            </div>
            <div className="division" />
            <div className="list-field-wrap">
                <h2>
                    기기 검색 결과
                </h2>
                <div className="count-and-button">
                    <p className="total-count">총 <span>{total}</span>건</p>
                    <div className="list-btn-wrap">
                        <button className={"btn-small btn-line-gray"} onClick={onExcelDownload}>
                            <img src="/assets/icons/icon_download_excel.svg"></img>
                            <span>엑셀다운로드</span>
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onDelete}>
                            삭제
                        </button>
                    </div>
                </div>
                <div className="table-wrap">
                    <Table
                        dataSource={list}
                        columns={columns}
                        size={"small"}
                        style={{ cursor: "pointer" }}
                        pagination={{
                            current: page,
                            onChange: (page) => setPageNum(page),
                            pageSize: 10,
                            total: total,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                        }}
                        rowSelection={rowSelection}
                    />
                </div>
            </div>
            <Alert
                isShow={alertShow === "alert"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
            />
            <AlertMessage
                isShow={alertShow === "alertMessage"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
                onOk={deleteDevice}
            />
        </Layout>
    )
}

export default DeviceList;