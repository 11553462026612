import { axiosInstance, nonAuthInstance } from "../index";


const main = {
    GetDistanceSum: async (data) => {
        const result = await axiosInstance.post(`/agg/drivestat/sum`, data);
        return result.data.info;
    },
    GetDistance: async (data) => {
        const result = await axiosInstance.post(`/agg/drivestat/select`, data);
        return result.data;
    },
    GetAirScore: async (data) => {
        const result = await axiosInstance.post(`/agg/airqualityscore/select`, data);
        return result.data;
    },
    GetAirScoreSum: async (data) => {
        const result = await axiosInstance.post(`/agg/airqualityscore/sum`, data);
        return result.data.info;
    },
    GetAirScoreAll: async (data) => {
        const result = await axiosInstance.post(`/agg/airqualityscore/day`, data);
        return result.data;
    },
    GetDriveScore: async (data) => {
        const result = await axiosInstance.post(`/agg/safetydrivescore/select`, data);
        return result.data;
    },
    GetDriveScoreSum: async (data) => {
        const result = await axiosInstance.post(`/agg/safetydrivescore/sum`, data);
        return result.data.info;
    },
    GetDriveScoreAll: async (data) => {
        const result = await axiosInstance.post(`/agg/safetydrivescore/day`, data);
        return result.data;
    },
    GetCarDtc: async (data) => {
        const result = await axiosInstance.post(`/agg/cardtc/select`, data);
        return result.data;
    },
    GetLocation: async (data) => {
        const result = await axiosInstance.post(`/agg/event/location`, data);
        return result.data;
    },
    GetScoreFrn: async (data) => {
        const result = await axiosInstance.post(`/agg/airdrive/frn`, data);
        return result.data;
    },
    GetScoreCru: async (data) => {
        const result = await axiosInstance.post(`/agg/airdrive/cru`, data);
        return result.data;
    },
    GetEventSafetycar: async (data) => {
        const result = await axiosInstance.post(`/agg/event/safetycar`, data);
        return result.data;
    },

}

export default main;