import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import { userState } from "../stores/UserStore";
import Layout from "../layout/Layout";
import ContentCard from "../components/ContentCard";
import SearchCard from "../components/SearchCard";
import { ResponsivePie } from '@nivo/pie';
import main from "../services/main";
import car from "../services/car";
import common from "../services/common";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import dayjs from "dayjs";
import franchise from "../services/franchise";
import {hexToNum, setCurrentUrl} from "../utils";

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const Main = ()=>{
    const userInfo = useRecoilValue(userState);
    const yesterday = dayjs().subtract(1, "day");
    const initialData = {
        page: 1,
        pageSize: 10,
        cruSeq: null,
        carSeq: null,
        frnSeq: userInfo?.frnGradeCd === "F0003" ? userInfo?.frnSeq : null,
        // prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? userInfo?.frnSeq : userInfo?.prFrnSeq,
        prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? (userInfo.frnGradeCd === "F0001" ? 2 : userInfo?.frnSeq) : userInfo?.prFrnSeq,
        frnGradeCd: "",
        bizNm: "",
        carNo: "",
        cruNm: "",
        cruHp: "",
        address: "",
        address1: "",
        address2: "",
        email: "",
        useYn: "Y",
        carNmCd: "",
        carTypeCd: "",
        regDtStart: dayjs(yesterday).format("YYYY-MM-DD 00:00:00"),
        regDtEnd: dayjs(yesterday).format("YYYY-MM-DD 23:59:59")
    }
    const textArr = ["우수", "양호", "주의"];
    const [data, setData] = useState(initialData);
    const [frnList, setFrnList] = useState([]);
    const [bizList, setBizList] = useState([]);
    const [driveCar, setDriveCar] = useState(0);
    const [distance, setDistance] = useState("");
    const [dtcCount, setDtcCount] = useState(0);
    const [totalCar, setTotalCar] = useState(0);
    const [waitCar, setWaitCar] = useState(0);
    const [drivePer, setDrivePer] = useState(0);
    const [waitPer, setWaitPer] = useState(0);
    const [selectDateType, setDateType] = useState('daily');
    const [driveScoreList, setDriveScoreList] = useState([0, 0, 0]);
    const [dustScoreList, setDustScoreList] = useState([0, 0, 0]);
    const [isShowDrive, setShowDrive] = useState(true);
    const [isShowDust, setShowDust] = useState(true);
    const [isClickEnter, setClickEnter] = useState(false);
    const optionGrade = [
        {
            value: '',
            label: '전체',
        },
        {
            value: 'F0003',
            label: '법인',
        },
        {
            value: 'F0004',
            label: '개인',
        },
    ];

    const navigate = useNavigate();

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        getOptionFranchise();
        onSearch();
    }, [])

    useEffect(() => {
        const temp = {
            ...data,
            frnSeq: null
        }
        setData(temp)
        getOptionBiz(temp);
    }, [data.prFrnSeq, data.frnGradeCd])

    useEffect(() => {
        if (isClickEnter) {
            onSearch();
            setClickEnter(false);
        }
    }, [data.frnSeq])

    useEffect(() => {
        if(selectDateType === "daily"){
            setData((prev) => {
                return ({
                    ...prev,
                    regDtStart: dayjs(yesterday).format("YYYY-MM-DD 00:00:00"),
                    regDtEnd: dayjs(yesterday).format("YYYY-MM-DD 23:59:59")
                })
            })
        }else if(selectDateType === "monthly"){
            let lastDay = new Date(yesterday.format("YYYY"), yesterday.format("MM"), 0);
            setData((prev) => {
                return ({
                    ...prev,
                    regDtStart: dayjs(yesterday).format("YYYY-MM-01 00:00:00"),
                    regDtEnd: dayjs(lastDay).format("YYYY-MM-DD 23:59:59")
                })
            })
        }
    }, [selectDateType])
    const getScoreData = (score, max) => {
        const num = hexToNum(score, 2);
        const scoreData = [];
        for(let i = 0; i < 3; i++) {
            const data = {
                key: i,
                lowScore: num[i],
                highScore: i === 0 ? max : num[i - 1] - 1,
                text: textArr[i],
            }
            scoreData.push(data);
        }
        return scoreData;
    }
    const onSearch = async () => {
        const temp = {
            prFrnSeq: data?.prFrnSeq,
            // frnSeq: data?.frnSeq ? data.frnSeq : null,
            frnSeq: userInfo?.frnGradeCd === "F0003" ? userInfo?.frnSeq : (data.frnSeq ? data.frnSeq : null),
            startAggDay: data?.regDtStart ? data.regDtStart : null,
            endAggDay: data?.regDtEnd ? data.regDtEnd : null,
            frnGradeCd: data.frnGradeCd ? data.frnGradeCd : null
        }
        try{
            //AppConfig
            let driveRange;
            let dustRange;
            await common.GetAppConfig().then((res)=>{
                if(res){
                    driveRange = getScoreData(res.driveScoreRange, 100);
                    dustRange = getScoreData(res.dustScoreSetPacket, 100);
                }
            })
            //TODO 차량 토탈 개수 조회
            let totalCnt = 0;
            await car.GetCar(temp).then((res)=>{
               if(res && res.datas?.length > 0){
                   totalCnt = res.totalCnt;
                   setTotalCar(totalCnt);
               }else{
                   setTotalCar(0);
               }
            });
            await main.GetDistance(temp).then((res)=>{
                if(res && res.datas?.length > 0){
                    let driveArr = [];
                    res.datas.forEach((v,i,a)=>{
                        if(!driveArr.includes(v.carSeq) && v.distance > 0){
                            driveArr.push(v.carSeq);
                        }
                    });
                    setDriveCar(driveArr.length);
                    if(totalCnt > 0){
                        setWaitCar(totalCnt - driveArr.length);
                        setDrivePer((driveArr.length / totalCnt * 100).toFixed(1));
                        setWaitPer(100 - (driveArr.length / totalCnt * 100).toFixed(1));
                    }else{
                        setDriveCar(0);
                        setWaitCar(0);
                        setDrivePer(0);
                        setWaitPer(0);
                    }
                }else{
                    setDriveCar(0);
                    setWaitCar(totalCnt);
                    setDrivePer(0);
                    setWaitPer(totalCnt === 0 ? 0 : 100);
                }
            });
            await main.GetDistanceSum(temp).then((res)=>{
                if(res && res.distance){
                    setDistance(res.distance.toLocaleString());
                }else{
                    setDistance(0);
                }
            });

            let scoreList;
            if(temp.frnSeq && temp.frnSeq > 0){
                await main.GetScoreCru(temp).then((res)=>{
                    scoreList = res.info.cru;
                });
            }else if(temp.prFrnSeq && temp.prFrnSeq > 0){
                await main.GetScoreFrn(temp).then((res)=>{
                    scoreList = res.info.frn;
                });
            }

            let driveScoreCount = [0, 0, 0];
            let dustScoreCount = [0, 0, 0];
            scoreList.forEach((v)=>{
                //DRIVE SCORE
                if(v.driveScore >= driveRange[0].lowScore){
                    driveScoreCount[0] = driveScoreCount[0] + 1;
                }else if(v.driveScore >= driveRange[1].lowScore){
                    driveScoreCount[1] = driveScoreCount[1] + 1;
                }else{
                    driveScoreCount[2] = driveScoreCount[2] + 1;
                }
                //DUST SCORE(AIR)
                if(v.dustScore >= dustRange[0].lowScore){
                    dustScoreCount[0] = dustScoreCount[0] + 1;
                }else if(v.dustScore >= dustRange[1].lowScore){
                    dustScoreCount[1] = dustScoreCount[1] + 1;
                }else{
                    dustScoreCount[2] = dustScoreCount[2] + 1;
                }
            });
            setDriveScoreList(driveScoreCount);
            setDustScoreList(dustScoreCount);
            if(driveScoreCount[0] + driveScoreCount[1] + driveScoreCount[2] <= 0){
                setShowDrive(false);
            }else {
                setShowDrive(true);
            }
            if(dustScoreCount[0] + dustScoreCount[1] + dustScoreCount[2] <= 0){
                setShowDust(false);
            }else {
                setShowDust(true);
            }
            await main.GetCarDtc(temp).then((res)=>{
                if(res && res.datas?.length > 0){
                    let count = res.datas.reduce(function(acc, current){
                        if (acc.findIndex(({carSeq})=>carSeq === current.carSeq) === -1){
                            acc.push(current);
                        }
                        return acc;
                    }, []);
                    setDtcCount(count.length);
                }else{
                    setDtcCount(0);
                }
            });
        }catch (e){

        }
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            setClickEnter(true);
        }
    }

    const getOptionFranchise = () => {
        const searchData = ["F0002"];
        // const searchData = ["F0001", "F0002"];

        searchData.forEach(async (item) => {
            const tempData = {
                page: 1,
                pageSize: 9999,
                frnGradeCd: item,
                useYn: "Y",
                regDtStart: "",
                regDtEnd: ""
            }
            try {
                await franchise.GetFranchise(tempData).then((res) => {
                    if (res.datas && res.datas.length > 0) {
                        res.datas.forEach((item) => {
                            setFrnList((prev) => prev.concat({
                                value: item.frnSeq,
                                label: item.bizNm
                            }))
                        })
                    }
                });
            } catch {

            }
        });
    }

    const getOptionBiz = async (temp) => {
        const tempData = {
            ...temp,
            page: 1,
            pageSize: 9999,
            useYn: "Y",
            regDtStart: "",
            regDtEnd: ""
        }
        try {
            await franchise.GetFranchise(tempData).then(async (res) => {
                const data = [];
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        // data.push({
                        //     value: item.frnSeq,
                        //     label: item.bizNm
                        // })
                        if(item.frnGradeCd === "F0002" || item.frnGradeCd === "F0001"){

                        }else{
                            data.push({
                                value: item.frnSeq,
                                label: item.bizNm
                            });
                        }
                    })
                }
                setBizList(data);
            });
        } catch {

        }
    }

    return (
        <>
            <Layout
                children={
                <div className="main-wrap">
                    <div className="search-wrap">
                        <SearchCard
                            title="AI 리포트"
                            frnList={frnList}
                            frnValue={data.prFrnSeq}
                            onChangeFrn={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    prFrnSeq: e
                                })
                            })}
                            typeList={optionGrade}
                            typeValue={data.frnGradeCd}
                            onChangeType={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    frnGradeCd: e
                                })
                            })}
                            searchList={bizList}
                            searchValue={data.frnSeq}
                            onChangeSearch={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    frnSeq: e
                                })
                            })}
                            onKeyPress={onEnter}
                            dateList={[
                                {
                                    value: 'monthly',
                                    label: '월간',
                                },
                                {
                                    value: 'daily',
                                    label: '일간',
                                },
                            ]}
                            dateValue={selectDateType}
                            onChangeDateType={(value) => {
                                setDateType(value);
                            }}
                            startValue={data.regDtStart}
                            endValue={data.regDtEnd}
                            onChangeDate={(value, dateString) => {
                                setData((prev) => {
                                    if (value) {
                                        let endDt = dayjs(value[1]);
                                        var lastDay = new Date(endDt.format("YYYY"), endDt.format("MM"), 0);
                                        return ({
                                            ...prev,
                                            regDtStartUtc: dateString[0].split("-").length == 2 ? dayjs(dateString[0]+"-01") : value[0],
                                            regDtEndUtc: dateString[1].split("-").length == 2 ? dayjs(lastDay) : value[1],
                                            regDtStart: dateString[0].split("-").length == 2 ? dateString[0]+"-01 00:00:00" : dateString[0] + " 00:00:00",
                                            regDtEnd: dateString[1].split("-").length == 2 ? dayjs(lastDay).format("YYYY-MM-DD 23:59:59") : dateString[1] + " 23:59:59"
                                        })
                                    } else {
                                        return ({
                                            ...prev,
                                            regDtStartUtc: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01"),
                                            regDtEndUtc: dayjs(yesterday).format("YYYY-MM-DD"),
                                            regDtStart: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01") + " 00:00:00",
                                            regDtEnd: dayjs(yesterday).format("YYYY-MM-DD") + " 23:59:59"
                                        })
                                    }
                                })
                            }}
                            onClick={onSearch}
                            readOnly={userInfo?.frnGradeCd !== "F0001"}
                        />
                    </div>
                    <div className="content-wrap">
                        <ContentCard
                            cardType="normal"
                            title="등록차량"

                            onClick={()=>{
                                navigate("/car", {state: {
                                        scroll: "carList"
                                    }});
                            }}
                            children={
                                <>
                                    <div className="subTitle">
                                        <span>총</span>{totalCar.toLocaleString()}<span>대</span>
                                    </div>
                                    <div className="description">
                                        <p>운행 {driveCar.toLocaleString()}대(<span>{drivePer}%</span>)</p>
                                        <p>대기 {waitCar.toLocaleString()}대({waitPer}%)</p>
                                    </div>
                                    <div className="content" style={{
                                        width:"50%",
                                        height: "100px",
                                    }}>
                                        <Bar
                                            options={{
                                                maintainAspectRatio: false,
                                                responsive: true,
                                                plugins: {
                                                    legend: {display: false},
                                                },
                                                scales:{
                                                    x: {display: false,},
                                                    y: {display: false,}
                                                },
                                            }}
                                            data={{
                                                labels: ['대기', '운행'],
                                                datasets: [{
                                                    data: [waitCar, driveCar],
                                                    backgroundColor: ["#BEBFC0", "#50B6FF"],
                                                }],
                                            }}
                                        />
                                    </div>
                                </>
                            }
                        />
                        <ContentCard
                            cardType="normal"
                            title="운행률"
                            onClick={()=>{
                                navigate("/car", {state: {
                                        scroll: "map"
                                    }});
                            }}
                            children={
                                <>
                                    <div className="subTitle">
                                        <span className="label">운행중</span>{driveCar.toLocaleString()}<span>대</span>
                                    </div>
                                    <div className="description">
                                        <p>총 {totalCar.toLocaleString()}대</p>
                                    </div>
                                    <div className="content" style={{
                                        width:"130px",
                                        height: "130px",
                                    }}>
                                        <div style={{ width: '130px', height: '130px', margin: '0 auto', postion: "relative"}}>
                                            <div className={"pie-chart-ball"}>
                                                <p><span>{drivePer? Math.floor(drivePer) : 0}</span>%</p>
                                            </div>
                                            <ResponsivePie
                                                /**
                                                 * 참고 document
                                                 * https://nivo.rocks/pie/
                                                 */
                                                data={[
                                                    { id: '운행중', value: driveCar },
                                                    { id: '대기중', value: waitCar },
                                                ]}
                                                animate={true}
                                                enableArcLinkLabels={false}
                                                innerRadius={0.8}
                                                padAngle={1.5}
                                                cornerRadius={8}
                                                colors={['#50B6FF', "#ddd"]} // 커스터하여 사용할 때
                                                theme={{
                                                    labels: {
                                                        text: {display: "none",},
                                                    },
                                                }}
                                            />
                                        </div>
                                    </div>
                                </>
                            }
                        />
                        <ContentCard
                            cardType="background"
                            title="운행거리"
                            onClick={()=>{
                                navigate("/cru", {state: {
                                        scroll: "driveStatus"
                                    }});
                            }}
                            children={
                                <>
                                    <div className="subTitle">
                                        <span>총</span>{distance? distance : 0}<span>km</span>
                                    </div>
                                    <div className="content" style={{
                                        width:"130px",
                                        height: "130px",
                                        textAlign: "right"
                                    }}>
                                        <img style={{
                                            height: "100%"
                                        }} src="/assets/icons/Subtract.svg" alt="marker.svg" />
                                    </div>
                                </>
                            }
                        />
                        <ContentCard
                            cardType="fill"
                            title="운행현황 조회"
                            children={
                                <div className="fill-content">
                                    <div>
                                        <img style={{}} src="/assets/images/car_group.svg" alt="car" />
                                    </div>
                                    <button className="card-button" onClick={()=>{
                                        navigate("/car");
                                    }}>
                                        <img style={{}} src="/assets/icons/icon_search_white.svg" alt="car" />조회하기
                                    </button>
                                </div>
                            }
                        />
                        <ContentCard
                            cardType="graph"
                            title="안전운전 점수 분포"
                            onClick={()=>{
                                navigate("/cru")
                            }}
                            children={
                                <div className="graph-content">
                                    {!isShowDrive &&
                                        <ResponsivePie
                                            /**
                                             * 참고 document
                                             * https://nivo.rocks/pie/
                                             */
                                            data={[
                                                { id: '데이터 없음', value: 1 },
                                            ]}
                                            innerRadius={0.5}
                                            animate={true}
                                            enableArcLinkLabels={false}
                                            margin={{ top: 0, right: 20, bottom: 20, left: 20 }}
                                            isInteractive={false}
                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    justify: false,
                                                    // translateX: 5,
                                                    translateY: 15,
                                                    // itemsSpacing: 0,
                                                    itemWidth: 60,
                                                    itemHeight: 18,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            colors={["#A8AAAB"]} // 커스터하여 사용할 때
                                            theme={{
                                                labels: {
                                                    text: {display: "none",},
                                                },
                                            }}
                                        />
                                    }
                                    {isShowDrive &&
                                        <ResponsivePie
                                            /**
                                             * 참고 document
                                             * https://nivo.rocks/pie/
                                             */
                                            innerRadius={0.5}
                                            data={[
                                                { id: '우수', value: driveScoreList[0] },
                                                { id: '양호', value: driveScoreList[1] },
                                                { id: '주의', value: driveScoreList[2] },
                                            ]}
                                            animate={true}
                                            enableArcLinkLabels={false}
                                            margin={{ top: 0, right: 20, bottom: 20, left: 20 }}
                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: 5,
                                                    translateY: 15,
                                                    // itemsSpacing: 0,
                                                    itemWidth: 60,
                                                    itemHeight: 18,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            colors={['#A4D9FF', "#FFCA8B", "#FCADAD"]} // 커스터하여 사용할 때
                                            theme={{
                                                labels: {
                                                    text: {display: "none",},
                                                },
                                            }}
                                        />
                                    }
                                </div>
                            }
                        />
                        <ContentCard
                            cardType="graph"
                            title="공기관리 점수 분포"
                            onClick={()=>{
                                navigate("/car")
                            }}
                            children={
                                <div className="graph-content">
                                    {!isShowDust &&
                                        <ResponsivePie
                                            /**
                                             * 참고 document
                                             * https://nivo.rocks/pie/
                                             */
                                            data={[
                                                { id: '데이터 없음', value: 1 },
                                            ]}
                                            innerRadius={0.5}
                                            animate={true}
                                            enableArcLinkLabels={false}
                                            margin={{ top: 0, right: 20, bottom: 20, left: 20 }}
                                            isInteractive={false}
                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    justify: false,
                                                    // translateX: 5,
                                                    translateY: 15,
                                                    // itemsSpacing: 0,
                                                    itemWidth: 60,
                                                    itemHeight: 18,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            colors={["#A8AAAB"]} // 커스터하여 사용할 때
                                            theme={{
                                                labels: {
                                                    text: {display: "none",},
                                                },
                                            }}
                                        />
                                    }
                                    {isShowDust &&
                                        <ResponsivePie
                                            /**
                                             * 참고 document
                                             * https://nivo.rocks/pie/
                                             */
                                            innerRadius={0.5}
                                            data={[
                                                { id: '우수', value: dustScoreList[0] },
                                                { id: '양호', value: dustScoreList[1] },
                                                { id: '주의', value: dustScoreList[2] },
                                            ]}
                                            animate={true}
                                            enableArcLinkLabels={false}
                                            margin={{ top: 0, right: 20, bottom: 20, left: 20 }}
                                            legends={[
                                                {
                                                    anchor: 'bottom',
                                                    direction: 'row',
                                                    justify: false,
                                                    translateX: 5,
                                                    translateY: 15,
                                                    // itemsSpacing: 0,
                                                    itemWidth: 60,
                                                    itemHeight: 18,
                                                    symbolShape: 'circle',
                                                    effects: [
                                                        {
                                                            on: 'hover',
                                                            style: {
                                                                itemTextColor: '#000'
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                            colors={['#8CEB9B', "#FFE99C", "#A8AAAB"]} // 커스터하여 사용할 때
                                            theme={{
                                                labels: {
                                                    text: {display: "none",},
                                                },
                                            }}
                                        />
                                    }

                                </div>
                            }
                        />
                        <ContentCard
                            cardType="wide"
                            title="차량관리 현황"
                            children={
                                <div className="wide-content">
                                    {/*    <img style={{}} src="/assets/images/car_group.svg" alt="car" />*/}
                                    <div>
                                        <div className="circle">
                                            <img style={{}} src="/assets/icons/icon_car_Breakdown.svg" alt="car" />
                                        </div>
                                        <div className="textBox">
                                            <h3>정비 진단</h3>
                                            <p><span>{dtcCount}</span>대</p>
                                        </div>
                                    </div>
                                    <div>
                                        <div className="box">
                                            <img style={{transform: "translateY(3px)"}} src="/assets/images/limit.svg" alt="car" />
                                            <div>
                                                <p>준비중<span>(L)</span></p>
                                                <h4>0km</h4>
                                            </div>
                                        </div>
                                        <div className="box">
                                            <img style={{}} src="/assets/images/elec.svg" alt="car" />
                                            <div>
                                                <p>준비중<span>(kwh)</span></p>
                                                <h4>0km</h4>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        />
                    </div>
                </div>
                }
            />
        </>
    )
}

export default Main;