import "./Header.scss";
import React, { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useRecoilState } from "recoil";
import { userState } from "../../stores/UserStore";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import "moment/locale/ko";
import Alert from "../Alert";
import Cookies from "js-cookie";

const Header = () => {
    const [userInfo, setUserInfo] = useRecoilState(userState);
    const [alertShow, setAlertShow] = useState(false);
    const [alertMsg, setAlertMsg] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const url = location.pathname.split("/")[1];
    const [frnGrade, setFrnGrade] = useState(0);
    const menuRefs = {
        menu1: useRef(null),
        menu2: useRef(null),
        menu3: useRef(null),
        menu4: useRef(null),
        menu5: useRef(null),
        menu6: useRef(null),
        // menu7: useRef(null),
        menu8: useRef(null),
    };

    const handleClick = (menuRef, url) => {
        console.log(userInfo)

        Object.values(menuRefs).forEach((ref) => {
            if (ref.current) {
                // ref가 null이 아닌 경우에만 클래스 추가
                ref.current.classList.remove("active");
            }
        });
        menuRef.current.classList.add("active");

        if (location.pathname !== url) {
            navigate(url);
        }
    };

    useLayoutEffect(()=>{
        setFrnGrade(parseInt(userInfo.frnGradeCd.slice(1)));
        tokenTimeCheck();
        console.log(userInfo)
        console.log(moment.unix(userInfo.exp).format('YYYY-MM-DD HH:mm:ss'))
    }, []);

    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        tokenTimeCheck();
    }, [location.pathname]);

    const tokenTimeCheck = ()=>{
        console.log(Cookies.get("access-token"));

        let tokenExp = moment.unix(userInfo.exp);
        if(tokenExp.isBefore(moment.now())){
            setAlertMsg("토큰이 만료되었습니다.");
            setAlertShow(true);
            // setTimeout(()=>{
            //     localStorage.removeItem("keepLoginToken");
            //     setUserInfo(null);
            // }, 5000);
        }
        if(!Cookies.get("access-token")){
            //userInfo 정보는 있는데 토큰이 쿠키에서 없어진 경우
            setAlertMsg("토큰 정보가 없습니다. 다시 로그인하세요.")
            setAlertShow(true);
            // setTimeout(()=>{
            //     localStorage.removeItem("keepLoginToken");
            //     setUserInfo(null);
            // }, 5000);
        }
    }
    const onClose = ()=>{
        localStorage.removeItem("keepLoginToken");
        setUserInfo(null);
        navigate("/login");
    }
    return (
        <div className="header-wrap">
            <div className="header">
                <div className="floor1">
                    <div className="logo-wrap">
                        <img onClick={()=>{
                            navigate("/main");
                        }} src="/assets/images/Logo_ifense.svg" alt="ifense 로고" />
                        <div className="info-wrap">
                            <span>안녕하세요. <span>{userInfo.userNm}</span>님</span>
                            {/*<span>userId님 환영합니다.</span>*/}
                            <button
                                onClick={() => {
                                    localStorage.removeItem("keepLoginToken");
                                    setUserInfo(null);
                                    navigate("/login");
                                }}
                            >
                                <img src="/assets/icons/ic_logout.svg" alt="logout" />
                                Log out
                            </button>
                        </div>
                    </div>
                </div>
                <div className="floor2">
                    <ul className="gnb">
                        <li
                            ref={menuRefs.menu1}
                            className={`menu-item ${url === "main" ? "active" : ""}`}
                            onClick={() => handleClick(menuRefs.menu1, "/main")}
                        >
                            대시보드
                        </li>
                        {frnGrade <= 4 &&
                            <>
                                <li
                                    ref={menuRefs.menu5}
                                    className={`menu-item ${url === "car" ? "active" : ""}`}
                                    onClick={() => handleClick(menuRefs.menu5, "/car")}
                                >
                                    차량관리
                                </li>
                                <li
                                    ref={menuRefs.menu4}
                                    className={`menu-item ${url === "cru" ? "active" : ""}`}
                                    onClick={() => handleClick(menuRefs.menu4, "/cru")}
                                >
                                    안전관리
                                </li>
                            </>
                        }
                        {frnGrade === 1 &&
                            <>
                                <li
                                    ref={menuRefs.menu8}
                                    className={`menu-item ${url === "setting" ? "active" : ""}`}
                                    onClick={() => handleClick(menuRefs.menu8, "/setting")}
                                >
                                    설정관리
                                </li>
                                <li
                                    ref={menuRefs.menu6}
                                    className={`menu-item ${url === "device" ? "active" : ""}`}
                                    onClick={() => handleClick(menuRefs.menu6, "/device")}
                                >
                                    기기관리
                                </li>
                            </>
                        }
                        {frnGrade <= 2 &&
                            <>
                                <li
                                    ref={menuRefs.menu2}
                                    className={`menu-item ${url === "admin" ? "active" : ""}`}
                                    onClick={() => handleClick(menuRefs.menu2, "/admin")}
                                >
                                    회원관리
                                </li>
                                <li
                                    ref={menuRefs.menu3}
                                    className={`menu-item ${url === "franchise" ? "active" : ""}`}
                                    onClick={() => handleClick(menuRefs.menu3, "/franchise")}
                                >
                                    가맹점관리
                                </li>
                            </>
                        }
                        {/*<li*/}
                        {/*    ref={menuRefs.menu7}*/}
                        {/*    className={`menu-item ${url === "tax" ? "active" : ""}`}*/}
                        {/*    onClick={() => handleClick(menuRefs.menu7, "/tax")}*/}
                        {/*>*/}
                        {/*    세무서비스*/}
                        {/*</li>*/}
                    </ul>
                </div>
            </div>
            <Alert
                isShow={alertShow}
                content={<p>{alertMsg}</p>}
                onClose={onClose}
            />
        </div>
    );
};

export default Header;

