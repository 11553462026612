const translator = {
    rownum: "순번",
    cruSeq: "순번",
    carSeq: "순번",
    prFrnSeq: "소속",
    frnGradeCd: "소속 유형",
    bizNm: "가맹점명",
    cruNm: "기사명",
    carNo: "차량번호",
    cruHp: "전화번호",
    address1: "주소",
    email: "이메일",
    userId: "회원ID",
    userNm: "이름",
    userHp: "연락처",
    regDt: "등록일",
    updDt: "처리일",
    useStat: "상태",
    installDt: "장착일",
    frnGradeNm: "소속",
    bizRegNo: "사업자 등록번호",
    bizType: "업태",
    bizSvc: "종목",
    hp: "전화번호",
    manager: "담당자명",
    carNm: "차종",
    carColor: "색상",
    carYear: "연식",
    carType: "연료",
    deviceMac:"MAC주소",
    dustVer: "컨트롤러",
    obdVer: "OBD",
    carPic: "차량등록증",
    prFrnName: "소속",
    prBizNm: "소속",
    frnName: "가맹점명",
    deviceNo: "기기번호",
    appVer:"앱버전",

    date: "일시",
    aggDay: "날짜",
    distance: "운행거리",
    termTime: "운행시간",
    driveScore: "운전점수",
    dustScore: "공기관리점수",
    sdAcceleration: "급가속",
    sdStart: "급출발",
    sdBreak: "급감속",
    sdStop: "급정지",
    sdLane: "급진로변경",
    sdOvertaking: "급앞지르기",
    sdSpin: "급좌우회전",
    sdUturn: "급U턴",
    sd : "운전점수 감점항목",
    sdName: "감점유형",

    voc0: "우수",
    voc1: "좋음",
    voc2: "보통",
    voc3: "나쁨",
    dust0: "우수",
    dust1: "좋음",
    dust2: "보통",
    dust3: "나쁨",
    air: "공기관리점수 감점항목",
    dustGrade: "미세먼지 등급",
    vocGrade: "공기질 등급",

    F0003: "법인",
    F0004: "개인",
    dtcCode: "DTC코드",
    ecuCode: "ECU코드",
    ecuName: "ECU명",
    dtcName: "DTC내용",
    // aggDay: "일시",
    // dustScore: "공기질 점수",
    // termTime: "운행 시간",
    // dust0: "미세먼지 0등급",
    // dust1: "미세먼지 1등급",
    // dust2: "미세먼지 2등급",
    // dust3: "미세먼지 3등급",
    // voc0: "voc 0등급",
    // voc1: "voc 1등급",
    // voc2: "voc 2등급",
    // voc3: "voc 3등급"
    dt: "날짜",
    coolantTemp:"냉각수온도",
    inTemp:"흡기온도",
    outTemp:"배기온도",
    batteryCapa:"배터리잔량",
    batteryVolt:"배터리전압",
    batteryCharge:"충전전류량",
    batteryTemp:"배터리온도",
    tpmsPressFl:"공기압FL",
    tpmsPressFr:"공기압FR",
    tpmsPressRl:"공기압RL",
    tpmsPressRr:"공기압RR",
    tpmsTempFl:"타이어온도FL",
    tpmsTempFr:"타이어온도FR",
    tpmsTempRl:"타이어온도RL",
    tpmsTempRr:"타이어온도RR",
    speed: "속도",
    steering: "조향각",
    odo:"주행거리",
    deviceCd: "타입"
}

export default translator;
