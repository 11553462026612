import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import services, { axiosInstance } from "../services/index";
import Button from "../components/Button";
import Tip from "../components/Tip";
import {encryptoAes, encryptoSha} from "../utils/CryptoUtil";

const FindId = () => {
    // f = 정보 조회 실패 상태 / t = 정보 조회 성공 상태
    const [findStatus, setFindStatus] = useState(false);
    const [findFail, setFindFail] = useState(false);
    const [findFailMsg, setFindFailMsg] = useState("입력하신 정보로 조회된 데이터가 없습니다.");
    const navigate = useNavigate();
    const [userNm, setUserNm] = useState("");
    const [userHp, setUserHp] = useState("");
    const [formStatus, setFormStatus] = useState("active");
    const [userNmNone, setUserNmNone] = useState(false);
    const [userHpNone, setUserHpNone] = useState(false);
    const [findInfo, setFindInfo] = useState("");
    useEffect(() => {
        formStatusUpdate();
    }, [userNm, userHp]);
    useEffect(()=>{

    }, [userNmNone, userHpNone]);

    const formStatusUpdate = () => {
        if(!userNm || !userHp){setFormStatus(""); return;}

        if (userNm.length > 1 && userHp.length > 1) {
            setFormStatus("active");
        } else {
            setFormStatus("");
        }
    }

    const userNmHandler = (value) => {
        setUserNm(value);
    }
    const userHpHandler = (value) => {
        setUserHp(value);
    }

    const inputValidate = ()=>{
        let pass = true;
        if(!userNm || userNm.length < 1){
            setUserNmNone(true);
            pass = false;
        }
        if(!userHp || userHp.length < 1){
            setUserHpNone(true);
            pass = false;
        }
        return pass;
    }

    const findHandler = async (e) => {
        e.preventDefault();
        setFindStatus(false);
        setFindFail(false);
        if(!inputValidate()){
            setTimeout(()=>{
                setUserNmNone(false);
                setUserHpNone(false);
            }, 5000);
            return ;
        };

        try {
            // TODO ID찾기 API 생기면 주소 변경
            const result = await services.auth.findId({
                userNm: userNm,
                userHp: userHp
            });

            if (!result) {
                throw new Error("ID Find Fail: Result is Null");
            }

            setFindStatus(true);
            setFindInfo(result); // TODO api 반환 값 넣기
            // navigate("/main");
        } catch (err) {
            setFindFail(true);
            setFindStatus(false);
        }

    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            findHandler();
        }
    }

    return (
        <div className="login-wrap">
            <div className="logo">
                <img src="/assets/images/Logo_ifense.svg" alt="ifense 로고" onClick={()=>{
                    navigate("/login");
                }} />
            </div>
            <div className="login-infomation-wrap">
                <h2>아이디 찾기</h2>
                {!findStatus && (
                    <div>
                        <div className="info-field">
                            <span>이름</span>
                            <input
                                type="text"
                                id="userNm"
                                className="input-text input-full"
                                placeholder="이름을 입력하세요."
                                onChange={e => userNmHandler(e.target.value)}
                                onKeyPress={e => onEnter(e)}
                                style={userNmNone ? { border: "1px solid #F04E3E" } : {}}
                            />
                            {userNmNone && (
                                <div className="tipWrap">
                                    <Tip
                                        content="필수 입력 사항 입니다."
                                        size={12}
                                    />
                                </div>
                            )}
                        </div>
                        <div className="info-field">
                            <span>핸드폰 번호</span>
                            <input
                                type="text"
                                id="ph"
                                className="input-text input-full"
                                placeholder="핸드폰 번호를 입력하세요."
                                onChange={e => userHpHandler(e.target.value)}
                                onKeyPress={e => onEnter(e)}
                                style={userHpNone ? { border: "1px solid #F04E3E" } : {}}
                            />
                            {userHpNone && (
                                <div className="tipWrap">
                                    <Tip
                                        content="필수 입력 사항 입니다."
                                        size={12}
                                    />
                                </div>
                            )}
                        </div>
                        <Button
                            buttonType="login"
                            content="아이디 찾기"
                            onClick={findHandler}
                            formStatus={formStatus}
                        />
                    </div>
                )}

                {
                    findStatus &&
                    <div className="find-wrap">
                        <div className="find-text">
                            <p className="findContent">회원님의 아이디는</p>
                            <span className="find">{findInfo}</span>
                            <p className="findContent">입니다.</p>
                        </div>
                        <Button
                            buttonType="login"
                            content="로그인 하러가기"
                            formStatus="active"
                            onClick={()=>{navigate("/login");}}
                        />
                    </div>

                }
                <div className="find-helper-wrap">
                    <Link to="/login">로그인 이동</Link> | <Link to="/findPwd">비밀번호 찾기</Link>
                </div>
                {
                    findFail &&
                    <div className="login-fail-msg">
                        <Tip
                            content={findFailMsg}
                            size={15}
                        />
                    </div>
                }
            </div>
        </div>

    )
}

export default FindId;
