import './styles/fonts/pretendard.css';
import './styles/fonts/pretendardvariable.css';
import './styles/index.scss';

import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import Test1 from "./pages/Test1";
import Test2 from "./pages/Test2";
import Login from "./pages/login";
import FindId from "./pages/id";
import FindPwd from "./pages/pwd";
import Main from "./pages/main";
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import AdminList from "./pages/admin/AdminList";
import FranchiseList from "./pages/franchise/FranchiseList";
import FranchiseAdd from "./pages/franchise/FranchiseAdd";
import AdminAdd from "./pages/admin/AdminAdd";
import PrivateRoute from "./pages/role/PrivateRoute";
import CruList from "./pages/cru/CruList";
import CruAdd from "./pages/cru/CruAdd";
import CarList from "./pages/car/CarList"
import CarAdd from "./pages/car/CarAdd";
import CarChange from "./pages/car/CarChange";
import CarDetail from "./pages/car/CarDetail";
import CruDetail from "./pages/cru/CruDetail";
import DeviceList from "./pages/device/DeviceList";
import DeviceDetail from "./pages/device/DeviceDetail";
import SettingPage from "./pages/setting/SettingPage";
import ObdTestPage from "./pages/ObdTestPage";
import StatList from "./pages/car/StatList";
import RoleRoute from "./pages/role/RoleRoute";
import Error from "./pages/error/Error";
function App() {
  return (
      <RecoilRoot>
          <RecoilWrapper />
      </RecoilRoot>
  );
}

const RecoilWrapper = ()=>{
    //FIXME 권한별 페이지 접근 제한
    // PrivateRoute : 로그인 유무 접근 제한
    // RoleRoute    : 권한별 접근 제한
    return (
        <BrowserRouter>
            <Routes>
                {/*로그인 없이도 접근 가능*/}
                <Route path="/login" element={<Login />} />
                <Route path="/findId" element={<FindId />} />
                <Route path="/findPwd" element={<FindPwd />} />
                {/*로그인 해야 접근 가능*/}
                {/* RoleRoute frnGradeCd
                    1: 최고관리자(가맹본부)만 접근 가능
                    2: 최고관리자 + 지역가맹본부
                    3: 법인
                    4: 개인 ---> 사이트 로그인이 불가함
                */}
                <Route element={<PrivateRoute authentication={true}/>}>
                    <Route path="/main" element={<Main />}></Route>
                    <Route element={<RoleRoute frnGradeCd={2} />}>
                        <Route path="/admin" element={<AdminList />} />
                        <Route path="/admin/:id" element={<AdminAdd />} />
                    </Route>
                    <Route path="/franchise" element={<FranchiseList/>} />
                    <Route path="/franchise/:id" element={<FranchiseAdd/>} />
                    <Route path="/cru" element={<CruList />} />
                    <Route path="/cru/:id" element={<CruAdd />} />
                    <Route path="/cru/detail/:id" element={<CruDetail />} />
                    <Route path="/car" element={<CarList />} />
                    <Route path="/car/:id" element={<CarAdd />} />
                    <Route path="/car/change/:id" element={<CarChange />}/>
                    <Route path="/car/detail/:id" element={<CarDetail />} />
                    <Route path="/car/stat" element={<StatList />} />
                    <Route element={<RoleRoute frnGradeCd={1} />}>
                        <Route path="/device" element={<DeviceList />} />
                        <Route path="/device/:id" element={<DeviceDetail />} />
                        <Route path="/setting" element={<SettingPage />} />
                        <Route path="/obd/test" element={<ObdTestPage />} />
                    </Route>
                    <Route path="/error" element={<Error />} />
                </Route>
                {/* 와일드카드 경로 추가 */}
                <Route path="*" element={<Navigate to="/main" />} />
            </Routes>
        </BrowserRouter>
    )
}

export default App;
