import React, {useEffect, useState} from "react";
import Layout from "../../layout/Layout";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import TipInput from "../../components/Input/TipInput";
import SelectBox from "../../components/SelectBox/SelectBox";
import admin from "../../services/admin";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import franchise from "../../services/franchise";
import {bizRegNumHypen, noneHypen, phoneHypen, setCurrentUrl, trimObject} from "../../utils";
import Alert from "../../components/Alert";

const AdminAdd = ()=>{
    const userInfo = useRecoilValue(userState);
    const initialData = {
        regDt: "",
        updDt: "",
        userId: "",
        userPwd: "",
        prFrnSeq: userInfo?.frnSeq,
        // prFrnSeq: userInfo.frnGradeCd === "F0001" ? 2 : userInfo?.frnSeq,
        frnSeq: null,
        userNm: "",
        userHp: "",
        useYn: "Y"
    }
    const { id } = useParams();
    const [data, setData] = useState(initialData);
    const [checkedPwd, setCheckedPwd] = useState(true);
    const [prFrnSelectList, setPrSelectList] = useState([]);
    const [frnSelectList, setFrnSelectList] = useState([]);
    const navigate = useNavigate();
    const [noneValue, setNoneValue] = useState("");
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const { state } = useLocation();

    useEffect(() => {
        setCurrentUrl(window.location.pathname);
        getPrFranchise();
        setNoneValue("");
        if (id === "0") return;
        if (!state || state.prFrnSeq == null) navigate("/error");
        setData((prev) => {
            return ({
                ...prev,
                prFrnSeq: state.prFrnSeq,
                frnSeq: state.frnSeq,
                bizNm: state.bizNm,
                userId: state.userId,
                userPwd: state.userPwd,
                userNm: state.userNm,
                userHp: state.userHp
            })
        })
    }, []);

    useEffect(() => {
        getFranchise();
        setData((prev) => {
            return ({
                ...prev,
                frnSeq: null
            })
        })
    }, [data.prFrnSeq])

    /*const getPrFranchise = async () => {
        const data = [];
        const tempData = {
            page: 1,
            pageSize: 9999,
            frnGradeCd: "F0002",
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        let bool = true;
                        data.forEach((d) => {
                            if (d.value === item.frnSeq) {
                                bool = false;
                            }
                        })
                        if(bool) {
                            data.push({
                                value: item.frnSeq,
                                label: item.bizNm
                            })
                            setPrSelectList((prev) => prev.concat({
                                value: item.frnSeq,
                                label: item.bizNm
                            }))
                        }
                    })
                }
            });
        }
        catch {
            setPrSelectList([]);
        }
    }*/

    const getPrFranchise = () => {
        const searchData = ["F0001", "F0002"];

        searchData.forEach(async (item) => {
            const data = [];
            const tempData = {
                page: 1,
                pageSize: 9999,
                frnGradeCd: item,
                useYn: "Y",
                regDtStart: "",
                regDtEnd: ""
            }
            try {
                await franchise.GetFranchise(tempData).then((res) => {
                    if (res.datas && res.datas.length > 0) {
                        res.datas.forEach((item) => {
                            let bool = true;
                            data.forEach((d) => {
                                if (d.value === item.frnSeq) {
                                    bool = false;
                                }
                            })
                            if(bool) {
                                data.push({
                                    value: item.frnSeq,
                                    label: item.bizNm
                                })
                                setPrSelectList((prev) => prev.concat({
                                    value: item.frnSeq,
                                    label: item.bizNm
                                }))
                            }
                        })
                    }
                });
            }
            catch {
                setPrSelectList([]);
            }
        });
    }

    const getFranchise = async () => {
        const tempData = {
            page: 1,
            pageSize: 9999,
            prFrnSeq: data.prFrnSeq,
            useYn: "Y"
        }

        try {
            await franchise.GetFranchise(tempData).then((res) => {
                const data = [];
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        let bool = true;
                        if (item.userId !== "" && item.userId !== null && item.userId) {
                            bool = false;
                        } else {
                            if(item.frnGradeCd == "F0004") bool = false;
                            data.forEach((d) => {
                                if (d.value === item.frnSeq) {
                                    bool = false;
                                }
                            })
                        }
                        if(bool) {
                            data.push({
                                value: item.frnSeq,
                                label: item.bizNm + "(" + bizRegNumHypen(item.bizRegNo) + ")"
                            })
                        }
                    })
                }
                setFrnSelectList(data);
            });
        }
        catch {
            setFrnSelectList([]);
        }
    }

    const checkValue = {
        "prFrnSeq": "소속",
        "frnSeq": "가맹점명",
        "userId": "ID",
        "userPwd": "비밀번호",
        "checkPwd": "비밀번호 확인",
        "userNm": "이름",
        "userHp": "연락처"
    }

    const onCheck = async () => {
        if (id !== "0") {
            delete checkValue.frnSeq
        }
        let emptyValue = [];
        Object.keys(checkValue).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined) {
                emptyValue.push(checkValue[key]);
            }
        })
        if(data.checkPwd !== data.userPwd){
            setCheckedPwd(false);
            emptyValue.push(checkValue["checkPwd"]);
        }
        
        if (emptyValue.length > 0) {
            setNoneValue(emptyValue[0]);
            setAlertMessage(`${emptyValue[0]}를(을) 입력해주세요.`);
            setAlertShow("alert");
            return;
        }

        const check = await onCheckInfo();
        if (!check) {
            setNoneValue("");
            setAlertMessage("입력하신 정보와 일치하는 이름, 연락처가 존재합니다.");
            setAlertShow("alert");
            return;
        }

        let tempData = {
            ...data,
            userHp: noneHypen(data.userHp),
        }

        tempData = trimObject(tempData);

        if (id === "0") {
            onRegister(tempData);
        } else {
            onModify(tempData);
        }
    }

    const onRegister = async (tempData) => {
        delete tempData.prFrnSeq;

        try {
            await admin.AddAdminUser(tempData).then((res) => {
                if (res && res.info) {
                    setAlertMessage("등록되었습니다.");
                    setAlertShow("alertMove");
                } else {
                    setAlertMessage("등록 실패하였습니다.");
                    setAlertShow("alert");
                }
            });
        }
        catch {
            setAlertMessage("등록 실패하였습니다.");
            setAlertShow("alert");
        }
    }

    const onModify = async (tempData) => {
        try {
            await admin.ModifyAdminUser(tempData).then((res) => {
                if (res && res.info) {
                    setAlertMessage("수정되었습니다.");
                    setAlertShow("alertMove");
                }
                else {
                    setAlertMessage("수정 실패하였습니다.");
                    setAlertShow("alert");
                }
            });
        }
        catch {
            setAlertMessage("수정 실패하였습니다.");
            setAlertShow("alert");
        }
    }

    const onCheckId = async (value) => {
        if (value.length < 1) return;
        try {
            await admin.GetAdminUser({ userId : value }).then((res) => {
                if (res && res.datas.length > 0) {
                    setNoneValue("sameID");
                } else {
                    setNoneValue("");
                }
            });
        } catch {}
    }

    const onCheckInfo = async () => {
        let bool = true;
        const temp = {
            userNm: data.userNm,
            userHp: noneHypen(data.userHp),
            useYn: "Y"
        }
        try {
            await admin.GetAdminUser(trimObject(temp)).then((res) => {
                if (res && res.datas.length > 0) {
                    bool = false
                } else {
                    bool = true
                }
            });
        } catch {}

        return bool;
    }

    const alertClose = () => {
        setAlertShow("");
        if(alertShow === "alertMove") {
            navigate("/admin");
        }
    }

    const onCancel = () => {
        navigate("/admin");
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onCheck();
        }
    }

    return (
        <Layout>
            <div className="add-page-wrap">
                {id === "0" &&
                    <h2>
                        회원 등록
                    </h2>
                }
                {id !== "0" &&
                    <h2>
                        회원 수정
                    </h2>
                }
                <div className="add-input-wrap">
                    <SelectBox
                        label="소속선택"
                        id="prFrnSeq"
                        placeholder="소속을 선택하세요"
                        none={noneValue === "소속" ? true : false}
                        width={374}
                        height={38}
                        value={data.prFrnSeq}
                        onChange={(e) => setData((prev) => {
                            return ({
                                ...prev,
                                prFrnSeq: e
                            })
                        })}
                        options={prFrnSelectList}
                        readOnly={id !== "0" || userInfo?.frnGradeCd === "F0002" }
                        required
                    />
                    { id === "0" ?
                        <SelectBox
                            label="가맹점명"
                            id="frnSeq"
                            placeholder="택시 조합을 선택하세요"
                            none={noneValue === "가맹점명" ? true : false}
                            width={374}
                            height={38}
                            value={data.frnSeq}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    frnSeq: e
                                })
                            })}
                            options={frnSelectList}
                            readOnly={id !== "0"}
                            required
                        /> :
                        <TipInput
                            label="가맹점명"
                            value={data.bizNm}
                            readOnly
                            required
                        />
                    }
                    <TipInput
                        label="ID"
                        id="userId"
                        placeholder="ID를 입력하세요"
                        none={noneValue === "ID" || noneValue === "sameID" ? true : false}
                        tipContent={noneValue === "sameID" ? "중복된 ID가 존재합니다." : "필수 입력 사항 입니다."}
                        value={data.userId}
                        onChange={e => setData((prev) => {
                            onCheckId(e.target.value.toString().trim());
                            return ({
                                ...prev,
                                userId: e.target.value.toString().trim()
                            })
                        })}
                        onEnter={onEnter}
                        readOnly={id !== "0"}
                        required
                    />
                    <TipInput
                        label="비밀번호"
                        type="password"
                        id="userPwd"
                        placeholder="비밀번호를 입력하세요"
                        none={noneValue === "비밀번호" ? true : false}
                        value={data.userPwd}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                userPwd: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <TipInput
                        label="비밀번호 확인"
                        type="password"
                        id="checkPwd"
                        placeholder="비밀번호를 다시 입력하세요"
                        none={noneValue === "비밀번호 확인" ? true : false}
                        value={data.checkPwd}
                        tipContent={checkedPwd ? "필수 입력 사항입니다." : "비밀번호 확인이 일치하지 않습니다."}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                checkPwd: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <TipInput
                        label="이름"
                        id="userNm"
                        placeholder="이름을 입력하세요"
                        none={noneValue === "이름" ? true : false}
                        value={data.userNm}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                userNm: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                    <TipInput
                        label="연락처"
                        id="userHp"
                        placeholder="연락처를 입력하세요"
                        none={noneValue === "연락처" ? true : false}
                        value={phoneHypen(data.userHp)}
                        onChange={e => setData((prev) => {
                            return ({
                                ...prev,
                                userHp: e.target.value
                            })
                        })}
                        onEnter={onEnter}
                        required
                    />
                </div>
                <div className="btn-center-wrap">
                    <button className={"btn btn-gray"} onClick={onCancel}>
                        취소
                    </button>
                    <button className={"btn btn-navy"} onClick={onCheck}>
                        {id === "0" ? "등록" : "수정"}
                    </button>
                </div>
            </div>
            <Alert
                isShow={alertShow === "alert" || alertShow === "alertMove"}
                content={<p>{alertMsg}</p>}
                onClose={alertClose}
            />
        </Layout>
    )
}

export default AdminAdd;

