import React, {useEffect, useState} from "react";
import translator from "../../utils/translator";
import Layout from "../../layout/Layout";
import Input from "../../components/Input/Input";
import {Table} from "antd";
import {useNavigate} from "react-router-dom";
import franchise from "../../services/franchise";
import DateInput from "../../components/Input/DateInput";
import {
    bizRegNumHypen,
    dateSorter, getPreviousUrl, getSearchData,
    phoneHypen, removeSearchData, setCurrentUrl, setSearchData,
    stringSorter,
    trimObject,
    utcToLocaleTime,
    utcToLocalformat
} from "../../utils";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import ExcelUpload from "../../components/Confirm/ExcelUpload";
import Alert from "../../components/Alert";
import SearchSelect from "../../components/SelectBox/SearchSelect";
import common from "../../services/common";
import dayjs from "dayjs";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import "moment/locale/ko";
import AlertMessage from "../../components/Alert/AlertMessage";
import moment from "moment";
import useDidMountEffect from "../../hooks/useDidMountEffect";
const FranchiseList = ()=>{
    const userInfo = useRecoilValue(userState);
    const initialData = {
        page: 1,
        pageSize: 10,
        prFrnSeq : userInfo?.frnGradeCd === "F0001" ? null : userInfo?.frnSeq,
        bizNm: "",
        bizRegNo: "",
        bizType: "",
        bizSvc: "",
        userHp: "",
        userNm: "",
        regDtStart: "",
        regDtStartUtc: "",
        useYn: "Y"
    }
    const [data, setData] = useState(initialData);
    const [total, setTotal] = useState(0);
    const [list,setList] = useState([]);
    const [excelData, setExcel] = useState([]);
    const [selectList, setSelectList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [page, setPageNum] = useState(1);
    const [fileName, setFileName] = useState("");
    const [confirmShow, setConfirmShow] = useState(false);
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const [excelFile, setExcelFile] = useState([]);
    const navigate = useNavigate();

    const excelHeaders = ["순번", "소속", "가맹점명", "사업자등록번호", "업태", "종목", "담당자명", "연락처", "등록일"];

    useEffect(() => {
        let currentUrl = window.location.pathname;
        if(getPreviousUrl() === currentUrl) {
            let searchData = getSearchData();
            if(searchData) {
                searchData = {
                    ...searchData,
                    regDtStartUtc : searchData.regDtStartUtc ? dayjs(searchData.regDtStartUtc, "YYYY/MM/DD").add(1, 'day') : ""
                }
                setData(searchData);
                fetchData(searchData);
            } else {
                searchList(1);
            }
        } else {
            searchList(1);
        }
        removeSearchData();
        setCurrentUrl(currentUrl);
        getFrnGradeCode();
    }, [])

    useDidMountEffect(() => {
        setSelectedRowKeys([]);
        searchList(page);
    }, [page])

    const searchList = (page) => {
        const tempData = {
            ...data,
            page,
        }
        setData(trimObject(tempData));
        setPageNum(page);
        fetchData(tempData);
    }

    const fetchData = async (data) => {
        try {
            await franchise.GetFranchise(data).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    setTotal(res.totalCnt);
                    setList(res.datas.map((item) => {
                        return {
                            ...item,
                            key: item.frnSeq
                        }
                    }));
                } else {
                    setTotal(0);
                    setList([]);
                }
            });
        } catch {
            setTotal(0);
            setList([]);
        }
        setSelectedRowKeys([]);
        setExcel([]);
    }

    const getFrnGradeCode = async () => {
        try {
            await common.GetCode({cdGrp: "F"}).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    const data = [];
                    data.push({
                        value: "",
                        label: "전체"
                    })
                    res.datas.forEach((item) => {
                        data.push({
                            value: item.cdId,
                            label: item.cdVal
                        })
                    })
                    setSelectList(data);
                }
            });
        } catch {
            setSelectList([]);
        }
    }

    const onSearch = () => {
        searchList(1);
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onSearch();
        }
    }

    const onClear = () => {
        setData(initialData);
        if(page === 1) {
            fetchData(initialData);
        } else {
            setPageNum(1);
        }
    }

    const onRegister = () => {
        setSearchData(data);
        navigate("/franchise/0");
    }

    const onModify = (frn) => {
        setSearchData(data);
        navigate('/franchise/'+frn.frnSeq, { state : frn });
    }

    const onDelete = () => {
        if (selectedRowKeys.length < 1) {
            setAlertMessage("선택한 항목이 없습니다.");
            setAlertShow("alert");
            return;
        }
        setAlertMessage("삭제하시겠습니까?");
        setAlertShow("alertMessage");
    }

    const deleteFranchise = () => {
        selectedRowKeys.map(async (frnSeq) => {
            const temp = {
                frnSeq,
                prFrnSeq: data.prFrnSeq,
                useYn: "N"
            }
            try {
                await franchise.DeleteFranchise(temp).then((res) => {
                    if (res.info) {
                        setAlertMessage("삭제되었습니다.");
                        setAlertShow("alert");
                        searchList(1);
                    } else {
                        setAlertMessage("다시 시도해주세요.");
                        setAlertShow("alert");
                        searchList(1);
                    }
                });
            } catch {
                setAlertMessage("오류가 발생하였습니다.");
                setAlertShow("alert");
                searchList(1);
            }
        })
    }

    const onExcelUpload = () => {
        setFileName("");
        setConfirmShow(true);
    }

    const onRegExcel = async () => {
        setFileName("");
        setConfirmShow(false);
        const data = {
            attFile: excelFile,
            frnSeq: userInfo.frnSeq
        }
        try {
            await franchise.ExcelUpload(data).then((res)=>{
                if (res && res?.cd === 1) {
                    setAlertMessage("업로드 되었습니다.");
                    setAlertShow("alert");
                    searchList(1);
                } else {
                    setAlertMessage(res?.msg);
                    setAlertShow("alert");
                    searchList(1);
                }
            });
        } catch {
            setAlertMessage("오류가 발생하였습니다.");
            setAlertShow("alert");
            searchList(1);
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, data) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setExcel(data);
        },
    };

    const header = ["rownum", "prBizNm", "bizNm", "bizRegNo", "bizType", "bizSvc", "userNm", "userHp", "regDt"];

    const columns = header.map((key) => {
        if(key === "rownum"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b)=>Number(a[key]) - Number(b[key]),
                align: 'center',
            }
        }else if (key === "userNm") {
            return {
                title: "담당자명",
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        } else if (key === "prBizNm") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) =>  stringSorter(a, b, key),
                align: 'center',
            }
        } else if (key === "bizRegNo") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    return bizRegNumHypen(val);
                }
            }
        } else if (key === "frnSeq") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
            }
        } else if (key === "userHp") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    return phoneHypen(val);
                }
            }
        } else if (key === "regDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    return dayjs(val).format("YYYY-MM-DD");
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    });

    const onExcelDownload = async () => {
        try {
            const wb = new Excel.Workbook();
            const sheet = wb.addWorksheet(`가맹점관리`);
            const headerRow = sheet.addRow(excelHeaders);
            headerRow.height = 30.75;
            headerRow.eachCell((cell, colNum) => {
                sheet.getColumn(colNum).width = 20;
                cell.font = {
                    bold: true,
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            let temp = {
                ...data,
                pageSize: 999999,
                page: 1
            }
            let excelList;
            await franchise.GetFranchise(temp).then((res)=>{
                if(res.datas && res.datas.length > 0){
                    excelList = res.datas;
                }
            })
            const selectedData = selectedRowKeys.length === 0 ? excelList : excelData;
            const datas = [];
            selectedData.forEach((v)=>{
                const row = {};
                Object.keys(v).forEach((key)=>{
                    if(header.includes(key)){
                        if(key === "regDt") {
                            row[key] = dayjs(v[key]).format("YYYY-MM-DD");
                        } else if(key === "userHp") {
                            row[key] = phoneHypen(v[key]);
                        } else if(key === "bizRegNo") {
                            row[key] = bizRegNumHypen(v[key]);
                        } else {
                            row[key] = v[key];
                        }
                    }
                });
                datas.push(row);
            });
            datas.forEach((item) => {
                const rowDatas = header.map(key => item[key]);
                const appendRow = sheet.addRow(rowDatas);
                appendRow.eachCell((cell, colNum) => {
                    cell.alignment = {
                        horizontal: "center",
                    }
                });
            });
            const fileData = await wb.xlsx.writeBuffer();
            const blob = new Blob([fileData], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `가맹점관리`);
        } catch (error) {
        }
    }

    return (
        <Layout>
            <div className="admin-wrap">
                <div className="field-wrap">
                    <div className="search-field-wrap">
                        <h2>
                            가맹점 검색
                        </h2>
                        <div className="search-filter">
                            <SearchSelect
                                label="소속 유형"
                                id="frnGradeCd"
                                placeholder="소속 유형을 선택하세요"
                                value={data.frnGradeCd}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        frnGradeCd: e
                                    })
                                })}
                                options={selectList}
                                /*TODO: 로그인한 사람이 가맹본부가 아닐 경우 자기 소속으로 픽스*/
                                /*disabled={}*/
                            />
                            <Input
                                hasLabel
                                label="가맹점명"
                                placeholder="가맹점명을 입력하세요."
                                value={data.bizNm}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        bizNm: e.target.value
                                    })
                                })}
                                onKeyPress={onEnter}
                            />
                            <Input
                                hasLabel
                                label="사업자번호"
                                placeholder="사업자번호를 입력하세요."
                                value={data.bizRegNo}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        bizRegNo: e.target.value
                                    })
                                })}
                                onKeyPress={onEnter}
                            />
                            <Input
                                hasLabel
                                label="업태"
                                placeholder="업태를 입력하세요."
                                value={data.bizType}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        bizType: e.target.value
                                    })
                                })}
                                onKeyPress={onEnter}
                            />
                            <Input
                                hasLabel
                                label="종목"
                                placeholder="종목을 입력하세요."
                                value={data.bizSvc}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        bizSvc: e.target.value
                                    })
                                })}
                                onKeyPress={onEnter}
                            />
                            <Input
                                hasLabel
                                label="담당자"
                                placeholder="담당자를 입력하세요."
                                value={data.userNm}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        userNm: e.target.value
                                    })
                                })}
                                onKeyPress={onEnter}
                            />
                            <Input
                                hasLabel
                                label="연락처"
                                placeholder="연락처를 입력하세요."
                                value={data.userHp}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        userHp: e.target.value
                                    })
                                })}
                                onKeyPress={onEnter}
                            />
                            <DateInput
                                hasLabel
                                label="등록일"
                                placeholder="날짜를 선택하세요."
                                value={data.regDtStartUtc}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        regDtStartUtc: e ?? "",
                                        regDtStart: utcToLocalformat(e)
                                    })
                                })}
                            />
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="btn-between-wrap">
                        <div></div>
                        <div className="search-btn-center">
                            <button className={"btn btn-gray"} onClick={onClear}>
                                초기화
                            </button>
                            <button className={"btn btn-navy"} onClick={onSearch}>
                                검색하기
                            </button>
                        </div>
                        <div>
                            <button className={"btn btn-line-navy"} onClick={onRegister}>
                                + 가맹점 등록
                            </button>
                        </div>
                    </div>
                </div>
                <div className="division" />
                <div className="list-field-wrap">
                    <h2>
                        가맹점 검색 결과
                    </h2>
                    <div className="count-and-button">
                        <p className="total-count">총 <span>{total}</span>건</p>
                        <div className="list-btn-wrap">
                            <button className={"btn-small btn-line-gray"} onClick={onExcelUpload}>
                                <img src="/assets/icons/icon_upload_excel.svg"></img>
                                <span>엑셀등록</span>
                            </button>
                            <button className={"btn-small btn-line-gray"} onClick={onExcelDownload}>
                                <img src="/assets/icons/icon_download_excel.svg"></img>
                                <span>엑셀다운로드</span>
                            </button>
                            <button className={"btn-small btn-line-gray"} onClick={onDelete}>
                                삭제
                            </button>
                        </div>
                    </div>                    
                    <div className="franchise-table-wrap">
                        <Table
                            dataSource={list}
                            size={"small"}
                            columns={columns}
                            style={{ cursor: "pointer" }}
                            pagination={{
                                current: page,
                                onChange: (page) => setPageNum(page),
                                pageSize: 10,
                                total: total,
                                position: ["bottomCenter"],
                                showSizeChanger: false,
                            }}
                            onRow={(record) => {
                                return {
                                    onClick: () => {
                                        onModify(record);
                                    },
                                };
                            }}
                            rowSelection={rowSelection}
                        />
                    </div>
                </div>
            </div>
            <ExcelUpload
                isShow={confirmShow}
                title="엑셀파일 등록"
                content="엑셀파일을 등록해주세요."
                fileName={fileName}
                setFileName={(data)=>setFileName(data)}
                onClose={()=>{
                    setFileName("");
                    setConfirmShow(false);
                }}
                onAdd={onRegExcel}
                headers={excelHeaders}
                setExcelFile={setExcelFile}
                excelName="가맹점엑셀양식"
                type="frn"
            />
            <Alert
                isShow={alertShow === "alert"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
            />
            <AlertMessage
                isShow={alertShow === "alertMessage"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
                onOk={deleteFranchise}
            />
        </Layout>
    )
}

export default FranchiseList;