import {useInputs} from "../../hooks/useInputs";
import {useEffect, useRef, useState} from "react";
import useOutsideClick from "../../hooks/useOutsideClick";
import "./CustomTable.scss";

const EditableFileCell = (props) => {
    const fileRef = useRef();

    useOutsideClick(fileRef, props.onEditComplete);

    const onSelectFile = () => {
        document.getElementById(props.name).click();
    }

    const handleFileChange = (e) => {
        if (!e.target.files[0].name) return;

        props.onChange(e);
        props.onSaveFile(e.target.files[0]);
        props.onEditComplete();
    }

    return (
        <div className="editable-cell">
            <div className="att-info">
                <input
                    type="file"
                    name={props.name}
                    id={props.name}
                    onChange={handleFileChange}
                    style={{ display: 'none' }}
                />
                <button className="btn btn-line-navy" ref={fileRef} onClick={onSelectFile}>파일 선택</button>
            </div>
           {/* <input
                style={{ width: "100%" }}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                ref={inputRef}
                onKeyDown={onKeyDown}
            />*/}
        </div>
    );
};

const EditableTableCell = (props) => {
    const inputRef = useRef();
    const onKeyDown = (e) => {
        if (e.key === "Enter") {
            props.onEditComplete();
        }
    };

    useOutsideClick(inputRef, props.onEditComplete);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    return (
        <div className="editable-cell">
            <input
                style={{ width: "100%" }}
                name={props.name}
                value={props.value}
                onChange={props.onChange}
                ref={inputRef}
                onKeyDown={onKeyDown}
            />
        </div>
    );
};

const EditableCell = (props) => {
    const [editMode, setEditMode] = useState(false);
    const {
        header,
        state,
        onEditComplete,
        onChange,
    } = props;

    const onEditCompleteHandler = () => {
        onEditComplete();
        setEditMode(false);
    };

    return editMode ? (
        <div className="custom-cell custom-stretch-center"
            key={header.dataIndex}
            style={header.width === "auto" ? { flex: 1 } : { width: header.width }}
        >
            { props.header.dataIndex === "file" ?
                <EditableFileCell
                    name={header.dataIndex}
                    value={state[header.dataIndex]}
                    onChange={onChange}
                    type={header.type}
                    onEditComplete={onEditCompleteHandler}
                    onSaveFile={props.onSaveFile}
                /> :
                <EditableTableCell
                    name={header.dataIndex}
                    value={state[header.dataIndex]}
                    onChange={onChange}
                    type={header.type}
                    onEditComplete={onEditCompleteHandler}
                />}
        </div>
    ) : (
        <div className="custom-cell custom-center-between setting-table-cell"
            key={header.dataIndex}
            onClick={() => {
                if (state[header.dataIndex] === "파일없음") return;
            }}
            style={header.width === "auto" ? { flex: 1 } : { width: header.width }}
        >
            <div className="setting-edit-icon" />
            <span>{header.render ? header.render(state) : state[header.dataIndex]}</span>
            {state[header.dataIndex] !== "파일없음" ?
                <div className="setting-edit-icon" onClick={(e) => {
                    e.stopPropagation();
                    setEditMode(true);
                }}>
                    <img src="/assets/icons/icon_edit_normal.svg" alt="edit" />
                </div>
                :
                <div className="setting-edit-icon" onClick={(e) => {
                    e.stopPropagation();
                }}>
                </div>
            }
        </div>
    );
};

function CustomTableRow(props) {
    const [state, onChange, initialize] = useInputs(props.row);

    const onEditComplete = async (col) => {
        try {
            await props.onEditComplete(state, col);
        } catch (e) {
            initialize();
        }
    };

    const onSaveFile = async (data) => {
        try {
            await props.onSaveFile(data, state);
        } catch (e) {
            initialize();
        }
    };

    return (
        <div className="custom-flex custom-row">
            {props.columns.map((header) => {
                if (header.editable)
                    return (
                        <EditableCell
                            key={header.dataIndex}
                            header={header}
                            state={state}
                            onChange={onChange}
                            onEditComplete={
                                props.onEditComplete
                                    ? () => onEditComplete(header.dataIndex)
                                    : null
                            }
                            onSaveFile={props.onSaveFile
                                ? (data) => onSaveFile(data)
                                : null}
                        />
                    );
                return (
                    <div className="custom-cell custom-center-center"
                        key={header.dataIndex}
                        onClick={() => {
                            if (header.selectable) {
                                header.onSelect(props.row);
                            }
                        }}
                        selected={
                            props.selectedRow &&
                            !!props.selectedRow.find((row) => row.key === props.row.key)
                        }
                        style={header.width === "auto" ? { flex: 1 } : { width: header.width }}
                    >
                        {header.render ? header.render(props.row) : state[header.dataIndex]}
                    </div>
                );
            })}
        </div>
    );
}

function CustomTable(props) {
  /*  const [valueData, setValue] = useState([]);

    useEffect(() => {
        setValue(props.data);
    }, [props.data])*/

    return (
        <div className="custom-style">
            <div className="custom-flex">
                {props.columns.map((header) => (
                    <div className="custom-header-item" key={header.dataIndex} style={{ width: header.width }}>
                        {header.title}
                    </div>
                ))}
            </div>
            <div>
                {props.data.map((row) => (
                    <CustomTableRow
                        row={row}
                        columns={props.columns}
                        key={row.key}
                        selectedRow={props.selectedRow}
                        onEditComplete={props.onEditComplete}
                        onSaveFile={props.onSaveFile}
                    />
                ))}
            </div>
        </div>
    );
}

export default CustomTable;