import React, { useState, useEffect } from "react";
import "./Input.scss";
import Tip from "../Tip";
import { checkImg } from "../../utils";
import Alert from "../Alert";

const TipAttInput = (props) => {
    const [alertShow, setAlertShow] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [rotate, setRotate] = useState(0);
    useEffect(()=>{
        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        const image = new Image();

        image.src = imgSrc;
        image.onload = () => {
            if (rotate === 90 || rotate === 270) {
                canvas.width = image.height;
                canvas.height = image.width;
            } else {
                canvas.width = image.width;
                canvas.height = image.height;
            }

            ctx.translate(canvas.width / 2, canvas.height / 2);
            ctx.rotate((rotate * Math.PI) / 180);
            ctx.drawImage(
                image,
                -image.width / 2,
                -image.height / 2,
                image.width,
                image.height
            );

            canvas.toBlob((blob) => {
                const rotatedFile = new File([blob], "rotatedImage.png", {
                    type: "image/png",
                });
                props.onChange(rotatedFile);
                const reader = new FileReader();
                reader.readAsDataURL(rotatedFile);
                reader.onloadend = () => {
                    setImgSrc(reader.result);
                    // Reset the rotation after saving
                    setRotate(0);
                };
            });
        };
    }, [rotate]);
    const onSelectFile = () => {
        document.getElementById("fileUpload").click();
    };
    const encodeFileToBase64 = (fileBlob) => {
        const reader = new FileReader();
        reader.readAsDataURL(fileBlob);
        return new Promise((resolve) => {
            reader.onload = () => {
                setImgSrc(reader.result);
                resolve();
            };
        });
    };

    const handleFileChange = (e) => {
        if (!e.target.files[0]?.name) return;

        if (!checkImg(e.target.files[0]?.name)) {
            setAlertShow(true);
            return;
        }

        props.onChange(e.target.files[0]);
        encodeFileToBase64(e.target.files[0]);
    };

    const rotateImage = () => {
        setRotate((prevRotate) => (prevRotate + 90) % 360)
    };

    const saveRotatedImage = () => {

    };

    return (
        <div className="tip-input-wrap">
            <label htmlFor={props.id}>{props.label}</label>
            <div className="tip-att-wrap">
                {!imgSrc && props.filePath && (
                    <a href={props.filePath} target="_blank" rel="noopener noreferrer">
                        <img src={props.filePath} alt="uploaded file" />
                    </a>
                )}
                {imgSrc && (
                    <div className="image-container" style={{ position: "relative" }}>
                        <img
                            src={imgSrc}
                            alt="uploaded"
                            style={{ transform: `rotate(${rotate}deg)` }}
                        />
                        <button
                            className="rotate-button"
                            onClick={rotateImage}
                            style={{ position: "absolute", top: 0, right: 0 }}
                        >
                            <span>90° 회전</span>
                            <img
                                src={"/assets/icons/RotateRight.svg"}
                                style={{ height: "25px", width: "25px" }}
                            />
                        </button>
                    </div>
                )}
                <div className="att-info">
                    <input
                        type="file"
                        name="fileUpload"
                        id="fileUpload"
                        onChange={handleFileChange}
                        style={{ display: "none" }}
                    />
                    <button className="btn btn-line-navy" onClick={onSelectFile}>
                        파일 선택
                    </button>
                </div>
                <div className="att-text">{props.text}</div>
                {props.none && (
                    <div className="tipWrap">
                        <Tip content={props.tipContent ?? "필수 입력 사항입니다."} size={12} />
                    </div>
                )}
            </div>
            <Alert
                isShow={alertShow}
                content={<p>해당 파일은 이미지 파일이 아닙니다.</p>}
                onClose={() => {
                    setAlertShow(false);
                }}
            />
        </div>
    );
};

export default TipAttInput;