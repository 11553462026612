import { axiosInstance } from "../index";


const common = {
    GetCode: async (data) => {
        const result = await axiosInstance.post(`/cmn/get`, data);
        return result.data;
    },
    AddCode: async (data) => {
        const result = await axiosInstance.post(`/cmn/add`, data);
        return result.data;
    },
    ModifyCode: async (data) => {
        const result = await axiosInstance.post(`/cmn/modify`, data);
        return result.data;
    },
    RemoveCode: async (data) => {
        const result = await axiosInstance.post(`/cmn/remove`, data);
        return result.data;
    },
    GetAppConfig: async (data) => {
        const result = await axiosInstance.post(`/cmn/appconfig/get`, data);
        return result.data;
    },
    ModifyAppConfig: async (files, params) => {
        const result = await axiosInstance.post(`/cmn/appconfig/modify`, files, { params });
        return result.data;
    },
}

export default common;