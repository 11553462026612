import React from "react";
import "./SelectBox.scss";
import Tip from "../Tip";
import {ConfigProvider, Select} from "antd";

const SelectBox = (props)=>{
    let value = "";
    if (props.readOnly && props.options.length > 0) {
        props.options.map((item) => {
            if (item.value === props.value) {
                value = item.label;
            }
        })
    }

    return (
        <div className="tip-select-wrap">
            <label htmlFor={props.id}>{props.label}<p className="required-red">{props.required && "*"}</p></label>
            {!props.readOnly &&
                <div>
                    <ConfigProvider
                        theme={{
                            token: {
                                borderRadius: 8,
                                colorBorder: "#D3D4D5",
                                colorBgContainer: '#ffffff',
                                fontSize: 14,
                                transition: 'all 0.5s',
                                lineHeight: 1,
                                colorTextPlaceholder: '#7C7F81',
                                colorPrimary: '#7688A8',
                                colorPrimaryHover: '#D3D4D5',
                            },
                            components: {
                                Select: {
                                    optionFontSize: 14,
                                    optionHeight: 32,
                                    optionLineHeight: 1.5
                                }
                            }
                        }}
                    >
                        <Select
                            showSearch
                            style={{
                                width: props.width ? props.width : 178,
                                height: props.height ? props.height : 48,
                                fontSize: 14,
                                border: props.none ? "1px solid #F04E3E" : "none",
                                borderRadius: 9
                            }}
                            suffixIcon={<img src="/assets/icons/icon_shevron_down.svg" alt="down" />}
                            onChange={props.onChange}
                            placeholder={props.placeholder}
                            value={props.value ?? null}
                            optionFilterProp="children"
                            filterOption={(input, option) => (option?.label ?? '').includes(input)}
                            filterSort={(optionA, optionB) => {
                                if (optionA?.value === "") {
                                    return -1
                                } else if (optionB?.value === "") {
                                    return 1
                                } else {
                                    if (optionA?.value === 1) {
                                        return -1
                                    } else if (optionB?.value === 1) {
                                        return 1
                                    } else {
                                        return (optionA?.value ?? '').toString().toLowerCase().localeCompare((optionB?.value ?? '').toString().toLowerCase());
                                    }
                                }
                            }}
                            options={props.options ?? []}
                        />
                    </ConfigProvider>
                    {props.none &&
                        <div className="tipWrap">
                            <Tip
                                content={props.tipContent ?? "필수 입력 사항입니다."}
                                size={12}
                            />
                        </div>
                    }
                </div>
            }
            {props.readOnly &&
                <div className="tip-input-text">{value}</div>
            }
        </div>
    )
}

export default SelectBox;