import Layout from "../../layout/Layout";
import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import ImageAlert from "../../components/Alert/ImageAlert";
import SearchCard3 from "../../components/SearchCard/SearchCard3";
import { saveAs } from "file-saver";
import {Table, ConfigProvider} from "antd";
import translator from "../../utils/translator";
import {dateSorter, phoneHypen, setCurrentUrl, stringSorter} from "../../utils";
import * as Excel from "exceljs";
import cru from "../../services/cru";
import device from "../../services/device";
import car from "../../services/car";
import main from "../../services/main";
import dayjs from "dayjs";
import SelectBox from "../../components/SelectBox/SelectBox";
import AlertMessage from "../../components/Alert/AlertMessage";
import Alert from "../../components/Alert";
import 'dayjs/locale/ko';
import useDidMountEffect from "../../hooks/useDidMountEffect";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";

dayjs.locale('ko');
const CarDetail = ()=>{
    const userInfo = useRecoilValue(userState);
    const yesterday = dayjs().subtract(1, "day");
    const initialData = {
        page: 1,
        pageSize: 10,
        frnGradeCd: "",
        bizNm: "",
        carNo: "",
        cruNm: "",
        cruHp: "",
        address: "",
        address1: "",
        address2: "",
        email: "",
        carNmCd: "",
        carTypeCd: "",
        regDtStart: dayjs(yesterday).format("YYYY-MM-DD 00:00:00"),
        regDtEnd: dayjs(yesterday).format("YYYY-MM-DD 23:59:59")
        // regDtStartUtc: yesterDay.split("-").length == 2 ? dayjs()
    }
    const initDevice = {
        deviceMac: "",
        installDt: ""
    }
    const navigate = useNavigate();
    const [page, setPageNum] = useState(1);
    const [carPicData, setCarPicData] = useState();
    const [imgSrc, setImgSrc] = useState("");
    const [driveList,setDriveList] = useState([]);
    const [dtcList, setDtcList] = useState([]);
    const [tab, setTab] = useState("drive");
    const [cruSelect, setCruSelect] = useState(null);
    const [showAlert, setShowAlert] = useState(false);
    const [state, setState] = useState();
    const [selectDateType, setDateType] = useState('daily');
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const [deleteCruSeq, setDeleteCruSeq] = useState();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [excelData, setExcel] = useState([]);
    const [cruList, setCruList] = useState([]);
    const [odo, setOdo] = useState();
    const [showAddCru, setShowAddCru] = useState(false);
    const [deviceInfo, setDeviceInfo] = useState(initDevice);
    const [addCruList, setAddCruList] = useState([]);
    const [carInfo, setCarInfo] = useState();
    const [data, setData] = useState(initialData);
    const [distance, setDistance] = useState(0);
    const [driveTime, setDriveTime] = useState(0);
    const [airScore, setAirScore] = useState(0);
    const [dtcCount, setDtcCount] = useState(0);
    const [driveTotal, setDriveTotal] = useState(0);
    const [dtcTotal, setDtcTotal] = useState(0);

    const driveExcelHeaders = ["날짜", "냉각수온도", "흡기온도", "배기온도", "배터리잔량", "배터리전압", "충전전류량", "배터리온도", "공기압FL", "공기압FR", "공기압RL", "공기압RR", "타이어온도FL", "타이어온도FR", "타이어온도RL", "타이어온도RR"];
    const dtcExcelHeaders = ["날짜", "ECU코드", "ECU명", "DTC코드", "DTC내용"];
    const location = useLocation();

    useEffect(()=>{
        setCurrentUrl(window.location.pathname);
        if(!location.state || !location.state.carSeq || location.state.carSeq == ""){
            navigate("/error");
        }else{
            setState(location.state);
            getCarInfo(location.state);
            getCruList(location.state);
            getOdoInfo(location.state);
            getDeviceInfo(location.state);
            onSearch();
            //TODO 1. 운행기록 목록 조회 넣기
        }
    }, []);

    useEffect(() => {
        if(selectDateType === "daily"){
            setData((prev) => {
                return ({
                    ...prev,
                    regDtStart: dayjs(yesterday).format("YYYY-MM-DD 00:00:00"),
                    regDtEnd: dayjs(yesterday).format("YYYY-MM-DD 23:59:59")
                })
            })
        }else if(selectDateType === "monthly"){
            let lastDay = new Date(yesterday.format("YYYY"), yesterday.format("MM"), 0);
            setData((prev) => {
                return ({
                    ...prev,
                    regDtStart: dayjs(yesterday).format("YYYY-MM-01 00:00:00"),
                    regDtEnd: dayjs(lastDay).format("YYYY-MM-DD 23:59:59")
                })
            })
        }
    }, [selectDateType])

    useDidMountEffect(()=>{
        setSelectedRowKeys([]);
        fetchList(page);
    }, [page]);

    const getCarInfo  = async (data)=>{
        const temp = {carSeq: data.carSeq, useYn: ""}
        await car.GetCar(temp).then((res)=>{
            if(res.datas && res.datas.length > 0){
                setCarInfo(res.datas[0]);
            }else{
                //fail
            }
        })
    }
    const getOdoInfo = async (data)=>{
        const temp = {
            carSeq: data?.carSeq,
            frnSeq: data?.frnSeq,
            startAggDay: dayjs().subtract(1, "month").format("YYYY-MM-DD HH:mm:ss"),
            endAggDay: dayjs().format("YYYY-MM-DD HH:mm:ss")
        }
        await car.GetEventOdo(temp).then((res)=>{
            if(res.datas && res.datas.length > 0){
                setOdo(res.datas[res.datas.length - 1]);
            }else{
                setOdo();
            }
        });

    }
    const getDeviceInfo = async (data)=>{
        const temp = {carSeq: data.carSeq, useYn: ""}
        await device.GetDevice(temp).then((res)=>{
            if(res.datas && res.datas.length >0){
                setDeviceInfo(res.datas[0]);
            }else{
                //fail
            }
        })
    }
    const onSearch = async ()=>{
        let temp;
        if (location.state?.aggDay) {
            let startAggDay = dayjs(location.state?.aggDay).format("YYYY-MM-DD 00:00:00");
            let endAggDay = dayjs(location.state?.aggDay).format("YYYY-MM-DD 23:59:59");
            setData((prev) => {
                return {
                    ...prev,
                    regDtStart: startAggDay,
                    regDtEnd: endAggDay
                }
            });
            temp = {
                carSeq: location.state.carSeq,
                startAggDay,
                endAggDay
            }
        } else {
            temp = {
                carSeq: location.state.carSeq,
                startAggDay: data?.regDtStart,
                endAggDay: data?.regDtEnd
            }
        }
        await main.GetDistanceSum(temp).then((res)=>{
            if(res && res.distance > 0){
                setDistance(res.distance);
            }else{
                setDistance(0);
            }
        });
        await main.GetAirScoreSum(temp).then((res)=>{
            if(res && res.dustScore > 0 && res.termTime){
                setAirScore(Math.round(res.dustScore));
                setDriveTime(Math.floor(res.termTime / 60 / 60));
            }else{
                setAirScore(0);
                setDriveTime(0);
            }
        });
        await main.GetCarDtc(temp).then((res)=>{
            if(res && res.datas.length > 0){
                setDtcCount(res.totalCnt);
            }else{
                setDtcCount(0);
            }
        });
        fetchList(1);
    }

    const fetchList = async (pageNum) => {
        let temp;
        if (location.state?.aggDay) {
            let startAggDay = dayjs(location.state?.aggDay).format("YYYY-MM-DD 00:00:00");
            let endAggDay = dayjs(location.state?.aggDay).format("YYYY-MM-DD 23:59:59");
            setData((prev) => {
                return {
                    ...prev,
                    regDtStart: startAggDay,
                    regDtEnd: endAggDay
                }
            });
            temp = {
                carSeq: location.state.carSeq,
                startAggDay,
                endAggDay,
                page : pageNum,
                pageSize: 10,
            }
            delete location.state?.aggDay;
        } else {
            temp = {
                carSeq: location.state.carSeq,
                startAggDay: data?.regDtStart,
                endAggDay: data?.regDtEnd,
                page : pageNum,
                pageSize: 10,
            }
        }
        if (tab === "drive" || pageNum === 1) {
            await main.GetEventSafetycar(temp).then((res)=>{
                if(res && res.datas?.length > 0){
                    setDriveTotal(res.totalCnt);
                    setDriveList(res.datas.map((item, index)=>{
                        return {
                            ...item,
                            key: index
                        }
                    }));
                }else{
                    setDriveTotal(0);
                    setDriveList([]);
                }
            });
        }
        if (tab === "dtc" || pageNum === 1) {
            await main.GetCarDtc(temp).then((res)=>{
                if(res && res.datas?.length > 0){
                    setDtcTotal(res.totalCnt);
                    setDtcList(res.datas.map((item, index)=>{
                        return {
                            ...item,
                            key: index + 1
                        }
                    }));
                }else{
                    setDtcTotal(0);
                    setDtcList([]);
                }
            });
        }
    }

    const getCruList = async (data)=>{
        const temp = {carSeq: data.carSeq, useYn: ""}
        await cru.GetCru(temp).then((res)=>{
            if(res.datas && res.datas.length > 0){
                setCruList(res.datas);
            }else{
                //fail
                setCruList([]);
            }
        });
    }
    const getAddCruList = async ()=>{
        if(!state?.frnSeq) return;
        const temp = {
            frnSeq: state?.frnSeq,
            // useStat: "Y",
            useYn: "Y",
        }
        await cru.GetCru(temp).then((res)=>{
            if(res.datas && res.datas.length > 0){
                setAddCruList([]);
                res.datas.forEach((item)=>{
                    if (item.carSeq === null) {
                        setAddCruList((prev)=>prev.concat({
                            value: item.cruSeq,
                            label: item.bizNm + " / " + item.cruNm + " / " + item.cruHp
                        }))
                    }
                })
            }else{
                //fail
            }

        });
    }
    const onDeleteCru = async (cruSeq)=>{
        setAlertMessage("삭제하시겠습니까?");
        setAlertShow("alertMessage");
        setDeleteCruSeq(cruSeq);
    }
    const deleteCru = async() => {
        await cru.InitCar({cruSeq: deleteCruSeq, useYn: '', frnSeq: userInfo.frnSeq}).then((res)=>{
            if(res.info){
                getCruList(state);
                setAlertMessage("삭제되었습니다.");
                setAlertShow("alert");
            } else{
                //fail
                setAlertMessage("다시 시도해주세요.");
                setAlertShow("alert")
            }
        });
    }
    const addCru = async ()=>{
        if (cruSelect == null) return;

        const temp = {
            carSeq: state.carSeq,
            cruSeq: cruSelect,
            frnSeq: state.frnSeq,
            useYn: ""
        }
        await cru.ModifyCru(temp).then((res)=>{
            if(res.info){
                getCruList(state);
                setShowAddCru(false);
                setCruSelect(null);
            }else{
                //fail
            }
        })
    }
    const onClose = () => {
        setShowAlert(false);
    }
    const onCarPic = ()=>{
        if(!carInfo.carSeq || carInfo.carSeq == 0) return;
        setCarPicData({
            carNm: carInfo?.carNm,
            carYear: carInfo?.carYear,
            carColor: carInfo?.carColor,
            carType: carInfo?.carType
        });
        setShowAlert(true);
        setImgSrc(carInfo?.carPic);
    }
    const onExcelDownload = async () => {
        try {
            const wb = new Excel.Workbook();
            const sheet = wb.addWorksheet(`${tab === "drive"? "차량 정보목록" : "진단이력"}`);
            const headerRow = sheet.addRow(tab === "drive" ? driveExcelHeaders : dtcExcelHeaders);
            headerRow.height = 30.75;
            headerRow.eachCell((cell, colNum) => {
                sheet.getColumn(colNum).width = 20;
                cell.font = {
                    bold: true,
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });

            let selectedData = excelData;
            if(selectedRowKeys.length === 0){
                if (tab === "drive") {
                    selectedData = driveList;
                } else {
                    selectedData = dtcList;
                }
            }

            const datas = [];
            let headers = [];
            if (tab === "drive") {
                headers = ["dt", "coolantTemp", "inTemp", "outTemp", "batteryCapa", "batteryVolt", "batteryCharge", "batteryTemp", "tpmsPressFl", "tpmsPressFr", "tpmsPressRl", "tpmsPressRr", "tpmsTempFl", "tpmsTempFr", "tpmsTempRl", "tpmsTempRr", "odo"];
            } else {
                headers = ["aggDay", "ecuCode", "ecuName", "dtcCode", "dtcName"];
            }

            selectedData.forEach((v)=>{
                const row = {};

                Object.keys(v).forEach((key)=>{
                    if(headers.includes(key)){
                        if (key === "dt") {
                            row[key] = dayjs(v[key]).format("YYYY-MM-DD HH:mm:ss");
                        }
                        else {
                            row[key] = v[key];
                        }
                    }
                });

                datas.push(row);
            })

            datas.forEach((item) => {
                const rowDatas = headers.map(key => item[key]);
                const appendRow = sheet.addRow(rowDatas);
                appendRow.eachCell((cell, colNum) => {
                    cell.alignment = {
                        horizontal: "center",
                    }
                });
            });
            const fileData = await wb.xlsx.writeBuffer();
            const blob = new Blob([fileData], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, tab === "drive" ? `차량 정보목록` : `진단이력`);
        } catch (error) {
        }
    }
    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, data) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setExcel(data);
        },
        fixed: true
    };

    const dtcHeader = ["key", "aggDay", "ecuCode", "ecuName", "dtcCode", "dtcName"];
    const dtcColumns = dtcHeader.map((key) => {
        if (key === "key") {
            return {
                title: "순번",
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
            }
        } else if (key === "ecuCode") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',

            }
        } else if (key === "aggDay") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
            }
        } else if (key === "ecuName"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                render: (value) => <div>{value==="" ? "제원에 정의되어 있지 않거나 정보를 찾을 수 없습니다.":value}</div>
            }
        } else if (key === "dtcName") {
          return {
              title: translator[key],
              dataIndex: key,
              key,
              sorter: (a, b) => Number(a[key]) - Number(b[key]),
              align: 'center',
              render: (value) => <div>{value==="" ? "제원에 정의되어 있지 않거나 정보를 찾을 수 없습니다.":value}</div>
          }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                /*width: '90px',*/
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    });

    const dustHeader = ["dt", "coolantTemp", "inTemp", "outTemp", "batteryCapa", "batteryVolt", "batteryCharge", "batteryTemp", "tpmsPressFl", "tpmsPressFr", "tpmsPressRl", "tpmsPressRr", "tpmsTempFl", "tpmsTempFr", "tpmsTempRl", "tpmsTempRr"];

    const dustColumns = dustHeader.map((key) => {
        if (key === "dt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '130px',
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                render: (value) => {
                    return <div>{dayjs(value).format("YYYY-MM-DD HH:mm:ss")}</div>
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '110px',
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
            }
        }
    });
    return (
        <Layout>
            <div className="car-detail-title">
                <h2>차량 상세 보기</h2>
                <button onClick={onCarPic}>차량 등록증 확인</button>
            </div>
            <div className="car-profile-wrap">
                <div className="profile-picture">
                    <img src="/assets/images/defaultCar.png" width="100%" height="100%"/>
                </div>
                <div className="profile-detail">
                    <div>
                        <div className="profile-title" style={{ borderTopLeftRadius: '16px' }}>소속</div>
                        <div>{carInfo?.prBizNm}</div>
                    </div>
                    <div>
                        <div className="profile-title">가맹점명</div>
                        <div>{carInfo?.bizNm}</div>
                    </div>
                    <div>
                        <div className="profile-title">차량번호</div>
                        <div>{carInfo?.carNo}</div>
                    </div>
                    <div>
                        <div className="profile-title">연식</div>
                        <div>{carInfo?.carYear}</div>
                    </div>
                    <div>
                        <div className="profile-title">차종</div>
                        <div>{carInfo?.carNm}</div>
                    </div>
                    <div>
                        <div className="profile-title">연료</div>
                        <div>{carInfo?.carType}</div>
                    </div>
                    {/*<div>*/}
                    {/*    <div className="profile-title">이름</div>*/}
                    {/*    {mode === 'text' && <div className="table-text-blue" onClick={onShowModal}>{state?.cruNm}</div>}*/}
                    {/*    {mode === 'noneCruNo' &&*/}
                    {/*        <div style={{ padding: '7px 26px' }}>*/}
                    {/*            <button className={"btn-small btn-navy"} style={{ height: '28px' }} onClick={onEditMode}>*/}
                    {/*                + 기사등록*/}
                    {/*            </button>*/}
                    {/*        </div>}*/}
                    {/*    {mode === 'edit' &&*/}
                    {/*        <div className="profile-edit-cell" style={{ padding: '0 4px 0 26px', border: "2px solid #48AFF9" }}>*/}
                    {/*            <input ref={inputFocus} />*/}
                    {/*            <button className={"btn-small btn-navy"} style={{ height: '28px', margin: '4px 0' }} onClick={onRegisterCruNm}>*/}
                    {/*                + 등록*/}
                    {/*            </button>*/}
                    {/*        </div>}*/}
                    {/*</div>*/}
                    <div className="bottom-none">
                        <div className="profile-title" style={{ borderBottomLeftRadius: '16px' }}>장착기기</div>
                        {deviceInfo?.deviceMac.length > 0 &&
                            <div>{deviceInfo?.deviceMac}</div>
                        }
                        {deviceInfo?.deviceMac == "" &&
                            <div>미등록</div>
                        }
                    </div>
                    <div className="bottom-none">
                        <div className="profile-title">주행거리</div>
                        {odo &&
                            // <div>{dayjs(deviceInfo?.installDt).format("YYYY년 MM월 DD일")}</div>
                            <div>{odo.odo.toLocaleString()}km<span style={{
                                color: "gray",
                                marginLeft: "70px"
                            }}>{odo.dt}</span></div>
                        }
                        {!odo &&
                            <div>최근 한달간 주행이력이 없습니다.</div>
                        }

                    </div>
                    {/*<div>*/}
                    {/*    <div className="profile-title">마지막 주차</div>*/}
                    {/*    <div>경기 광주시 초월읍 산수로 323-32</div>*/}
                    {/*</div>*/}
                </div>
            </div>
            <div className="car-detail-content">
                <h2>차량 운전자</h2>
                <table border={1}>
                    <thead>
                        <tr>
                            <th className="th">소속</th>
                            <th className="th">가맹점명</th>
                            <th className="th">운전자</th>
                            <th className="th">전화번호</th>
                            <th className="th">등록일자</th>
                            <th className="th">삭제</th>
                        </tr>
                    </thead>
                    <tbody>
                        {cruList &&
                            cruList.map((v, i)=>(
                               <tr key={i}>
                                   <td>{v.prBizNm}</td>
                                   <td>{v.bizNm}</td>
                                   <td>{v.cruNm}</td>
                                   <td>{phoneHypen(v.cruHp)}</td>
                                   <td>{dayjs(v.regDt).format("YYYY년 MM월 DD일")}</td>
                                   <td><div className="cru-delete-btn">
                                       <button onClick={()=>{onDeleteCru(v.cruSeq)}}>삭제</button>
                                   </div></td>
                               </tr>
                            ))
                        }
                        {cruList.length < 1 &&
                            <tr><td colSpan={6}>등록된 기사가 없습니다.</td></tr>
                        }
                        {location.state.useStat != "D" &&
                            <tr className="cru-add-wrap">
                                {!showAddCru &&
                                    <td className="cru-add-btn" colSpan={6} onClick={() => {setShowAddCru(true); getAddCruList();}}>+ 기사 등록</td>
                                }
                                {showAddCru &&
                                    <td className="cru-add-input" colSpan={6}>
                                        <div>
                                            <SelectBox
                                                label="기사 선택"
                                                id="crnSeq"
                                                placeholder="기사를 선택하세요"
                                                width={374}
                                                height={38}
                                                value={cruSelect}
                                                onChange={(e)=>{
                                                    setCruSelect(e)
                                                }}
                                                options={addCruList}
                                            />
                                            <button onClick={()=>{addCru();}}>등록</button>
                                            <button onClick={()=>{setShowAddCru(false); setCruSelect(null);}}>취소</button>
                                        </div>
                                    </td>
                                }
                            </tr>
                        }
                    </tbody>
                </table>
                <SearchCard3
                    onClick={onSearch}
                    distance={distance}
                    airScore={airScore}
                    driveTime={driveTime}
                    dtcCount={dtcCount}
                    dateList={[
                        {
                            value: 'monthly',
                            label: '월간',
                        },
                        {
                            value: 'daily',
                            label: '일간',
                        },
                    ]}
                    dateValue={selectDateType}
                    // defaultValue={location.state?.aggDay}
                    onChangeDateType={(value) => {
                        setDateType(value);
                    }}
                    startValue={data.regDtStart}
                    endValue={data.regDtEnd}
                    onChangeDate={(value, dateString) => {
                        setData((prev) => {
                            if (value) {
                                let endDt = dayjs(value[1]);
                                var lastDay = new Date(endDt.format("YYYY"), endDt.format("MM"), 0);
                                return ({
                                    ...prev,
                                    regDtStartUtc: dateString[0].split("-").length == 2 ? dayjs(dateString[0]+"-01") : value[0],
                                    regDtEndUtc: dateString[1].split("-").length == 2 ? dayjs(lastDay) : value[1],
                                    regDtStart: dateString[0].split("-").length == 2 ? dateString[0]+"-01 00:00:00" : dateString[0] + " 00:00:00",
                                    regDtEnd: dateString[1].split("-").length == 2 ? dayjs(lastDay).format("YYYY-MM-DD 23:59:59") : dateString[1] + " 23:59:59"
                                })
                            } else {
                                return ({
                                    ...prev,
                                    regDtStartUtc: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01"),
                                    regDtEndUtc: dayjs(yesterday).format("YYYY-MM-DD"),
                                    regDtStart: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01") + " 00:00:00",
                                    regDtEnd: dayjs(yesterday).format("YYYY-MM-DD") + " 23:59:59"
                                })
                            }
                        })
                    }}
                />
            </div>
            <div className="cru-field-wrap" style={{ marginTop: '20px' }}>
                <div className="tap-menu-wrap">
                    <div className={tab === "drive" ? "active" : ""} onClick={() => {
                        setTab("drive");
                        setSelectedRowKeys([]);
                        setExcel([]);
                        setPageNum(1);
                    }}>차량 정보목록</div>
                    <div className={tab === "dtc" ? "active" : ""} onClick={() => {
                        setTab("dtc");
                        setSelectedRowKeys([]);
                        setExcel([]);
                        setPageNum(1);
                    }}>진단이력</div>
                </div>
                <div className="cru-wrap">
                    <p className="cru-total-count">총 <span>{tab === "drive" ? driveTotal : dtcTotal}</span>건</p>
                    <div className="cru-btn-wrap">
                        <button className={"btn-small btn-line-gray"} onClick={onExcelDownload}>
                            <img src="/assets/icons/icon_download_excel.svg"></img>
                            <span>엑셀다운로드</span>
                        </button>
                    </div>
                </div>
                <div className={`cru-table-wrap ${tab === "drive" ? "drive-table-wrap" : "dtc-table-wrap"}`}>
                    <ConfigProvider
                        theme={{
                            token: {
                                borderRadius: 0,
                            },
                            components: {
                                table: {
                                    // cellPaddingBlock: 1,
                                    // cellPaddingBlockMD: 1,
                                    // cellPaddingBlockSM: 1/
                                },
                            },
                        }}
                    >
                    {tab === "drive" &&
                        <Table
                            dataSource={driveList}
                            columns={dustColumns}
                            style={{ cursor: "pointer" }}
                            size={"small"}
                            pagination={{
                                current: page,
                                onChange: (page) => setPageNum(page),
                                pageSize: 10,
                                total: driveTotal,
                                position: ["bottomCenter"],
                                showSizeChanger: false,
                            }}
                            rowSelection={rowSelection}
                        />
                    }
                    {tab === "dtc" &&
                        <Table
                            dataSource={dtcList}
                            columns={dtcColumns}
                            style={{ cursor: "pointer" }}
                            size={"small"}
                            pagination={{
                                current: page,
                                onChange: (page) => setPageNum(page),
                                pageSize: 10,
                                total: dtcTotal,
                                position: ["bottomCenter"],
                                showSizeChanger: false,
                            }}
                            rowSelection={rowSelection}
                        />

                    }
                    </ConfigProvider>
                </div>
            </div>
            <ImageAlert
                title="차량 등록증 확인"
                options={carPicData}
                isShow={showAlert}
                onClose={onClose}
                imgSrc={imgSrc}
                carNo={carInfo?.carNo}
            />
            <AlertMessage
                isShow={alertShow === "alertMessage"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
                onOk={deleteCru}
            />
            <Alert
                isShow={alertShow === "alert"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
            />
        </Layout>
    )
}

export default CarDetail;