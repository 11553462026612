import "./SearchCard.scss";
import FrnDrop from "../DropDown/FrnDrop";
import FrnTypeDrop from "../DropDown/FrnTypeDrop";
import FrnSearchDrop from "../DropDown/FrnSearchDrop";
import DateTypeDrop from "../DropDown/DateTypeDrop";
import Button from "../Button";
import React from "react";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import translator from "../../utils/translator";
const SearchCard = (props)=>{
    const userInfo = useRecoilValue(userState);
    return (
        <div className="search-card-wrap">
            <h2>{props.title}</h2>
            <div className="search-input-wrap">
                <FrnDrop
                    options={props.frnList}
                    value={props.frnValue}
                    onChange={props.onChangeFrn}
                    readOnly={props.readOnly}
                />
                <FrnTypeDrop
                    options={props.typeList}
                    value={["F0003", "F0004"].includes(userInfo.frnGradeCd) ? translator[userInfo.frnGradeCd] : props.typeValue}
                    onChange={props.onChangeType}
                    readOnly={["F0003", "F0004"].includes(userInfo.frnGradeCd) ? true : false}
                />
                <FrnSearchDrop
                    options={props.searchList}
                    onChange={props.onChangeSearch}
                    value={["F0003", "F0004"].includes(userInfo.frnGradeCd) ? userInfo.bizNm : props.searchValue}
                    readOnly={["F0003", "F0004"].includes(userInfo.frnGradeCd) ? true : false}
                    onKeyPress={props.onKeyPress}
                />
                <DateTypeDrop
                    options={props.dateList}
                    value={props.dateValue}
                    onChangeType={props.onChangeDateType}
                    onChange={props.onChangeDate}
                    startValue={props.startValue}
                    endValue={props.endValue}
                />
                <Button buttonType="normal" content="적용" onClick={props.onClick} />
            </div>
        </div>
    )
}

export default SearchCard;