import Layout from "../../layout/Layout";
import React, {useEffect, useState, useRef} from "react";
import SearchCard from "../../components/SearchCard";
import ContentCard from "../../components/ContentCard";
import MapSearch from "../../components/MapSearch";
import FrnDrop from "../../components/DropDown/FrnDrop";
import Input from "../../components/Input/Input";
import { saveAs } from "file-saver";
import {Table} from "antd";
import ExcelUpload from "../../components/Confirm/ExcelUpload";
import Alert from "../../components/Alert";
import {useLocation, useNavigate} from "react-router-dom";
import * as Excel from "exceljs";
import translator from "../../utils/translator";
import {
    dateSorter,
    getPreviousUrl,
    getSearchData, removePreviousUrl,
    removeSearchData,
    setCurrentUrl, setSearchData,
    stringSorter,
    trimObject
} from "../../utils";
import car from "../../services/car";
import franchise from "../../services/franchise";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import dayjs from "dayjs";
import main from "../../services/main";

import 'dayjs/locale/ko';

dayjs.locale('ko');

const CarList = ()=>{
    const userInfo = useRecoilValue(userState);
    const yesterday = dayjs().subtract(1, "day");
    const initialData = {
        page: 1,
        pageSize: 10,
        rownum: null,
        carSeq: null,
        frnGradeCd: "",
        frnSeq: userInfo.frnGradeCd === "F0003" ? userInfo?.frnSeq : null,
        // prFrnSeq : userInfo.frnGradeCd !== "F0003" ? userInfo?.frnSeq : userInfo?.prFrnSeq,
        prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? (userInfo.frnGradeCd === "F0001" ? 2 : userInfo?.frnSeq) : userInfo?.prFrnSeq,
        bizNm: "",
        cruNm: "",
        carNm: "",
        carNo: "",
        carNmCd: "",
        carColor: "",
        carTypeCd: "",
        carYear: "",
        carType: "",
        regDtStart: dayjs(yesterday).format("YYYY-MM-DD 00:00:00"),
        regDtEnd: dayjs(yesterday).format("YYYY-MM-DD 23:59:59")
    }
    const navigate = useNavigate();
    const [list, setList] = useState([]);
    const [frnLocationList, setFrnLocationList] = useState([]);
    const [selectDateType, setDateType] = useState('daily');
    const [total, setTotal] = useState(0);
    const [totalCar, setTotalCar] = useState(0);
    const [frnList, setFrnList] = useState([]);
    const [selectOption, setSelectOption] = useState("bizNm");
    const [bizList, setBizList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [alertShow, setAlertShow] = useState(false);
    const [excelFile, setExcelFile] = useState([]);
    const [data, setData] = useState(initialData);
    const [beforeData, setBefore] = useState(initialData);
    const [fileName, setFileName] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const [confirmShow, setConfirmShow] = useState(false);
    const [airScore, setAirScore] = useState(0);
    const [driveTime, setDriveTime] = useState(0);
    const [distanceSum, setDistanceSum] = useState(0);
    const [dtcCount, setDtcCount] = useState(0);
    const [excelData, setExcel] = useState([]);
    const [targetData, setTargetData] = useState();
    const [mapList, setMapList] = useState();
    const [isBack, setIsBack] = useState(false);
    const excelHeaders = ["순번", "소속", "가맹점명", "차량번호", "차량종류", "색상", "연식", "연료"];
    const optionGrade = [
        {
            value: '',
            label: '전체',
        },
        {
            value: 'F0003',
            label: '법인',
        },
        {
            value: 'F0004',
            label: '개인',
        },
    ];
    const [page, setPageNum] = useState(1);
    const { state } = useLocation();
    const carListRef = useRef();
    const carMapRef = useRef();
    const [isClickEnter, setClickEnter] = useState(false);

    useEffect(() => {
        getOptionFranchise();
        setIsBack(true);
        if(state?.scroll === "map"){
            setTimeout(() => {
                const headerHeight = 120; // 헤더 높이
                const elementPosition = carMapRef.current.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerHeight;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }, 100);
        }
        if(state?.scroll === "carList"){
            setTimeout(() => {
                const headerHeight = 120; // 헤더 높이
                const elementPosition = carListRef.current.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerHeight;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }, 100);
        }
    }, []);

    useEffect(() => {
        if (!isBack) return;
        let currentUrl = window.location.pathname;
        if(getPreviousUrl() === currentUrl) {
            let searchData = getSearchData();
            if(searchData) {
                setDateType(searchData.selectDateType);
                if (searchData.beforeData.bizNm != "") setSelectOption("bizNm");
                if (searchData.beforeData.carType != "") setSelectOption("carType");
                if (searchData.beforeData.carNo != "") setSelectOption("carNo");
                if (searchData.beforeData.carNm != "") setSelectOption("carNm");
                onSearch(searchData);
            } else {
                onSearch();
            }
        } else {
            onSearch();
        }
        removeSearchData();
        setCurrentUrl(currentUrl);
        setIsBack(false);
    }, [frnList]);

    useEffect(() => {
        getOptionBiz(data);
    }, [data.prFrnSeq])

    useEffect(() => {
        setSelectedRowKeys([]);
        onBottomSearch(page);
    }, [page])

    useEffect(() => {
        if (isClickEnter) {
            onSearch();
            setClickEnter(false);
        }
    }, [data.frnSeq])

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            setClickEnter(true);
        }
    }

    const onBottomEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onBottomSearch(1);
        }
    }

    const getOptionFranchise = () => {
        const searchData = ["F0002"];
        // const searchData = ["F0001", "F0002"];
        searchData.forEach(async (item) => {
            const tempData = {
                page: 1,
                pageSize: 9999,
                frnGradeCd: item,
                useYn: "Y",
                regDtStart: "",
                regDtEnd: ""
            }
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        setFrnList((prev) => prev.concat({
                            value: item.frnSeq,
                            label: item.bizNm
                        }))
                    })
                }
            });
        });
    }

    const onSearch = (searchData) => {
        const tempData = {
            ...searchData?.data ?? data,
            [selectOption]: beforeData[selectOption],
            page : 1,
        }
        setData(trimObject(tempData));
        setBefore(searchData?.beforeData ?? tempData);
        setPageNum(1);
        fetchData(tempData);
        if(searchData) {
            onBottomSearch(1, searchData.beforeData);
        }
    }

    const onBottomSearch = async (page, curData) => {
        const temp = {
            ...curData ?? beforeData,
            page,
            regDtEnd:"",
            regDtStart:""
        }
        setPageNum(page);
        try {
            const result = await car.GetCar(temp).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    setTotal(res.totalCnt);
                    return res.datas.map((item) => {
                        return {
                            ...item,
                            key: item.carSeq
                        }
                    });
                }else{
                    setTotal(0);
                    return [];
                }
            });
            setList(result);
        } catch {
            setTotal(0);
            setList([]);
        }
        setSelectedRowKeys([]);
    }

    const getOptionBiz = async (temp) => {
        const tempData = {
            ...temp,
            page: 1,
            pageSize: 9999,
            useYn: "Y",
            regDtStart: "",
            regDtEnd: ""
        }
        try {
            await franchise.GetFranchise(tempData).then(async (res) => {
                const data = [];
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        // data.push({
                        //     value: item.frnSeq,
                        //     label: item.bizNm
                        // })
                        if(item.frnGradeCd === "F0002" || item.frnGradeCd === "F0001"){

                        }else{
                            data.push({
                                value: item.frnSeq,
                                label: item.bizNm
                            });
                        }
                    })
                }
                setBizList(data);
            });
        } catch {

        }
    }

    const fetchData = async (data) => {
        let temp = {
            ...data,
            regDtEnd:"",
            regDtStart:""
        }
        const result = await car.GetCar(temp).then((res) => {
            if (res.datas && res.datas.length > 0) {
                setTotal(res.totalCnt);
                setTotalCar(res.totalCnt);
                return res.datas.map((item) => {
                    return {
                        ...item,
                        key: item.carSeq
                    }
                });
            }else{
                setTotal(0);
                setTotalCar(0);
                return [];
            }
        });
        setList(result);
        temp = {
            ...data,
            startAggDay: data.regDtStart,
            endAggDay: data.regDtEnd,
            bizNm: "",
            carType: "",
            carNo: "",
            carNm: ""
        }
        await main.GetDistanceSum(temp).then((res)=>{
            if(res && res.distance){
                setDistanceSum(res.distance);
            }else{
                setDistanceSum(0);
            }
        });
        await main.GetAirScoreSum(temp).then((res)=>{
            if(res && res.dustScore && res.termTime){
                setAirScore(Math.round(res.dustScore));
                setDriveTime(res.termTime);
            }else{
                setAirScore(0);
                setDriveTime(0);
            }
        });
        await car.GetHierarchy(temp).then((res)=>{
            if(res && res.datas?.length > 0){
                setMapList(res.datas);
            }else{
                setMapList([]);
            }
        });
        await main.GetCarDtc(temp).then((res)=>{
            if(res && res.datas?.length > 0){
                let count = res.datas.reduce(function(acc, current) {
                    if (acc.findIndex(({ carSeq }) => carSeq === current.carSeq) === -1) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
                setDtcCount(count.length);
            }else{
                setDtcCount(0);
            }
        });
        setSelectedRowKeys([]);
    };

    const onRegExcel = async () => {
        setFileName("");
        setConfirmShow(false);
        // 엑셀등록팝업에서 업로드 클릭 시 호출
        // 업로드 api 호출
        // 업로드 실패시 alert true
        const data = {
            attFile: excelFile,
            frnSeq: userInfo.frnSeq
        }
        await car.ExcelUpload(data).then((res)=>{
            //업로드 성공 여부로 alert 띄우기
            if (res && res?.cd === 1) {
                setAlertMessage("업로드 되었습니다.");
                setAlertShow("alert");
                onSearch();
            } else {
                setAlertMessage(res?.msg);
                setAlertShow("alert");
                onSearch();
            }
        });
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, data) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setExcel(data);
        },
        fixed: true
    };

    const header = ["rownum", "prBizNm", "bizNm", "carNo", "useStat", "carNm", "carColor", "carYear", "carType"];

    const columns = header.map((key) => {
        if (key === "rownum") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '100px',
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        }
        else if (key === "useStat") {
            return {
                title: translator[key],
                dataIndex: key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: '100px',
                render: (val) => {
                    switch (val) {
                        case "N":
                            return <p style={{color: "blue", textAlign: "center"}}>미승인</p>
                            break;
                        case "Y":
                            return "승인"
                            break;
                        case "R":
                            return <p style={{color: "red", textAlign: "center"}}>승인요청</p>
                            break;
                        case "H":
                            return <p style={{color: "blue", textAlign: "center"}}>보류</p>
                            break;
                        case "C":
                            return <p style={{color: "red", textAlign: "center"}}>변경요청</p>
                            break;
                        case "D":
                            return "사용중지"
                            break;
                        default:
                            return "미승인"
                            break;
                    }
                },
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        }
        else if (key === "prBizNm" || key === "bizNm") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '240px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        }
        else if (key === "carNo") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '180px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (value, data) => {
                    return <div className="table-text-blue" onClick={() => onDetailCar(data)}>{value}</div>
                }
            }
        } else if (key === "regDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '120px',
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '200px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        }
    });
    const onExcelDownload = async () => {
        try {
            const wb = new Excel.Workbook();
            const sheet = wb.addWorksheet(`차량관리`);
            const headerRow = sheet.addRow(excelHeaders);
            headerRow.height = 30.75;
            headerRow.eachCell((cell, colNum) => {
                sheet.getColumn(colNum).width = 20;
                cell.font = {
                    bold: true,
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            let temp = {
                ...data,
                pageSize: 999999,
                page: 1
            }
            delete temp.regDtEnd;
            delete temp.regDtStart;

            let excelList;
            await car.GetCar(temp).then((res)=>{
                if(res.datas && res.datas.length > 0){
                    excelList = res.datas;
                }
            })
            const selectedData = selectedRowKeys.length === 0 ? excelList : excelData;

            const datas = [];
            const column = ["rownum", "prBizNm", "bizNm", "carNo", "carNm", "carColor", "carYear", "carType"];
            selectedData.forEach((v)=>{
                const row = {};
                Object.keys(v).forEach((key)=>{
                    if(header.includes(key)){
                        row[key] = v[key];
                    }
                });
                datas.push(row);
            });
            datas.forEach((item) => {
                const rowDatas = column.map(key => item[key]);
                const appendRow = sheet.addRow(rowDatas);
                appendRow.eachCell((cell, colNum) => {
                    cell.alignment = {
                        horizontal: "center",
                    }
                });
            });
            const fileData = await wb.xlsx.writeBuffer();
            const blob = new Blob([fileData], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `차량관리`);
        } catch (error) {
        }
    }
    const onModify = (car) => {
        if(car.useStat === "D"){
            setAlertShow(true);
            setAlertMessage("사용 중지된 차량은 수정이 불가능합니다.");
            return;
        }
        setSearchData({data, beforeData, selectDateType});
        navigate('/car/'+car.carSeq, { state : car });
    }
    const onRegister = () => {
        setSearchData({data, beforeData, selectDateType});
        navigate("/car/0");
    }
    const onExcelUpload = () => {
        setFileName("");
        setConfirmShow(true);
    }
    const onDetailCar = (car) => {
        setSearchData({data, beforeData, selectDateType});
        navigate('/car/detail/'+car.carSeq, { state : car });
    }
    const optionList = [
        {
            value: 'bizNm',
            label: '가맹점명',
        },
        {
            value: 'carNo',
            label: '차량번호',
        },
        {
            value: 'carNm',
            label: '차량종류',
        },
        {
            value: 'carType',
            label: '연료타입'
        }
    ];

    const onSelect = (keys, info) => {
        console.log("========트리 데이터========")
        console.log(mapList)
        console.log("========================")

        if(!keys || keys.length < 1) {
            setTargetData();
            return;
        }

        let selectType = keys[0].indexOf("frnSeq") === 0 ? "frn" : "car";
        let target = keys[0].split("i")[0].slice(6);
        if(selectType === "frn"){
            getFrnLocation(target);
        }else{
            let frn = keys[0].split("f")[1].split("cruSeq")[0];
            let cru = keys[0].split("f")[1].split("cruSeq")[1];
            getCarLocation(cru, frn, target);
        }
    };
    const getFrnLocation = async (seq)=>{
        setTargetData();
        const temp = {
            startAggDay : data.regDtStart ? data.regDtStart : "",
            endAggDay : data.regDtEnd ? data.regDtEnd : "",
            frnSeq : seq
        }
        await main.GetLocation(temp).then((res)=>{
            if(res && res.datas?.length > 0){
                setFrnLocationList(res.datas);
                console.log("========운수사클릭 운수사 경로 목록(마커에 사용)========")
                console.log(res.datas)
                console.log("===============================")
            }else{
                setFrnLocationList([]);
            }
        });
    };
    const getCarLocation = async (cruSeq, frnSeq, carSeq)=>{
        let temp = {
            startAggDay : data.regDtStart ? data.regDtStart : "",
            endAggDay : data.regDtEnd ? data.regDtEnd : "",
            frnSeq : frnSeq
        }
        const result = await main.GetLocation(temp).then((res)=>{
            if(res && res.datas?.length > 0){
                setFrnLocationList(res.datas);
                console.log("========차량클릭 운수사 경로 목록(마커에 사용)========")
                console.log(res.datas)
                console.log("===============================")
                return res.datas;
            }
        });
        if(!result){
            setTargetData();
            setFrnLocationList([]);
            return ;
        }
        const target = result.find(item =>(item["carSeq"].toString() === (carSeq))&&(item["cruSeq"].toString() === (cruSeq)));
        if(!target) {
            setTargetData();
            return;
        }
        temp = {
            endAggDay : target.dt ? dayjs(target.dt).format("YYYY-MM-DD HH:mm:ss") : "",
            startAggDay: target.dt ? dayjs(target.dt).subtract(1, 'hour').format('YYYY-MM-DD HH:mm:ss') : "",
            carSeq : carSeq,
            cruSeq : cruSeq
        }
        await main.GetLocation(temp).then((res)=>{
            if(res && res.datas?.length > 0){
                setTargetData(res.datas);
                console.log("========차량클릭 차량 경로(경로 그리기에 사용)========")
                console.log(res.datas)
                console.log("============================================")
            }
        });
    };

    return (
        <Layout>
            <div className="car-list-wrap">
                <SearchCard
                    title="검색"
                    frnList={frnList}
                    frnValue={data.prFrnSeq}
                    onChangeFrn={(e) => {
                        const temp = {
                            ...data,
                            prFrnSeq: e,
                            frnSeq: null
                        }
                        setData(temp);
                    }}
                    typeList={optionGrade}
                    typeValue={data.frnGradeCd}
                    onChangeType={(e) => {
                        const temp = {
                            ...data,
                            frnGradeCd: e,
                            frnSeq: null
                        }
                        setData(temp);
                        getOptionBiz(temp);
                    }}
                    searchList={bizList}
                    searchValue={data.frnSeq}
                    onChangeSearch={(e) => setData((prev) => {
                        return ({
                            ...prev,
                            frnSeq: e
                        })
                    })}
                    onKeyPress={onEnter}
                    dateList={[
                        {
                            value: 'monthly',
                            label: '월간',
                        },
                        {
                            value: 'daily',
                            label: '일간',
                        },
                    ]}
                    dateValue={selectDateType}
                    onChangeDateType={(value) => {
                        setDateType(value);
                        setData((prev) => {
                            return ({
                                ...prev,
                                regDtStart: dayjs(yesterday).format(value === "daily" ? "YYYY-MM-DD 00:00:00" : "YYYY-MM-01 00:00:00"),
                                regDtEnd: dayjs(yesterday).format("YYYY-MM-DD 23:59:59")
                            })
                        })
                    }}
                    startValue={data.regDtStart}
                    endValue={data.regDtEnd}
                    onChangeDate={(value, dateString) => {
                        setData((prev) => {
                            if (value) {
                                let endDt = dayjs(value[1]);
                                var lastDay = new Date(endDt.format("YYYY"), endDt.format("MM"), 0);
                                return ({
                                    ...prev,
                                    regDtStartUtc: dateString[0].split("-").length === 2 ? dayjs(dateString[0]+"-01") : value[0],
                                    regDtEndUtc: dateString[1].split("-").length === 2 ? dayjs(lastDay) : value[1],
                                    regDtStart: dateString[0].split("-").length === 2 ? dateString[0]+"-01 00:00:00" : dateString[0] + " 00:00:00",
                                    regDtEnd: dateString[1].split("-").length === 2 ? dayjs(lastDay).format("YYYY-MM-DD 23:59:59") : dateString[1] + " 23:59:59"
                                })
                            } else {
                                return ({
                                    ...prev,
                                    regDtStartUtc: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01"),
                                    regDtEndUtc: dayjs(yesterday).format("YYYY-MM-DD"),
                                    regDtStart: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01") + " 00:00:00",
                                    regDtEnd: dayjs(yesterday).format("YYYY-MM-DD") + " 23:59:59"
                                })
                            }
                        })
                    }}
                    onClick={() => onSearch()}
                    readOnly={userInfo?.frnGradeCd !== "F0001"}
                />
                <ContentCard
                    cardType="mini"
                    title="등록차량"
                    theme="yellow"
                    onClick={()=>{
                        carListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }}
                    children={
                        <p><span>{totalCar}</span>대</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_car_cube_y.svg" alt='차량' />
                    }
                />
                <ContentCard
                    cardType="mini"
                    title="공기관리"
                    onClick={()=>{
                        removePreviousUrl();
                        setSearchData({data, beforeData, selectDateType});
                        navigate("/cru", {state: {
                                scroll: "driveGraph",
                            }});
                    }}
                    theme="green"
                    children={
                        <p>평균<span>{airScore > 0 ? airScore : 0}</span>점</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_air_cube_g.svg" alt='공기관리' />
                    }
                />
                <ContentCard
                    cardType="mini"
                    title="정비진단"
                    theme="red"
                    children={
                        <p><span>{dtcCount > 0 ? dtcCount : 0}</span>대</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_drive_cube_r.svg" alt='정비진단' />
                    }
                />
                <ContentCard
                    cardType="mini"
                    title="운행시간"
                    theme="blue"
                    onClick={()=>{
                        removePreviousUrl();
                        setSearchData({data, beforeData, selectDateType});
                        navigate("/cru", {state: {
                                scroll: "driveStatus",
                                order: "odrDriveFrn",
                                orderValue: 1
                            }});
                    }}
                    children={
                        <p><span>{driveTime > 0 ? Math.round(driveTime / 60 / 60).toLocaleString() : 0}</span>시간</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_drive_cube_b.svg" alt='운행시간' />
                    }
                />
                <ContentCard
                    cardType="mini"
                    title="운행거리"
                    theme="purple"
                    onClick={()=>{
                        removePreviousUrl();
                        setSearchData({data, beforeData, selectDateType});
                        navigate("/cru", {state: {
                                scroll: "driveStatus",
                                order: "oderDriveFrn",
                                orderValue: 0
                            }});
                    }}
                    children={
                        <p><span>{distanceSum > 0 ? distanceSum.toLocaleString() : 0}</span>km</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_air_cube_p.svg" alt='운행거리' />
                    }
                />
                <MapSearch
                    useRef={carMapRef}
                    list={mapList}
                    onSelect={onSelect}
                    locationList={frnLocationList}
                    targetData={targetData}
                />
            </div>
            <div className="cru-field-wrap" ref={carListRef}>
                <h2>
                    차량 목록
                </h2>
                <div className="cru-wrap">
                    <div className="cru-search-wrap">
                        <FrnDrop
                            options={optionList}
                            value={selectOption}
                            onChange={(e) => {
                                setSelectOption(e);
                                setBefore((prev) => {
                                    return ({
                                        ...prev,
                                        bizNm: "",
                                        carType: "",
                                        carNo: "",
                                        carNm: ""
                                    })
                                })
                            }}
                            width={200}
                            height={36}
                        />
                        <Input
                            placeholder="검색어를 입력하세요."
                            style={{ height: '18px' }}
                            value={beforeData[selectOption]}
                            onChange={(e) => setBefore((prev) => {
                                return ({
                                    ...prev,
                                    [selectOption]: e.target.value
                                })
                            })}
                            onKeyPress={onBottomEnter}
                        />
                        <button className={"btn-small btn-line-navy"} style={{ width: '110px' }} onClick={() => onBottomSearch(1)}>
                            검색
                        </button>
                    </div>
                    <div className="cru-btn-wrap">
                        <button className={"btn-small btn-line-gray"} onClick={()=>{
                            removePreviousUrl();
                            setSearchData({data, beforeData, selectDateType});
                            navigate("/car/stat");
                        }}>
                            승인관리
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onRegister}>
                            차량등록
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onExcelUpload}>
                            <img src="/assets/icons/icon_upload_excel.svg" alt='엑셀등록'></img>
                            <span>엑셀등록</span>
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onExcelDownload}>
                            <img src="/assets/icons/icon_download_excel.svg" alt='엑셀다운로드'></img>
                            <span>엑셀다운로드</span>
                        </button>
                    </div>
                </div>
                <div className="car-table-wrap">
                    <Table
                        dataSource={list}
                        columns={columns}
                        style={{ cursor: "pointer" }}
                        size={"small"}
                        pagination={{
                            current: page,
                            onChange: (page) => setPageNum(page),
                            pageSize: 10,
                            total: total,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                        }}
                        rowSelection={rowSelection}
                    />
                </div>
            </div>
            <ExcelUpload
                isShow={confirmShow}
                title="엑셀파일 등록"
                content="엑셀파일을 등록해주세요."
                fileName={fileName}
                setFileName={(data)=>setFileName(data)}
                onClose={()=>{
                    setFileName("");
                    setConfirmShow(false);
                }}
                onAdd={onRegExcel}
                headers={excelHeaders}
                setExcelFile={setExcelFile}
                excelName="차량엑셀양식"
                type="car"
            />
            <Alert
                isShow={alertShow}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow(false);
                }}
            />
        </Layout>
    )
}

export default CarList;