import "./Radio.scss";
import {Radio} from "antd";

const BottomWideRadio = (props)=>{

    return (
        <div className="radio-bottom-wide-wrap">
            <span>{props.label}</span>
            {props.options &&
                <Radio.Group onChange={props.onChange} value={props.value ?? props.defaultValue}>
                    {props.options.map((v) => <Radio value={v.key}>{v.label}</Radio>)}
                </Radio.Group>
                }
        </div>
    )

}

export default BottomWideRadio;