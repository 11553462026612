import { axiosInstance } from "./index";


const test = {
    GetObdList: async () => {
        const result = await axiosInstance.post("/test/obd");
        return result.data;
    },
    SetCar: async (data)=>{
        const result = await axiosInstance.post("/test/change", data);
        return result.data;
    }
}

export default test;

