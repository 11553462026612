import React from "react";
import "./Input.scss";
import Tip from "../Tip";

const TipInput = (props)=>{
    return (
        <div className="tip-input-wrap">
            <label htmlFor={props.id}>{props.label}<p className="required-red">{props.required && "*"}</p></label>
            {!props.readOnly &&
                <div>
                    <input
                        type={props.type ?? "text"}
                        id={props.id}
                        className={props.className ?? "input-text input-full"}
                        placeholder={props.placeholder}
                        value={props.value ?? ""}
                        onChange={props.onChange}
                        onKeyPress={props.onEnter}
                        onKeyUp={props.onKeyUp}
                        readOnly={props.readOnly ?? false}
                        style={props.none ? { border: "1px solid #F04E3E" } : {}}
                    />
                    {props.none &&
                        <div className="tipWrap">
                            <Tip
                                content={props.tipContent ?? "필수 입력 사항입니다."}
                                size={12}
                            />
                        </div>
                    }
                </div>
            }
            {props.readOnly &&
                <div className="tip-input-text">{props.value}</div>
            }
        </div>
    )
}

export default TipInput;