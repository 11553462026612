import React, {useEffect, useState, useRef} from "react";
import Layout from "../../layout/Layout";
import {Table} from "antd";
import Input from "../../components/Input/Input";
import FrnDrop from "../../components/DropDown/FrnDrop";
import cru from "../../services/cru";
import translator from "../../utils/translator";
import * as Excel from "exceljs";
import { saveAs } from "file-saver";
import ExcelUpload from "../../components/Confirm/ExcelUpload";
import Alert from "../../components/Alert";
import SearchCard from "../../components/SearchCard";
import ContentCard from "../../components/ContentCard";
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend } from "chart.js";
import {
    dateSorter,
    getGraphData,
    getGraphLabel, getPreviousUrl, getSearchData,
    phoneHypen, removeSearchData, setCurrentUrl, setSearchData,
    stringSorter,
    trimObject
} from "../../utils";
import {useLocation, useNavigate} from "react-router-dom";
import dayjs from "dayjs";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import franchise from "../../services/franchise";
import AlertMessage from "../../components/Alert/AlertMessage";
import useDidMountEffect from "../../hooks/useDidMountEffect";
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const header = ["rownum", "prBizNm", "bizNm", "cruNm", "carNo","cruHp", "appVer", "address1", "email", "regDt"];
const excelHeaders = ["순번", "소속", "가맹점명", "이름", "차량번호", "전화번호", "앱버전", "주소", "이메일", "등록일"];
const scoreColor = ['#39ADFF90', '#74c5ff90', '#9cd6ff90', '#c3e6ff90', '#d7eeff90'];
const airColor = ['#42d55a90', '#7ae18b90', '#a0eaac90', '#c6f2cd90', '#d9f6de90'];

const optionList = [
    {
        value: 'cruNm',
        label: '이름',
    },
    {
        value: 'carNo',
        label: '차량번호',
    },
    {
        value: 'cruHp',
        label: '전화번호',
    },
];

const CruList = ()=>{
    const today = new Date();
    const yesterday = new Date(today.setDate(today.getDate() - 1));
    const userInfo = useRecoilValue(userState);
    const initialData = {
        page: 1,
        pageSize: 10,
        cruSeq: null,
        carSeq: null,
        frnSeq: userInfo?.frnGradeCd === "F0003" ? userInfo?.frnSeq : null,
        prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? (userInfo.frnGradeCd === "F0001" ? 2 : userInfo?.frnSeq) : userInfo?.prFrnSeq,
        // prFrnSeq: userInfo?.frnGradeCd !== "F0003" ? userInfo?.frnSeq : userInfo?.prFrnSeq,
        frnGradeCd: "",
        bizNm: "",
        carNo: "",
        cruNm: "",
        cruHp: "",
        address: "",
        address1: "",
        address2: "",
        email: "",
        useYn: "Y",
        carNmCd: "",
        carTypeCd: "",
    }
    const initialSecond = {
        frnSeq: null,
        prFrnSeq: null,
        cruSeq: null,
        carSeq: null,
        startAggDay: dayjs(yesterday).format("YYYY-MM-DD"),
        endAggDay: dayjs(yesterday).format("YYYY-MM-DD"),
        odrDrivePoint: 0,
        odrDriveFrn: 0,
    }
    const initialDash = {
        driveScore: "0",
        dustScore: "0"
    }
    const navigate = useNavigate();
    const [data, setData] = useState(initialData);
    const [beforeData, setBefore] = useState(initialData);
    const [secondData, setSecond] = useState(initialSecond);
    const [dashData, setDash] = useState(initialDash);
    const [dangerCru, setDangerCru] = useState([]);
    const [dangerCnt, setDangerCnt] = useState(0);
    const [total, setTotal] = useState(0);
    const [listTotal, setListTotal] = useState(0);
    const [list, setList] = useState([]);
    const [scoreList, setScoreList] = useState([]);
    const [driveStatList, setDriveStatList] = useState([]);
    const [selectDateType, setDateType] = useState('daily');
    const [excelData, setExcel] = useState([]);
    const [frnList, setFrnList] = useState([]);
    const [bizList, setBizList] = useState([]);
    const [driveGraph, setDriveGraph] = useState({
        labels: [],
        datasets: []
    });
    const [airGraph, setAirGraph] = useState({
        labels: [],
        datasets: []
    });
    const [driveGraphAgg, setDriveGraphAgg] = useState(0);
    const [airGraphAgg, setAirGraphAgg] = useState(0);
    const [selectOption, setSelectOption] = useState("cruNm");
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [isClickEnter, setClickEnter] = useState(false);
    const [confirmShow, setConfirmShow] = useState(false);
    const [isBack, setIsBack] = useState(false);
    const [alertShow, setAlertShow] = useState("");
    const [alertMsg, setAlertMessage] = useState("");
    const [excelFile, setExcelFile] = useState([]);
    const [fileName, setFileName] = useState("");
    const [page, setPageNum] = useState(1);
    const driveStatusRef = useRef();
    const driveGraphRef = useRef();
    const state = useLocation().state;
    const cruListRef = useRef();

    useEffect(() => {
        getOptionFranchise();
        setIsBack(true);
        if(state?.scroll === "driveGraph"){
            setTimeout(() => {
                const headerHeight = 120; // 헤더 높이
                const elementPosition = driveGraphRef.current.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
            }, 100);
        }
        if(state?.scroll === "driveStatus"){
            // 위치가 탑이 아니라 중간쯤으로감 (뷰가 다 그려지기 전에 작동하는듯)
            // driveStatusRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
            setTimeout(() => {
                const headerHeight = 120; // 헤더 높이
                const elementPosition = driveStatusRef.current.getBoundingClientRect().top;
                const offsetPosition = elementPosition + window.pageYOffset - headerHeight;

                window.scrollTo({
                    top: offsetPosition,
                    behavior: 'smooth'
                });
                if(state?.order === "odrDriveFrn"){
                    setSecond((prev) => {
                        return ({
                            ...prev,
                            odrDriveFrn: state?.orderValue
                        })
                    })
                }
            }, 100);
        }
    }, [])

    useEffect(() => {
        if (!isBack) return;
        let currentUrl = window.location.pathname;
        if(getPreviousUrl() === currentUrl) {
            let searchData = getSearchData();
            if(searchData) {
                setSecond(searchData.secondData);
                setDateType(searchData.selectDateType);
                if (searchData.beforeData.carNo != "") setSelectOption("carNo");
                if (searchData.beforeData.cruNm != "") setSelectOption("cruNm");
                if (searchData.beforeData.cruHp != "") setSelectOption("cruHp");
                onSearch(searchData);
                removeSearchData();
            } else {
                onSearch();
            }
        } else {
            onSearch();
        }
        setCurrentUrl(currentUrl);
        setIsBack(false);
    }, [frnList]);

    useEffect(() => {
        getOptionBiz(data);
    }, [data.prFrnSeq, data.frnGradeCd])

    useEffect(() => {
        setSelectedRowKeys([]);
        onBottomSearch(page);
    }, [page])

    useEffect(() => {
        if (isClickEnter) {
            onSearch();
            setClickEnter(false);
        }
    }, [data.frnSeq])

    useDidMountEffect(() => {
        const dashTemp = {
            ...secondData,
            frnSeq: data.frnSeq,
            prFrnSeq: data.prFrnSeq,
            frnGradeCd: data.frnGradeCd
        }
        if (!data.frnSeq) {
            fetchDriveStatFrn(dashTemp);
        } else {
            fetchDriveStatCru(dashTemp);
        }
    }, [secondData.odrDriveFrn])

    useDidMountEffect(() => {
        if (scoreList[0]?.name === "-") return;
        const dashTemp = {
            ...secondData,
            frnSeq: data.frnSeq,
            prFrnSeq: data.prFrnSeq,
            frnGradeCd: data.frnGradeCd
        }
        if (!data.frnSeq) {
            fetchAirDriveFrn(dashTemp);
        } else {
            fetchAirDriveCru(dashTemp);
        }
    }, [secondData.odrDrivePoint])

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            setClickEnter(true);
        }
    }

    const onBottomEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onBottomSearch(1);
        }
    }

    const onSearch = (searchData) => {
        let data2 = searchData?.data ?? data;
        const tempData = {
            ...data2,
            [selectOption]: beforeData[selectOption],
            page: 1
        }
        const dashTemp = {
            ...searchData?.secondData ?? secondData,
            frnSeq: data2.frnSeq,
            prFrnSeq: data2.prFrnSeq,
            frnGradeCd: data2.frnGradeCd
        }
        setData(trimObject(tempData));
        setPageNum(1);
        setBefore(searchData?.beforeData ?? tempData);
        setDriveGraph([]);
        setAirGraph([]);
        fetchData(tempData);
        fetchDriveScore(dashTemp);
        fetchDustScore(dashTemp);
        if (!data2.frnSeq) {
            fetchDriveScoreFrn(dashTemp);
            fetchAirScoreFrn(dashTemp);
            fetchDriveStatFrn(dashTemp)
            fetchAirDriveFrn(dashTemp);
            fetchLowScoreFrn(dashTemp);
        } else {
            fetchDriveScoreCru(dashTemp);
            fetchAirScoreCru(dashTemp);
            fetchDriveStatCru(dashTemp)
            fetchAirDriveCru(dashTemp);
            fetchLowScoreCru(dashTemp);
        }
        if(searchData) {
            onBottomSearch(1, searchData.beforeData);
        }
    }

    const getOptionFranchise = async () => {
        const tempData = {
            page: 1,
            pageSize: 9999,
            frnGradeCd: "F0002",
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        setFrnList((prev) => prev.concat({
                            value: item.frnSeq,
                            label: item.bizNm
                        }))
                    })
                }
            });
        } catch {
            setFrnList([]);
        }
    }

    const getOptionBiz = async (temp) => {
        const tempData = {
            ...temp,
            page: 1,
            pageSize: 9999,
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then(async (res) => {
                const itemList = [];
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        if(item.frnGradeCd === "F0002" || item.frnGradeCd === "F0001"){
                        }else{
                            itemList.push({
                                value: item.frnSeq,
                                label: item.bizNm
                            });
                        }
                    })
                }
                setBizList(itemList);
            });
        } catch {

        }
    }

    const fetchData = async (data) => {
        try {
            const result = await cru.GetCru(data).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    setTotal(res.totalCnt);
                    setListTotal(res.totalCnt);
                    return res.datas.map((item) => {
                        return {
                            ...item,
                            key: item.cruSeq
                        }
                    })
                } else {
                    setListTotal(0);
                    setTotal(0);
                }
            });
            setList(result);
        } catch {
            setListTotal(0);
            setTotal(0);
            setList([]);
        }
        setSelectedRowKeys([]);
        setExcel([]);
    }

    const fetchDriveScore = async (dashTemp) => {
        try {
            await cru.safetyDriveScoreSum(dashTemp).then((res) => {
                if (res?.info) {
                    setDash((prev) => {
                        return ({
                            ...prev,
                            driveScore: Math.round(res.info.driveScore) ?? "0"
                        })
                    })
                } else {
                    setDash((prev) => {
                        return ({
                            ...prev,
                            driveScore: "0"
                        })
                    })
                }
            });
        } catch {
            setDash((prev) => {
                return ({
                    ...prev,
                    driveScore: "0"
                })
            })
        }
    }

    const fetchDustScore = async (dashTemp) => {
        try {
            await cru.safetyAirScoreSum(dashTemp).then((res) => {
                setDash((prev) => {
                    return ({
                        ...prev,
                        dustScore: res.info ? Math.round(res.info?.dustScore) : "0"
                    })
                })
            });
        } catch {
            setDash((prev) => {
                return ({
                    ...prev,
                    dustScore: "0"
                })
            })
        }
    }

    const fetchDriveScoreFrn = async (dashTemp) => {
        const endAggDay = new Date(dashTemp.endAggDay);
        const beforeAweek = new Date(endAggDay.setDate(endAggDay.getDate() - 7));
        const temp = {
             ...dashTemp,
             startAggDay: dayjs(beforeAweek).format("YYYY-MM-DD"),
        }
        // labels
        const label = getGraphLabel(temp.startAggDay, temp.endAggDay);
        try {
            let dataSet = [];
            // 안전 운전 그래프 (운수사)
            await cru.safetyDriveScoreFrn(temp).then((res) => {
                if (res?.info && res.info?.frn.length > 0) {
                    const bizData = getGraphData(res.info.frn, temp.startAggDay);
                    dataSet.push(...bizData);
                }
            });
            // 전체 평균값
            await cru.safetyDriveScoreSum(temp).then((res) => {
                if (res?.info) {
                    setDriveGraphAgg(Math.round(res.info.driveScore) ?? "0");
                } else {
                    setDriveGraphAgg("0");
                }
            });

            if(dataSet.length === 0) {
                setDriveGraph({
                    labels: label,
                    datasets: []
                })
            } else {
                setDriveGraph({
                    labels: label,
                    datasets: dataSet?.map((item, i) => {
                        return {
                            ...item,
                            backgroundColor: item.frnSeq === temp.prFrnSeq ? "#e7e7e7" : scoreColor[i], // 그라데이션을 배경색으로 설정
                            borderColor: item.frnSeq === temp.prFrnSeq ? "#e7e7e7" : [scoreColor[i]],
                            borderWidth: 1,
                            pointBorderWidth: 2
                        }
                    })
                })
            }
        } catch {
            setDriveGraphAgg("0");
            setDriveGraph({
                labels: label,
                datasets: []
            })
        }
    }

    const fetchDriveScoreCru = async (dashTemp) => {
        const endAggDay = new Date(dashTemp.endAggDay);
        const beforeAweek = new Date(endAggDay.setDate(endAggDay.getDate() - 7));
        const temp = {
            ...dashTemp,
            startAggDay: dayjs(beforeAweek).format("YYYY-MM-DD"),
        }
        // labels
        const label = getGraphLabel(temp.startAggDay, temp.endAggDay);
        try {
            let dataSet = [];
            await cru.safetyDriveScoreCru(temp).then((res) => {
                if (res?.info?.cru.length > 0) {
                    // 안전 운전 그래프 (운수사)
                    const bizData = getGraphData(res.info.cru, temp.startAggDay);
                    dataSet.push(...bizData);
                }
                if (res?.info?.frn.length > 0) {
                    // 운수사 평균값
                    const aggData = getGraphData(res.info.frn, temp.startAggDay);
                    dataSet.push({
                        label: "평균",
                        data: aggData[0].data
                    });
                }
            });
            // 전체 평균값
            await cru.safetyDriveScoreSum(temp).then((res) => {
                if (res?.info) {
                    setDriveGraphAgg(Math.round(res.info.driveScore) ?? "0");
                } else {
                    setDriveGraphAgg("0");
                }
            });

            if(dataSet.length === 0) {
                setDriveGraph({
                    labels: label,
                    datasets: []
                })
            } else {
                setDriveGraph({
                    labels: label,
                    datasets: dataSet?.map((item, i) => {
                        return {
                            ...item,
                            backgroundColor: item.label === "평균" ? "#e7e7e7" : scoreColor[i], // 그라데이션을 배경색으로 설정
                            borderColor: item.label === "평균" ? ["#e7e7e7"] : [scoreColor[i]],
                            borderWidth: 1,
                            pointBorderWidth: 2
                        }
                    })
                })
            }
        } catch {
            setDriveGraph({
                labels: label,
                datasets: []
            })
        }
    }

    const fetchAirScoreFrn = async (dashTemp) => {
        const endAggDay = new Date(dashTemp.endAggDay);
        const beforeAweek = new Date(endAggDay.setDate(endAggDay.getDate() - 7));
        const temp = {
            ...dashTemp,
            startAggDay: dayjs(beforeAweek).format("YYYY-MM-DD"),
        }
        // labels
        const label = getGraphLabel(temp.startAggDay, temp.endAggDay);
        try {
            let dataSet = [];
            // 공기질 그래프 (운수사)
            await cru.safetyAirScoreFrn(temp).then((res) => {
                if (res?.info && res.info?.frn.length > 0) {
                    const bizData = getGraphData(res.info.frn, temp.startAggDay);
                    dataSet.push(...bizData);
                }
            });
            // 전체 평균값
            await cru.safetyAirScoreSum(temp).then((res) => {
                if (res?.info) {
                    setAirGraphAgg(Math.round(res.info.dustScore) ?? "0");
                } else {
                    setAirGraphAgg("0");
                }
            });

            if(dataSet.length === 0) {
                setAirGraph({
                    labels: label,
                    datasets: []
                })
            } else {
                setAirGraph({
                    labels: label,
                    datasets: dataSet?.map((item, i) => {
                        return {
                            ...item,
                            backgroundColor: item.frnSeq === temp.prFrnSeq ? "#e7e7e7" : airColor[i], // 그라데이션을 배경색으로 설정
                            borderColor: item.frnSeq === temp.prFrnSeq ? "#e7e7e7" : [airColor[i]],
                            borderWidth: 1,
                            pointBorderWidth: 2
                        }
                    })
                })
            }
        } catch {
            setAirGraphAgg("0");
            setAirGraph({
                labels: label,
                datasets: []
            })
        }
    }

    const fetchAirScoreCru = async (dashTemp) => {
        const endAggDay = new Date(dashTemp.endAggDay);
        const beforeAweek = new Date(endAggDay.setDate(endAggDay.getDate() - 7));
        const temp = {
            ...dashTemp,
            startAggDay: dayjs(beforeAweek).format("YYYY-MM-DD"),
        }
        // labels
        const label = getGraphLabel(temp.startAggDay, temp.endAggDay);
        try {
            let dataSet = [];
            await cru.safetyAirScoreCru(temp).then((res) => {
                if (res?.info?.cru.length > 0) {
                    // 안전 운전 그래프 (운수사)
                    const bizData = getGraphData(res.info.cru, temp.startAggDay);
                    dataSet.push(...bizData);
                }
                if (res?.info?.frn.length > 0) {
                    // 운수사 평균값
                    const aggData = getGraphData(res.info.frn, temp.startAggDay);
                    dataSet.push({
                        label: "평균",
                        data: aggData[0].data
                    });
                }
            });
            // 전체 평균값
            await cru.safetyAirScoreSum(temp).then((res) => {
                if (res?.info) {
                    setAirGraphAgg(Math.round(res.info.dustScore) ?? "0");
                } else {
                    setAirGraphAgg("0");
                }
            });

            if(dataSet.length === 0) {
                setAirGraph({
                    labels: label,
                    datasets: []
                })
            } else {
                setAirGraph({
                    labels: label,
                    datasets: dataSet?.map((item, i) => {
                        return {
                            ...item,
                            backgroundColor: item.label === "평균" ? "#e7e7e7" : airColor[i], // 그라데이션을 배경색으로 설정
                            borderColor: item.label === "평균" ? ["#e7e7e7"] : [airColor[i]],
                            borderWidth: 1,
                            pointBorderWidth: 2
                        }
                    })
                })
            }
        } catch {
            setAirGraphAgg("0");
            setAirGraph({
                labels: label,
                datasets: []
            })
        }
    }

    const fetchAirDriveFrn = async (dashTemp) => {
        try {
            await cru.airDriveFrn(dashTemp).then((res) => {
                if (res?.info && res.info?.frn.length > 0) {
                    setScoreList(res.info.frn.map((item) => {
                        return {
                            ...item,
                            driveScore: Math.round(item.driveScore),
                            dustScore: Math.round(item.dustScore),
                            name: item.bizNm
                        }
                    }));
                } else {
                    setScoreList([{
                        driveScore: 0,
                        dustScore: 0,
                        name: "-"
                    }]);
                }
            });
        } catch {
            setScoreList([{
                driveScore: 0,
                dustScore: 0,
                name: "-"
            }]);
        }
    }

    const fetchDriveStatFrn = async (dashTemp) => {
        try {
            await cru.driveStatFrn(dashTemp).then((res) => {
                if (res?.datas && res.datas.length > 0) {
                    setDriveStatList(res.datas?.map((item) => {
                        return {
                            ...item,
                            name: item.bizNm,
                            termTime: Math.floor(item.termTime / 60 / 60)
                        }
                    }));
                } else {
                    setDriveStatList([{
                        distance: 0,
                        numCnt: 0,
                        termTime: 0,
                        name: "-"
                    }]);
                }
            });
        } catch {
            setDriveStatList([{
                distance: 0,
                numCnt: 0,
                termTime: 0,
                name: "-"
            }]);
        }
    }

    const fetchDriveStatCru = async (dashTemp) => {
        try {
            await cru.driveStatCru(dashTemp).then((res) => {
                if (res?.datas && res.datas.length > 0) {
                    setDriveStatList(res.datas?.map((item) => {
                        return {
                            ...item,
                            name: item.cruNm,
                            termTime: Math.floor(item.termTime / 60 / 60)
                        }
                    }));
                } else {
                    setDriveStatList([{
                        distance: 0,
                        numCnt: 0,
                        termTime: 0,
                        name: "-"
                    }]);
                }
            });
        } catch {
            setDriveStatList([{
                distance: 0,
                numCnt: 0,
                termTime: 0,
                name: "-"
            }]);
        }
    }


    const fetchAirDriveCru = async (dashTemp) => {
        try {
            await cru.airDriveCru(dashTemp).then((res) => {
                if (res?.info && res.info?.cru.length > 0) {
                    setScoreList(res.info.cru.map((item) => {
                        return {
                            ...item,
                            driveScore: Math.round(item.driveScore),
                            dustScore: Math.round(item.dustScore),
                            name: item.cruNm
                        }
                    }));
                } else {
                    setScoreList([{
                        driveScore: 0,
                        dustScore: 0,
                        name: "-"
                    }]);
                }
            });
        } catch {
            setScoreList([{
                driveScore: 0,
                dustScore: 0,
                name: "-"
            }]);
        }
    }

    const fetchLowScoreFrn = async (dashTemp) => {
        try {
            await cru.lowScoreFrn(dashTemp).then((res) => {
                if (res.info && res.info?.frn.length > 0) {
                    let warning = [];
                    let cnt = 0;
                    res.info.frn.forEach((item) => {
                        cnt += item.cruCnt;
                        warning.push({
                            id: item.frnSeq,
                            value: item.lowCnt,
                            label: `${item.bizNm} | ${item.cruCnt}명 | ${item.lowCnt}회`,
                            name: item.bizNm
                        })
                    })
                    setDangerCru(warning);
                    setDangerCnt(cnt);
                } else {
                    setDangerCnt(0);
                    setDangerCru([{
                        id: 0,
                        value: 100,
                        label: '위험 운전자 없음',
                    }]);
                }
            });
        } catch {
            setDangerCnt(0);
            setDangerCru([{
                id: 0,
                value: 100,
                label: '위험 운전자 없음',
            }]);
        }
    }

    const fetchLowScoreCru = async (dashTemp) => {
        try {
            await cru.lowScoreCru(dashTemp).then((res) => {
                if (res.info && res.info?.cru.length > 0) {
                    let warning = [];
                    res.info.cru.forEach((item) => {
                        warning.push({
                            id: item.cruSeq,
                            value: item.lowCnt,
                            label: `${item.cruNm} | ${item.lowCnt}회`,
                            name: item.cruNm
                        })
                    })
                    setDangerCru(warning);
                    setDangerCnt(res.info.cru.length);
                } else {
                    setDangerCnt(0);
                    setDangerCru([{
                        id: 0,
                        value: 100,
                        label: '위험 운전자 없음',
                    }]);
                }
            });
        } catch {
            setDangerCnt(0);
            setDangerCru([{
                id: 0,
                value: 100,
                label: '위험 운전자 없음',
            }]);
        }
    }

    const onBottomSearch = async (page, curData) => {
        const temp = {
            ...curData ?? beforeData,
            page,
        }
        setPageNum(page);
        try {
            const result = await cru.GetCru(temp).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    setListTotal(res.totalCnt);
                    return res.datas.map((item) => {
                        return {
                            ...item,
                            key: item.cruSeq
                        }
                    })
                } else {
                    setListTotal(0);
                    return [];
                }
            });
            setList(result);
        } catch {
            setListTotal(0);
            setList([]);
        }
        setSelectedRowKeys([]);
    }

    const onRegister = () => {
        setSearchData({data, secondData, beforeData, selectDateType});
        navigate("/cru/0");
    }

    const onModify = (cru) => {
        setSearchData({data, secondData, beforeData, selectDateType});
        navigate('/cru/'+cru.cruSeq, { state : cru });
    }

    const onDetail = (cru) => {
        setSearchData({data, secondData, beforeData, selectDateType});
        navigate('/cru/detail/'+cru.cruSeq, { state : cru });
    }

    const onDetailCar = (cru) => {
        setSearchData({data, secondData, beforeData, selectDateType});
        navigate('/car/detail/' + cru.carSeq, { state: cru });
    }

    const onExcelUpload = () => {
        setFileName("");
        setConfirmShow(true);
    }
    
    const onRegExcel = async () => {
        setFileName("");
        setConfirmShow(false);
        const data = {
            attFile: excelFile,
            frnSeq: userInfo?.frnSeq
        }
        try {
            await cru.ExcelUpload(data).then((res)=>{
                if (res && res?.cd === 1) {
                    setAlertMessage("업로드 되었습니다.");
                    setAlertShow("alert");
                    onSearch();
                } else {
                    setAlertMessage(res?.msg);
                    setAlertShow("alert");
                    onSearch();
                }
            });
        } catch {
            setAlertMessage("오류가 발생하였습니다.");
            setAlertShow("alert");
            onSearch();
        }
    }

    const onExcelDownload = async () => {
        try {
            const wb = new Excel.Workbook();
            const sheet = wb.addWorksheet(`안전관리`);
            const headerRow = sheet.addRow(excelHeaders);
            headerRow.height = 30.75;
            headerRow.eachCell((cell, colNum) => {
                sheet.getColumn(colNum).width = 20;
                cell.font = {
                    bold: true,
                };
                cell.alignment = {
                    vertical: "middle",
                    horizontal: "center",
                };
            });
            let temp = {
                ...data,
                pageSize: 999999,
                page: 1
            }
            let excelList;
            await cru.GetCru(temp).then((res)=>{
                if(res.datas && res.datas.length > 0){
                    excelList = res.datas;
                }
            })
            const selectedData = selectedRowKeys.length === 0 ? excelList : excelData;

            const datas = [];
            selectedData.forEach((v)=>{
                const row = {};
                Object.keys(v).forEach((key)=>{
                    if(header.includes(key)){
                        if(key === "regDt") {
                            row[key] = dayjs(v[key]).format("YYYY-MM-DD");
                        } else if(key === "cruHp") {
                            row[key] = phoneHypen(v[key]);
                        } else {
                            row[key] = v[key];
                        }
                    }
                });
                datas.push(row);
            });
            datas.forEach((item) => {
                const rowDatas = header.map(key => item[key]);
                const appendRow = sheet.addRow(rowDatas);
                appendRow.eachCell((cell, colNum) => {
                    cell.alignment = {
                        horizontal: "center",
                    }
                });
            });
            const fileData = await wb.xlsx.writeBuffer();
            const blob = new Blob([fileData], {
                type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            });
            saveAs(blob, `기사관리`);
        } catch (error) {
        }
    }

    const onDelete = () => {
        if (selectedRowKeys.length < 1) {
            setAlertMessage("선택한 항목이 없습니다.");
            setAlertShow("alert");
            return;
        }
        setAlertMessage("삭제하시겠습니까?");
        setAlertShow("alertMessage");
    }

    const deleteCru = () => {
        selectedRowKeys.map(async (cruSeq) => {
            const temp = {
                cruSeq,
                frnSeq: userInfo?.frnSeq,
                useYn: "N"
            }
            try {
                await cru.DeleteCru(temp).then((res) => {
                    if (res.info) {
                        setAlertMessage("삭제되었습니다.");
                        setAlertShow("alert");
                        onSearch();
                    } else {
                        setAlertMessage("다시 시도해주세요.");
                        setAlertShow("alert");
                        onSearch();
                    }
                });
            } catch {
                setAlertMessage("오류가 발생하였습니다.");
                setAlertShow("alert");
                onSearch();
            }
        });
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys, data) => {
            setSelectedRowKeys(newSelectedRowKeys);
            setExcel(data);
        },
        fixed: true
    };

    const columns = header.map((key) => {
        if (key === "rownum") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '100px',
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        } else if (key === "prBizNm" || key === "bizNm") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '180px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        } else if (key === "cruNm") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '100px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (value, data) => {
                    return <div className="table-text-blue" onClick={() => onDetail(data)}>{value}</div>
                },
            }
        } else if (key === "carNo") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                width: '150px',
                render: (value, data) => {
                    if (value) {
                        return <div className="table-text-blue" onClick={() => onDetailCar(data)}>{value}</div>
                    } else {
                        return <div className="table-text-red" onClick={() => onDetail(data)}>미등록</div>
                    }
                }
            }
        } else if (key === "cruHp") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '160px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                },
                render: (val) => {
                    return phoneHypen(val);
                }
            }
        } else if (key === "address1") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: 'auto',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        } else if (key === "regDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                width: '120px',
                render: (val) => {
                    return dayjs(val).format("YYYY-MM-DD");
                },
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        } else if (key === "appVer") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '100px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                },
                render: (val) => {
                    if (val) {
                        return <div>{val}</div>
                    } else {
                        return <div className="table-text-red">정보없음</div>
                    }
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                width: '220px',
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onModify(record)
                        },
                    };
                }
            }
        }
    });

    return (
        <Layout>
            <div className="cru-list-wrap">
                <SearchCard
                    title="기사 현황 검색"
                    frnList={frnList}
                    frnValue={data.prFrnSeq}
                    onChangeFrn={(e) => setData((prev) => {
                        return ({
                            ...prev,
                            prFrnSeq: e,
                            frnGradeCd: "",
                            frnSeq: null
                        })
                    })}
                    typeValue={data.frnGradeCd}
                    onChangeType={(e) => setData((prev) => {
                        return ({
                            ...prev,
                            frnGradeCd: e,
                            frnSeq: null
                        })
                    })}
                    searchList={bizList}
                    searchValue={data.frnSeq}
                    onChangeSearch={(e) => setData((prev) => {
                        return ({
                            ...prev,
                            frnSeq: e
                        })
                    })}
                    onKeyPress={onEnter}
                    dateList={[
                        {
                            value: 'monthly',
                            label: '월간',
                        },
                        {
                            value: 'daily',
                            label: '일간',
                        },
                    ]}
                    dateValue={selectDateType}
                    onChangeDateType={(value) => {
                        setDateType(value);
                        setSecond((prev) => {
                            return ({
                                ...prev,
                                startAggDay: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01"),
                                endAggDay: dayjs(yesterday).format("YYYY-MM-DD")
                            })
                        })
                    }}
                    startValue={secondData.startAggDay}
                    endValue={secondData.endAggDay}
                    onChangeDate={(value, dateString) => {
                        setSecond((prev) => {
                            if (value) {
                                let endDt = dayjs(value[1]);
                                var lastDay = new Date(endDt.format("YYYY"), endDt.format("MM"), 0);
                                return ({
                                    ...prev,
                                    startAggDay: dateString[0].split("-").length === 2 ? dateString[0]+"-01" : dateString[0],
                                    endAggDay: dateString[1].split("-").length === 2 ? dayjs(lastDay).format("YYYY-MM-DD") : dateString[1]
                                })
                            } else {
                                return ({
                                    ...prev,
                                    startAggDay: dayjs(yesterday).format(selectDateType === "daily" ? "YYYY-MM-DD" : "YYYY-MM-01"),
                                    endAggDay: dayjs(yesterday).format("YYYY-MM-DD")
                                })
                            }
                        })
                    }}
                    onClick={() => onSearch()}
                    readOnly={userInfo?.frnGradeCd !== "F0001"}
                />
                <ContentCard
                    cardType="small"
                    title="기사"
                    theme="yellow"
                    onClick={()=>{
                        cruListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }}
                    children={
                        <p>총<span>{total}</span>명</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_cs_yellow.svg" alt='기사인원수' />
                    }
                />
                <ContentCard
                    cardType="small"
                    title="위험 운전자"
                    theme="red"
                    onClick={()=>{
                        cruListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }}
                    children={
                        <p>총<span>{dangerCnt}</span>명</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_cs_red.svg" alt='위험운전자' />
                    }
                />
                <ContentCard
                    cardType="small"
                    title="안전 운전"
                    theme="blue"
                    onClick={()=>{
                        cruListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }}
                    children={
                        <p>평균<span>{dashData.driveScore}</span>점</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_cs_drive.svg" alt='안전운전점수' />
                    }
                />
                <ContentCard
                    cardType="small"
                    title="공기 관리"
                    theme="green"
                    onClick={()=>{
                        cruListRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
                    }}
                    children={
                        <p>평균<span>{dashData.dustScore}</span>점</p>
                    }
                    icon={
                        <img src="/assets/icons/icon_cs_eco.svg" alt='공기관리점수' />
                    }
                />
                <ContentCard
                    cardType="wide-graph"
                    title="안전 운전 그래프"
                    useRef={driveGraphRef}
                    graphData={driveGraph}
                    aggValue={driveGraphAgg}
                    tooltipColor="#a0d7ff"
                    color="#39ADFF"
                />
                <ContentCard
                    cardType="wide-graph"
                    title="공기 관리 그래프"
                    graphData={airGraph}
                    aggValue={airGraphAgg}
                    tooltipColor="#6bee98"
                    color="#42D55A"
                />
                <ContentCard
                    cardType="rank-drive"
                    title="운행 현황"
                    useRef={driveStatusRef}
                    sortDropDown={
                        [
                            {
                                value: 0,
                                label: '운행 거리순',
                            },
                            {
                                value: 2,
                                label: '운행 횟수순',
                            },
                            {
                                value: 1,
                                label: '운행 시간순',
                            },
                        ]
                    }
                    selectValue={secondData.odrDriveFrn}
                    list={driveStatList}
                    onChange={(e) => setSecond((prev) => {
                        return ({
                            ...prev,
                            odrDriveFrn: e
                        })
                    })}
                />
                <ContentCard
                    cardType="rank-grade"
                    title="안전관리 현황"
                    sortDropDown={
                        [
                            {
                                value: 0,
                                label: '안전 운전 점수순',
                            },
                            {
                                value: 1,
                                label: '공기 관리 점수순',
                            },
                        ]
                    }
                    selectValue={secondData.odrDrivePoint}
                    list={scoreList}
                    onChange={(e) => setSecond((prev) => {
                        return ({
                            ...prev,
                            odrDrivePoint: e
                        })
                    })}
                />
                <ContentCard
                    cardType="pie-long"
                    title="위험운전자"
                    data={dangerCru}
                    // margin={"-140"}
                    margin={dangerCru.length * 18 > 140 ? "-140" : `-${dangerCru.length * 18}`}
                />
            </div>
            <div className="cru-field-wrap" ref={cruListRef}>
                <h2>
                    기사 목록
                </h2>
                <div className="cru-wrap">
                    <div className="cru-search-wrap">
                        <FrnDrop
                            options={optionList}
                            value={selectOption}
                            onChange={(e) => {
                                setSelectOption(e);
                                setBefore((prev) => {
                                    return ({
                                        ...prev,
                                        carNo: "",
                                        cruNm: "",
                                        cruHp: "",
                                    })
                                })
                            }}
                            width={200}
                            height={36}
                        />
                        <Input
                            placeholder="검색어를 입력하세요."
                            style={{ height: '18px' }}
                            value={beforeData[selectOption]}
                            onChange={(e) => setBefore((prev) => {
                                return ({
                                    ...prev,
                                    [selectOption]: e.target.value
                                })
                            })}
                            onKeyPress={onBottomEnter}
                        />
                        <button className={"btn-small btn-line-navy"} style={{ width: '110px' }} onClick={() => onBottomSearch(1)}>
                            검색
                        </button>
                    </div>
                    <div className="cru-btn-wrap">
                        <button className={"btn-small btn-line-gray"} onClick={onRegister}>
                            기사등록
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onExcelUpload}>
                            <img src="/assets/icons/icon_upload_excel.svg" alt='엑셀등록'></img>
                            <span>엑셀등록</span>
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onExcelDownload}>
                            <img src="/assets/icons/icon_download_excel.svg" alt='엑셀다운로드'></img>
                            <span>엑셀다운로드</span>
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onDelete}>
                            삭제
                        </button>
                    </div>
                </div>
                <div className="cru-table-wrap">
                    <Table
                        dataSource={list}
                        size={"small"}
                        columns={columns}
                        style={{ cursor: "pointer" }}
                        pagination={{
                            current: page,
                            onChange: (page) => setPageNum(page),
                            pageSize: 10,
                            total: listTotal,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                        }}
                        rowSelection={rowSelection}
                    />
                </div>
            </div>
            <ExcelUpload
                isShow={confirmShow}
                title="엑셀파일 등록"
                content="엑셀파일을 등록해주세요."
                fileName={fileName}
                setFileName={(data)=>setFileName(data)}
                onClose={()=>{
                    setFileName("");
                    setConfirmShow(false);
                }}
                onAdd={onRegExcel}
                headers={excelHeaders}
                setExcelFile={setExcelFile}
                excelName="기사엑셀양식"
                type="cru"
            />
            <Alert
                isShow={alertShow === "alert"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
            />
            <AlertMessage
                isShow={alertShow === "alertMessage"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
                onOk={deleteCru}
            />
        </Layout>
    )
}

export default CruList;