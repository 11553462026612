import React, {useEffect, useState} from "react";
import Input from "../../components/Input/Input";
import Layout from "../../layout/Layout";
import Alert from "../../components/Alert";
import {Table} from "antd";
import translator from "../../utils/translator";
import admin from "../../services/admin";
import {useNavigate} from "react-router-dom";
import DateInput from "../../components/Input/DateInput";
import dayjs from "dayjs";
import {
    dateSorter,
    getPreviousUrl,
    getSearchData, removeSearchData, setCurrentUrl,
    setSearchData,
    stringSorter,
    trimObject,
    utcToLocalformat
} from "../../utils";
import {useRecoilValue} from "recoil";
import {userState} from "../../stores/UserStore";
import franchise from "../../services/franchise";
import SearchSelect from "../../components/SelectBox/SearchSelect";
import car from "../../services/car";
import cru from "../../services/cru";
import common from "../../services/common";
import BottomWideRadio from "../../components/Radio/BottomWideRadio";
import ImageAlert from "../../components/Alert/ImageAlert";
import AlertMessage from "../../components/Alert/AlertMessage";
import useDidMountEffect from "../../hooks/useDidMountEffect";

const optionGrade = [
    {
        value: '',
        label: '전체',
    },
    {
        value: 'F0003',
        label: '법인',
    },
    {
        value: 'F0004',
        label: '개인',
    },
];

const StatList = ()=>{
    const userInfo = useRecoilValue(userState);
    const initialData = {
        page: 1,
        pageSize: 10,
        useYn : "Y",
        prFrnSeq : userInfo?.frnGradeCd === "F0001" ? null : (userInfo?.frnGradeCd === "F0002" ? userInfo?.frnSeq : userInfo?.prFrnSeq),
        userId: "",
        userNm:"",
        userHp:"",
        frnSeq: null,
        frnGradeCd: null,
        bizNm: "",
        carNo: "",
        regDtStartUtc: "",
        regDtStart: "",
        useStat: null,
        carSeqList: []
    }
    const [data, setData] = useState(initialData);
    const [failAlert, setFailAlert] = useState(false);
    const [total, setTotal] = useState(0);
    const [list,setList] = useState([]);
    const [frnList, setFrnList] = useState([]);
    const [carNmList, setCarNmList] = useState([]);
    const [carTypeList, setCarTypeList] = useState([]);
    const [carYearList, setCarYearList] = useState([]);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [carNo, setCarNo] = useState("");
    const [page, setPageNum] = useState(1);
    const [showStat, setShowStat] = useState();
    const [failMsg, setFailMsg] = useState("차량 변경에 실패하였습니다");
    const [alertMsg, setAlertMessage] = useState("");
    const [alertShow, setAlertShow] = useState();
    const [carPicData, setCarPicData] = useState();
    const [failContent, setFailContent] = useState("차량 변경은 단일 항목을 선택한 경우에만 가능합니다.");
    const [showAlert, setShowAlert] = useState(false);
    const [imgSrc, setImgSrc] = useState("");
    const [showSeq, setShowSeq] = useState();
    const navigate = useNavigate();

    useEffect(() => {
        // getFrnGradeCode();
        let currentUrl = window.location.pathname;
        if(getPreviousUrl() === currentUrl) {
            let searchData = getSearchData();
            if(searchData) {
                searchData = {
                    ...searchData,
                    regDtStartUtc : searchData.regDtStartUtc ? dayjs(searchData.regDtStartUtc, "YYYY/MM/DD").add(1, 'day') : ""
                }
                setData(searchData);
                fetchData(searchData);
                removeSearchData();
            } else {
                searchList(1);
            }
        } else {
            searchList(1);
        }
        setCurrentUrl(currentUrl);
        getFranchise();
        getCarCode();
    }, []);

    useDidMountEffect(() => {
        searchList(page);
    }, [page])

    const getCarCode = async () => {
        await common.GetCode({cdId: null}).then((res)=>{
            const carTypeList = [];
            const carNmList = [];
            const carYearList = [];
            if(res.datas && res.datas.length > 0){
                res.datas.forEach((item) => {
                    if(item.cdGrp === "C"){
                        carNmList.push({
                            value: item.cdId,
                            label: item.cdVal
                        });
                    }
                    else if(item.cdGrp === "E"){
                        carTypeList.push({
                            value: item.cdId,
                            label: item.cdVal
                        })
                    }else if(item.cdGrp === "Y"){
                        carYearList.push({
                            value: item.cdVal,
                            label: item.cdVal
                        })
                    }
                });
                setCarNmList(carNmList);
                setCarTypeList(carTypeList);
                setCarYearList(carYearList);

            }
        });
    }
    const searchList = (page) => {
        const tempData = {
            ...data,
            page,
        }
        setData(trimObject(tempData));
        setPageNum(page);
        fetchData(tempData);
    }

    const fetchData = async (data) => {
        const tempdata = {};
        Object.keys(data).forEach((key) => {
            if (data[key] === "" || data[key] === null || data[key] === undefined ) {
            } else {
                tempdata[key] = data[key]
            }
        })
        setSelectedRowKeys([]);
        try {
            if(userInfo?.frnGradeCd === "F0003"){tempdata.frnSeq = userInfo?.frnSeq;}

            const result = await car.GetCar(tempdata).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    setTotal(res.totalCnt);
                    console.log(res)

                    return res.datas.map((item) => {
                        return {
                            ...item,
                            key: item?.carSeq
                        }
                    })
                } else {
                    setTotal(0);
                    return [];
                }
            });
            setList(result);
        } catch {
            setList([]);
            setTotal(0);
        }
    }

    const getFranchise = async () => {
        const tempData = {
            page: 1,
            pageSize: 9999,
            frnGradeCd: "F0002",
            useYn: "Y"
        }
        try {
            await franchise.GetFranchise(tempData).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    res.datas.forEach((item) => {
                        setFrnList((prev) => prev.concat({
                            value: item.frnSeq,
                            label: item.bizNm
                        }))
                    })
                }
            });
        } catch {
            setFrnList([])
        }
    }

/*    const getFrnGradeCode = async () => {
        try {
            await common.GetCode({cdGrp: "F"}).then((res) => {
                if (res.datas && res.datas.length > 0) {
                    const data = [];
                    data.push({
                        value: "",
                        label: "전체"
                    })
                    res.datas.forEach((item) => {
                        data.push({
                            value: item.cdId,
                            label: item.cdVal
                        })
                    })
                    setSelectList(data);
                }
            });
        } catch {
            setSelectList([]);
        }
    }*/

    const onSearch = () => {
        searchList(1);
    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            onSearch();
        }
    }

    const onClear = () => {
        setData(initialData);
        if(page === 1) {
            fetchData(initialData);
        } else {
            setPageNum(1);
        }
    }
    const onClose = () => {
        //TODO 여기 차량등록증확인 닫기 넣기
        setShowAlert(false);
    }
    const onCarClick = (record) => {
        if(!record.carSeq || record.carSeq == 0){return;}
        setSearchData(data);
        navigate(`/car/detail/${record.carSeq}`, {state: record});
    }
    const valueCheck = ()=>{
        let arr = showAlert ? [showSeq] : selectedRowKeys;
        let bool = true;
        arr.forEach((v)=>{
            let target;
            list.forEach((value)=>{
                if(value.carSeq == v){
                    target = value;
                }
            });
            console.log(target);
            if(!(target.useStat === "R" || target.useStat === "N")){
                setFailMsg("상태 변경에 실패하였습니다");
                setFailContent(" 미승인 및 승인 요청 상태인 경우에만 가능합니다.");
                setFailAlert(true);
                bool = false;
            }
        });
        return bool;
    }
    const onAccept = async ()=>{
        const temp = {
            carSeqList : showAlert ? [showSeq] : selectedRowKeys,
            useStat : "Y",
            frnSeq : userInfo.frnSeq
        }
        if(!valueCheck()) return ;

        console.log(temp)
        await car.SelectModifyCar(temp).then((res) => {
            console.log(res.info)
            if(res.info){
                setFailMsg("변경 완료");
                setFailContent("해당 항목을 승인 상태로 변경하였습니다.");
                setFailAlert(true);
                searchList(page);
            }else{
                setFailMsg("변경에 실패하였습니다");
                setFailContent("잠시 후 다시 시도해주세요.");
                setFailAlert(true);
            }
        });
    }

    // const onAcceptAll = async ()=>{
    //     await car.AllModifyCar(data).then((res)=>{
    //         console.log(res.info)
    //     })
    // }
    const onRefuse = async ()=>{

        const temp = {
            carSeqList : showAlert ? [showSeq] : selectedRowKeys,
            useStat : "H",
            frnSeq: userInfo.frnSeq
        }
        if(!valueCheck()) return;
        await car.SelectModifyCar(temp).then((res)=>{
            if(res.info){
                setFailMsg("변경 완료");
                setFailContent("해당 항목을 보류 상태로 변경하였습니다.");
                setFailAlert(true);
                searchList(page);
            }else{
                setFailMsg("변경에 실패하였습니다");
                setFailContent("다시 시도해주세요.");
                setFailAlert(true);
            }
        });
    }
    const stopCar = async ()=>{
        setAlertShow(false);
        const temp = {
            // TODO 사용중지 만들기 (확인 컨펌 누르면 사용중지 하도록)
            carSeq : selectedRowKeys[0],
            useStat : "D",
            frnSeq : userInfo.frnSeq
        }
        await car.DeleteCar(temp).then((res)=>{
            if(res.info){
                setFailMsg("변경 완료");
                setFailContent("해당 항목을 사용중지 상태로 변경하였습니다.");
                setFailAlert(true);
                searchList(page);
            }else{
                setFailMsg("변경에 실패하였습니다");
                setFailContent("다시 시도해주세요.");
                setFailAlert(true);
            }
        })

    }
    const onStopUse = async ()=>{
        let target;
        list.forEach((v, i, a)=>{
            if(v.carSeq == selectedRowKeys[0]){
                target = v;
            };
        });

        if(target.useStat == "D"){
            setFailContent("이미 사용중지된 상태입니다.");
            setFailAlert(true);
            return;
        }
        if(selectedRowKeys.length != 1 || selectedRowKeys.length <= 0){
            setFailContent("사용 중지는 단일 항목을 선택한 경우에만 가능합니다.");
            setFailAlert(true);
            return;
        };
        setAlertMessage("해당 차량을 사용 중지 하시겠습니까?");
        setAlertShow("alertMessage");
    }
    const onReqCancel = async ()=>{
        setFailMsg("변경 취소에 실패하였습니다");
        if(selectedRowKeys.length != 1){
            setFailContent("변경 취소는 단일 항목을 선택한 경우에만 가능합니다.");
            setFailAlert(true);
            return;
        };
        let target;
        list.forEach((v, i, a)=>{
            if(v.carSeq == selectedRowKeys[0]){
                target = v;
            };
        });

        if(target.useStat !== "C"){
            setFailContent("해당 항목은 변경요청 상태가 아닙니다.");
            setFailAlert(true);
            return;
        }

        const temp = {
            carSeq : selectedRowKeys[0],
            useStat : "N",
            frnSeq: userInfo.frnSeq
        }
        await car.ModifyCar(null, temp).then((res)=>{
            if(res.info){
                setFailMsg("변경 완료");
                setFailContent("해당 항목을 변경 요청에서 미승인 상태로 변경하였습니다.");
                setFailAlert(true);
                searchList(page);
            }else{
                setFailContent("다시 시도해주세요.");
                setFailAlert(true);
            }
        });
    }
    const onReq = async ()=>{
        if(selectedRowKeys.length != 1){
            setFailMsg("변경에 실패하였습니다");
            setFailContent("차량 변경은 단일 항목을 선택한 경우에만 가능합니다.");
            setFailAlert(true);
            return;
        };
        let target;
        list.forEach((v, i, a)=>{
            if(v.carSeq == selectedRowKeys[0]){
                target = v;
            };
        });

        if(target.useStat !== "C"){
            setFailMsg("차량 변경에 실패하였습니다");
            setFailContent("해당 항목은 변경요청 상태가 아닙니다.");
            setFailAlert(true);
            return;
        }

        await cru.GetCru({carSeq: target.carSeq, useStat: ""}).then((res)=>{
            if(res.datas.length >= 1){
                target.cruSeq = res.datas[0].cruSeq;

            }else{
                //기사 정보 없어서 실패, api에서 cruSeq를 필수로 요구함
                setFailMsg("차량 변경에 실패하였습니다");
                setFailContent("다시 시도해주세요.");
                setFailAlert(true);
                return;
            }
        });
        setSearchData(data);
        navigate(`/car/change/${selectedRowKeys[0]}`, {state: target});
    }
    const onCarPic = (record)=>{
        setShowSeq(record?.carSeq);
        setShowStat(record?.useStat);
        setCarNo(record?.carNo);
        if(!record.carSeq || record.carSeq == 0) return;
        setCarPicData({
            carNm: record?.carNm,
            carYear: record?.carYear,
            carColor: record?.carColor,
            carType: record?.carType
        });
        setShowAlert(true);
        setImgSrc(record?.carPic);
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: (newSelectedRowKeys) => {
            setSelectedRowKeys(newSelectedRowKeys);
        }
    };

    const header = ["rownum", "prBizNm", "bizNm", "carPic", "useStat", "carNo", "carNm", "carColor", "carType", "carYear", "regDt", "updDt"];

    const columns = header.map((key) => {
        if (key === "rownum") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => Number(a[key]) - Number(b[key]),
                align: 'center',
            }
        }
        else if (key === "carNo") {
            return {
                title: translator[key],
                dataIndex: key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onCarClick(record)
                        },
                    };
                },
                render: (value, data) => {
                    return <div className="table-text-blue" >{value}</div>
                }
          }
        }
        else if (key === "useStat") {
            return {
                title: translator[key],
                dataIndex: key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    switch (val) {
                        case "N":
                            return <p style={{color: "blue", textAlign: "center"}}>미승인</p>
                            break;
                        case "Y":
                            return "승인"
                            break;
                        case "R":
                            return <p style={{color: "red", textAlign: "center"}}>승인요청</p>
                            break;
                        case "H":
                            return <p style={{color: "blue", textAlign: "center"}}>보류</p>
                            break;
                        case "C":
                            return <p style={{color: "red", textAlign: "center"}}>변경요청</p>
                            break;
                        case "D":
                            return "사용중지"
                            break;
                        default:
                            return "미승인"
                            break;
                    }
                }
            }
        }else if (key === "regDt") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    return dayjs(val).format("YYYY년 MM월 DD일");
                }
            }
        }else if (key === "updDt"){
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => dateSorter(a, b, key),
                align: 'center',
                render: (val) => {
                    return dayjs(val).format("YYYY년 MM월 DD일");
                }
            }
        } else if (key === "carPic") {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
                onCell: (record) => {
                    return {
                        onClick: () => {
                            onCarPic(record)
                        },
                    };
                },
                render: (val) => {
                    return <button className="car-pic-btn">차량 등록증 확인</button>
                }
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                sorter: (a, b) => stringSorter(a, b, key),
                align: 'center',
            }
        }
    });
    return (
        <Layout>
            <div className="field-wrap">
                <div className="search-field-wrap">
                    <h2>
                        승인 관리
                    </h2>
                    <div className="search-filter">
                        <Input
                            hasLabel
                            label="차량 번호"
                            placeholder="차량 번호를 입력하세요."
                            value={data.carNo}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    carNo: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                        <DateInput
                            hasLabel
                            label="등록일"
                            placeholder="날짜를 선택하세요."
                            value={data.regDtStartUtc}
                            onChange={(date) => setData((prev) => {
                                return ({
                                    ...prev,
                                    regDtStartUtc: date ?? "",
                                    regDtStart: utcToLocalformat(date)
                                })
                            })}
                        />
                        {/*<DateInput*/}
                        {/*    hasLabel*/}
                        {/*    label="처리일"*/}
                        {/*    placeholder="날짜를 선택하세요."*/}
                        {/*    value={data.updDtUtc}*/}
                        {/*    onChange={(date) => setData((prev) => {*/}
                        {/*        return ({*/}
                        {/*            ...prev,*/}
                        {/*            updDtUtc: date ?? "",*/}
                        {/*            updDt: utcToLocalformat(date)*/}
                        {/*        })*/}
                        {/*    })}*/}
                        {/*/>*/}
                        {userInfo?.frnGradeCd === "F0001" &&
                            <SearchSelect
                                label="소속"
                                id="prFrnSeq"
                                placeholder="소속을 선택하세요"
                                value={data.prFrnSeq}
                                onChange={(e) => setData((prev) => {
                                    return ({
                                        ...prev,
                                        prFrnSeq: e
                                    })
                                })}
                                options={frnList}
                            />
                        }
                        <SearchSelect
                            label="소속 유형"
                            id="frnGradeCd"
                            placeholder="소속 유형을 선택하세요"
                            value={data.frnGradeCd}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    frnGradeCd: e
                                })
                            })}
                            options={optionGrade}
                            /*TODO: 로그인한 사람이 가맹본부가 아닐 경우 자기 소속으로 픽스*/
                            /*disabled={}*/
                        />
                        <Input
                            hasLabel
                            label="가맹점명"
                            placeholder="가맹점명을 입력하세요."
                            value={data.bizNm}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    bizNm: e.target.value
                                })
                            })}
                            onKeyPress={onEnter}
                        />
                        <SearchSelect
                            label="차종"
                            id="carNm"
                            placeholder="차종을 선택하세요"
                            value={data.carNmCd}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    carNmCd: e
                                })
                            })}
                            options={carNmList}
                            /*TODO: 로그인한 사람이 가맹본부가 아닐 경우 자기 소속으로 픽스*/
                            /*disabled={}*/
                        />
                        <SearchSelect
                            label="연료"
                            id="carType"
                            placeholder="연료를 선택하세요"
                            value={data.carTypeCd}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    carTypeCd: e
                                })
                            })}
                            options={carTypeList}
                            /*TODO: 로그인한 사람이 가맹본부가 아닐 경우 자기 소속으로 픽스*/
                            /*disabled={}*/
                        />
                        <SearchSelect
                            label="연식"
                            id="carYear"
                            placeholder="연식을 선택하세요"
                            value={data.carYear}
                            onChange={(e) => setData((prev) => {
                                return ({
                                    ...prev,
                                    carYear: e
                                })
                            })}
                            options={carYearList}
                            /*TODO: 로그인한 사람이 가맹본부가 아닐 경우 자기 소속으로 픽스*/
                            /*disabled={}*/
                        />
                    </div>

                </div>
                <BottomWideRadio
                    label="승인형태"
                    id="useStat"
                    value={data.useStat}
                    defaultValue={null}
                    options={[
                        {
                            label: "전체",
                            key: null
                        }, {
                            label: "미승인",
                            key: "N"
                        }, {
                            label: "승인요청",
                            key: "R"
                        }, {
                            label: "승인",
                            key: "Y"
                        }, {
                            label: "보류",
                            key: "H"
                        }, {
                            label: "사용중지",
                            key: "D"
                        }, {
                            label: "변경요청",
                            key: "C"
                        }]}
                    onChange={(e)=>setData((prev)=>{
                        return ({
                            ...prev,
                            useStat: e.target.value
                        })
                    })}
                />
                <div className="divider"></div>
                <div className="btn-between-wrap">
                    <div></div>
                    <div className="search-btn-center">
                        <button className={"btn btn-gray"} onClick={onClear}>
                            초기화
                        </button>
                        <button className={"btn btn-navy"} onClick={onSearch}>
                            검색하기
                        </button>
                    </div>
                    <div></div>
                </div>
            </div>
            <div className="division" />
            <div className="list-field-wrap">
                <h2>
                    검색 결과
                </h2>
                <div className="count-and-button">
                    <p className="total-count">총 <span>{total}</span>건</p>
                    <div className="list-btn-wrap">
                        <button className={"btn-small btn-line-gray"} onClick={onAccept}>
                            승인
                        </button>
                        {/*<button className={"btn-small btn-line-gray"} onClick={onAcceptAll}>*/}
                        {/*    일괄 수락*/}
                        {/*</button>*/}
                        <button className={"btn-small btn-line-gray"} onClick={onRefuse}>
                            승인 보류
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onReq}>
                            변경
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onReqCancel}>
                            변경 취소
                        </button>
                        <button className={"btn-small btn-line-gray"} onClick={onStopUse}>
                            사용 중지
                        </button>
                    </div>
                </div>
                <div className="stat-table-wrap">
                    <Table
                        dataSource={list}
                        columns={columns}
                        size={"small"}
                        style={{ cursor: "pointer" }}
                        pagination={{
                            current: page,
                            onChange: (page) => setPageNum(page),
                            pageSize: 10,
                            total: total,
                            position: ["bottomCenter"],
                            showSizeChanger: false,
                        }}
                        // onRow={(record) => {
                        //     return {
                        //         onClick: () => {
                        //             onCarPic(record);
                        //         },
                        //     };
                        // }}
                        rowSelection={rowSelection}
                    />
                </div>
            </div>
            <ImageAlert
                title="차량 등록증 확인"
                options={carPicData}
                isShow={showAlert}
                onClose={onClose}
                carNo={carNo}
                imgSrc={imgSrc}
                type="stat"
                showStat={showStat}
                onAccept={onAccept}
                onRefuse={onRefuse}
            />
            <Alert
                isShow={failAlert}
                title={failMsg}
                content={<p>{failContent}</p>}
                onClose={()=>{
                    setShowAlert(false);
                    setFailAlert(false);
                }}
            />
            <AlertMessage
                isShow={alertShow === "alertMessage"}
                content={<p>{alertMsg}</p>}
                onClose={()=>{
                    setAlertShow("");
                }}
                onOk={stopCar}
            />
        </Layout>
    )

}

export default StatList;