import "./Alert.scss";

const Alert = (props)=>{
    //TODO token 필요한 페이지 만들어지면 jwt 만료로 테스트
    return (
        <>
            {props.isShow &&
                <div className="content-block">
                    <div className="alert-wrap">
                        <div className="alert-header">
                            <h2 className="">{props.title ?? '알림'}</h2>
                            <i className="" onClick={props.onClose} />
                        </div>
                        {props.content}
                        <div className="btn-wrap">
                            <button className="btn-add" onClick={props.onClose}>확인</button>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default Alert;