import "./DropDown.scss";
import React from "react";
import { ConfigProvider, Select, DatePicker, Space } from 'antd';
import koKR from 'antd/locale/ko_KR'; // 로케일 파일 경로 확인
import dayjs from 'dayjs';

import 'dayjs/locale/ko';

dayjs.locale('ko');
const { RangePicker } = DatePicker;
const dateformat = "YYYY-MM-DD";

const DateTypeDrop = (props) => {
    return (
        <div className="dateTypeDrop">
            <ConfigProvider
                theme={{
                    token: {
                        borderRadius: 12,
                        colorBorder: "#D3D4D5",
                        colorBgContainer: '#fff',
                        fontSize: 15,
                        colorPrimary: "#7688A8"

                    },
                    components: {
                        Select: {
                            optionFontSize: 15,
                            optionHeight: 48,
                            optionLineHeight: 2,
                        }
                    }
                }}
            >
                <Select
                    className="dateTypeSelect"
                    // showSearch
                    style={{
                        width: 110,
                        height: 48,
                        fontSize: 15,
                        marginRight: 8
                    }}
                    suffixIcon={<img src="/assets/icons/icon_shevron_down.svg" alt="down" />}
                    value={props?.value}
                    placeholder="기간 유형 선택..."
                    optionFilterProp="children"
                    onChange={props.onChangeType}
                    filterOption={(input, option) => (option?.label ?? '').includes(input)}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? '').toLowerCase().localeCompare((optionB?.label ?? '').toLowerCase())
                    }
                    options={props.options}
                />
            </ConfigProvider>
            <ConfigProvider
                locale={koKR}
                theme={{
                    token: {
                        borderRadius: 12,
                        colorBorder: "#D3D4D5",
                        colorBgContainer: '#fff',
                        fontSize: 15,
                        colorPrimary: "#7688A8"
                    },
                    components: {
                        Select: {
                            optionFontSize: 15,
                            optionHeight: 48,
                            optionLineHeight: 2
                        }
                    }
                }}
            >
                <Space direction="vertical" size={15} locale={koKR} >
                    {
                        !props || props?.value === "monthly"&&
                        <RangePicker
                            style={{width:223, height: 48}}
                            picker="month"
                            locale={koKR}
                            suffixIcon={<img src="/assets/icons/icon_Calendar.svg" alt="down" />}
                            onChange={props.onChange}
                            value={[dayjs(props.startValue, dateformat), dayjs(props.endValue, dateformat)]}
                            disabled={[false, false]}
                        />
                    }
                    {
                        props?.value==="daily"&&
                        <RangePicker
                            style={{width:260, height: 48}}
                            suffixIcon={<img src="/assets/icons/icon_Calendar.svg" alt="down" />}
                            onChange={props.onChange}
                            value={[dayjs(props.startValue, dateformat), dayjs(props.endValue, dateformat)]}
                            disabled={[false, false]}
                        />
                    }
                    {/*<RangePicker*/}
                    {/*    picker="year"*/}
                    {/*    id={{*/}
                    {/*        start: 'startInput',*/}
                    {/*        end: 'endInput',*/}
                    {/*    }}*/}
                    {/*    onFocus={(_, info) => {*/}
                    {/*        console.log('Focus:', info.range);*/}
                    {/*    }}*/}
                    {/*    onBlur={(_, info) => {*/}
                    {/*        console.log('Blur:', info.range);*/}
                    {/*    }}*/}
                    {/*/>*/}
                </Space>
            </ConfigProvider>
        </div>


    );
};

export default DateTypeDrop;
