import React from "react";
import "./Input.scss";
import Tip from "../Tip";
import {Radio} from "antd";

const RadioInput = (props)=>{
    let value = "";
    if (props.readOnly && props.options.length > 0) {
        props.options.map((item) => {
            if (item.value === props.value) {
                value = item.label;
            }
        })
    }
    return (
        <div className="tip-input-wrap">
            <label htmlFor={props.id}>{props.label}<p className="required-red">{props.required && "*"}</p></label>
            {!props.readOnly &&
                <div>
                    <div className="tip-input-radio">
                        <Radio.Group onChange={props.onChange} value={props.value ?? props.defaultValue}>
                            {props.options.length > 0 && props.options.map((item) => {
                               return <Radio value={item.value}>{item.label}</Radio>
                            })}
                        </Radio.Group>
                    </div>
                    {props.none &&
                        <div className="tipWrap">
                            <Tip
                                content={props.tipContent ?? "필수 입력 사항입니다."}
                                size={12}
                            />
                        </div>
                    }
                </div>
            }
            {props.readOnly &&
                <div className="tip-input-text">{props.value === "F0001" ? "가맹본부" : value}</div>
            }
        </div>
    )
}

export default RadioInput;