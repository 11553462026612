import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import services, { axiosInstance } from "../services/index";
import { userState } from "../stores/UserStore";
import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";
import CheckBox from "../components/CheckBox";
import Button from "../components/Button";
import Tip from "../components/Tip";
import {encryptoSha, decryptoAes, encryptoAes} from "../utils/CryptoUtil";
const Login = () => {
    const [userInfo, setUserInfo] = useRecoilState(userState);

    const navigate = useNavigate();

    const [userId, setUserId] = useState("");
    const [userPwd, setUserPwd] = useState("");
    const [formStatus, setFormStatus] = useState("active");
    const [loginFail, setLoginFail] = useState(false);
    const [loginFailMsg, setLoginFailMsg] = useState("아이디나 비밀번호가 틀렸습니다. 확인하고 다시 로그인 해주세요.");
    const [idNone, setIdNone] = useState(false);
    const [pwdNone, setPwdNone] = useState(false);
    const [keepLogin, setKeepLogin] = useState(false);

    useEffect(()=>{

        //로그인 유지 기능 사용중인지 확인 - 로컬 스토리지 체크
        keepLoginCheck();
    }, []);
    useEffect(() => {
        formStatusUpdate();
    }, [userId, userPwd]);
    useEffect(()=>{

    }, [idNone, pwdNone]);
    useEffect(()=>{

    }, [userInfo]);
    const keepLoginCheck = async ()=>{
        const keepLoginToken = localStorage.getItem("keepLoginToken");
        if(!keepLoginToken || keepLoginToken.length < 0){
            return ;
        }else{
            await Cookies.set("access-token", keepLoginToken);
            axiosInstance.interceptors.request.use((config) => {
                config.headers["Authorization"] = `Bearer ${keepLoginToken}`;
                return config;
            }, (err) => {
                return Promise.reject(err);
            });

            const decodeKeepLoginToken = jwtDecode(keepLoginToken);

            await setUserInfo(prevUserInfo => ({
                ...prevUserInfo,
                userId: decodeKeepLoginToken.id,
                role: decodeKeepLoginToken.role,
                userNm: decodeKeepLoginToken.nm,
                frnSeq: decodeKeepLoginToken.frnSeq,
                frnGradeCd: decodeKeepLoginToken.frnGradeCd,
                iat: decodeKeepLoginToken.iat,
                exp: decodeKeepLoginToken.exp,
                prFrnSeq: decodeKeepLoginToken.prFrnSeq,
                bizNm: decodeKeepLoginToken.bizNm
            }));
            // console.log(jwtDecode(keepLoginToken));
            navigate("/main");
        }
    }
    const keepLoginHandler = (checked) => {
        setKeepLogin(checked);

    }

    const formStatusUpdate = () => {
        if(!userId || !userPwd){setFormStatus(""); return;}

        if (userId.length > 1 && userPwd.length > 1) {
            setFormStatus("active");
        } else {
            setFormStatus("");
        }
    }

    const userIdHandler = (value) => {
        setUserId(value);
    }
    const userPwdHandler = (value) => {
        setUserPwd(value);
    }

    const inputValidate = ()=>{
        let pass = true;
        if(!userId || userId.length < 1){
            setIdNone(true);
            pass = false;
        }
        if(!userPwd || userPwd.length < 1){
            setPwdNone(true);
            pass = false;
        }
        return pass;
    }

    const loginHandler = async (e) => {
        e.preventDefault();
        setLoginFail(false);
        if(!inputValidate()){
            console.log(idNone, pwdNone)
            setTimeout(()=>{
                setIdNone(false);
                setPwdNone(false);
            }, 5000);
            return ;
        };

        try {
            const token = await services.auth.login({ userId: userId, userPwd: userPwd, ip: "172.30.1.54" });

            if (!token) {
                throw new Error("Login Fail: Token is Null");
            }
            await Cookies.set("access-token", token);
            axiosInstance.interceptors.request.use((config) => {
                config.headers["Authorization"] = `Bearer ${token}`;
                return config;
            }, (err) => {
                return Promise.reject(err);
            });

            //로그인 유지 기능 활성화 되어있는 경우
            if(keepLogin){
                localStorage.setItem("keepLoginToken", token);
            }
            const decodeToken = jwtDecode(token);
            //TODO 메인 페이지로 이동 + 유저 정보 상태관리에 저장해야함
            await setUserInfo(prevUserInfo => ({
                ...prevUserInfo,
                userId: decodeToken.id,
                role: decodeToken.role,
                userNm: decodeToken.nm,
                frnSeq: decodeToken.frnSeq,
                prFrnSeq: decodeToken.prFrnSeq,
                frnGradeCd: decodeToken.frnGradeCd,
                bizNm: decodeToken.bizNm,
                iat: decodeToken.iat,
                exp: decodeToken.exp
            }));
            navigate("/main");
        } catch (err) {
            //TODO 나중에 로그인 에러코드 혹은 반환값 생기면 여기서 에러 구문 선택해서 표시
            // if(err.code){
            //     let msg = "";
            //     switch (err.code){
            //         case 1:
            //             msg = "서버 오류입니다. 잠시후 다시 로그인 해주세요."
            //             break;
            //         case 2:
            //             msg = "사용이 정지된 회원입니다. 관리자에게 문의하세요."
            //             break;
            //         default:
            //             msg = "아이디나 비밀번호가 틀렸습니다. 확인하고 다시 로그인 해주세요."
            //             break;
            //     }
            //     setLoginFailMsg(msg);
            // }
            setLoginFail(true);
        }

    }

    const onEnter = (e) => {
        if (e.key.toLowerCase() === 'enter') {
            loginHandler(e);
        }
    }

    return (
        <div className="login-wrap">
            <div className="logo">
                <img src="/assets/images/Logo_ifense.svg" alt="ifense 로고" />
            </div>
            <div className="login-infomation-wrap">
                <h2>관리자 로그인</h2>
                <div className="info-field">
                    <span>아이디</span>
                    <input
                        type="text"
                        id="userId"
                        className="input-text input-full"
                        placeholder="id를 입력하세요."
                        onChange={e => userIdHandler(e.target.value)}
                        onKeyPress={e => onEnter(e)}
                        style={idNone ? { border: "1px solid #F04E3E" } : {}}
                    />
                    {idNone &&
                        <div className="tipWrap">
                            <Tip
                                content="필수 입력 사항 입니다."
                                size={12}
                            />
                        </div>
                    }
                </div>

                <div className="info-field">
                    <span>비밀번호</span>
                    <input
                        type="password"
                        id="userPwd"
                        className="input-text input-full"
                        placeholder="password를 입력하세요."
                        onChange={e => userPwdHandler(e.target.value)}
                        onKeyPress={e => onEnter(e)}
                        style={pwdNone ? { border: "1px solid #F04E3E" } : {}}
                    />
                    {pwdNone &&
                        <div className="tipWrap">
                            <Tip
                                content="필수 입력 사항 입니다."
                                size={12}
                            />
                        </div>
                    }
                </div>

                <div className="keepLogin">
                    <CheckBox
                        label="로그인 유지"
                        checked={false}
                        onChange={keepLoginHandler}
                    />
                </div>
                <Button
                    buttonType="login"
                    content="로그인"
                    onClick={loginHandler}
                    formStatus={formStatus}
                />
                <div className="login-helper-wrap">
                    <Link to="/findId">아이디 찾기</Link>
                    |
                    <Link to="/findPwd">비밀번호 찾기</Link>
                </div>
                {
                    loginFail &&
                    <div className="login-fail-msg">
                        <Tip
                            content={loginFailMsg}
                            size={15}
                        />
                    </div>
                }
            </div>
        </div>

    )
}

export default Login;
