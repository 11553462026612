import React from "react";
import "./SelectBox.scss";
import {ConfigProvider, Select} from "antd";

const SearchSelect = (props)=>{
    return (
        <div className="input-wrap">
            <label htmlFor={props.id} className="input-label">{props.label}</label>
            <div>
                <ConfigProvider
                    theme={{
                        token: {
                            borderRadius: 8,
                            colorBorder: "#D3D4D5",
                            colorBgContainer: '#ffffff',
                            fontSize: 14,
                            transition: 'all 0.5s',
                            lineHeight: 1,
                            colorTextPlaceholder: '#7C7F81',
                            colorPrimary: '#7688A8',
                            colorPrimaryHover: '#D3D4D5',
                        },
                        components: {
                            Select: {
                                optionFontSize: 15,
                                optionHeight: 30,
                                // optionLineHeight: 2
                            }
                        }
                    }}
                >
                    <Select
                        // showSearch
                        style={{
                            width: props.width ? props.width : 227,
                            height: props.height ? props.height : 37,
                            fontSize: 14,
                            borderRadius: 9
                        }}
                        suffixIcon={<img src="/assets/icons/icon_shevron_down.svg" alt="down" />}
                        onChange={props.onChange}
                        placeholder={props.placeholder}
                        value={props.value ?? null}
                        optionFilterProp="children"
                        filterOption={(input, option) => (option?.label ?? '').includes(input)}
                        filterSort={(optionA, optionB) => {
                            if (isNaN(optionA.value) && isNaN(optionB.value)) return;

                            if (optionA?.value === 1) {
                                return -1
                            } else if (optionB?.value === 1) {
                                return 1
                            } else {
                                return (optionA?.value ?? '').toString().toLowerCase().localeCompare((optionB?.value ?? '').toString().toLowerCase());
                            }
                        }}
                        options={props.options ?? []}
                        disabled={props.disabled}
                    />
                </ConfigProvider>
            </div>
        </div>
    )
}

export default SearchSelect;