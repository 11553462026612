import React from 'react';
import "./Button.scss";

const Button = (props) => {

    return (
        <>
            {props.buttonType === "login" &&
                <button className={props.formStatus === "active" ? "loginButton active" : "loginButton"} onClick={props.onClick}>
                    {props.content}
                </button>
            }
            {props.buttonType === "normal" &&
                <button className="btn-normal" onClick={props.onClick}>
                    {props.content}
                </button>
            }
        </>
    );
}

export default Button;
