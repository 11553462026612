import React from "react";
import "./Input.scss";

const Input = (props)=>{
    const inputPropsOnly = {
        ...props,
    };

    delete inputPropsOnly.hasLabel;

    return (
        <div className="input-wrap">
            {props.hasLabel && (
                <span className="input-label">
                    {props.label}
                </span>
            )}
            <input {...inputPropsOnly} />
        </div>
    )
}

export default Input;