import "./Confirm.scss";

const ExcelUpload = (props)=> {
    const onSelectFile = () => {
        document.getElementById("fileUpload").click();
    }

    const handleFileChange = (e) => {
        if (!e.target.files[0].name) return;

        props.setFileName(e.target.files[0].name);
        props.setExcelFile(e.target.files[0]);
    }

    return (
        <>
            {props.isShow &&
                <div className="confirm-wrap">
                    <h2>
                        {props.title}
                        {/*dev s3*/}
                        {/*<a href={`https://static.vifense.net/prod/excel/${props.type}.xlsx`}>*/}
                        {/*    <img src="/assets/icons/icon_question_gray.svg" title="엑셀 양식 다운로드" />*/}
                        {/*</a>*/}
                        {/*prod s3*/}
                        <a href={`https://static.vifense.net/prod/excel/${props.type}.xlsx`}>
                            <img src="/assets/icons/icon_question_gray.svg" title="엑셀 양식 다운로드" />
                        </a>
                    </h2>
                    <p>{props.content}</p>
                    <div className="confirm-input">
                        <input type="text" value={props.fileName} className="file-input" />
                        <input type="file" name="fileUpload" id="fileUpload" onChange={handleFileChange} style={{ display: 'none' }}/>
                        <button className="btn btn-line-navy" onClick={onSelectFile}>파일 선택</button>
                    </div>
                    <div className="btn-wrap">
                        <button className="btn-cancel" onClick={props.onClose}>취소</button>
                        <button className="btn-add" onClick={props.onAdd}>업로드</button>
                    </div>
                </div>
            }
        </>
    )
}

export default ExcelUpload;
