import "./Modal.scss";
import {Table} from "antd";
import translator from "../../utils/translator";
import useDidMountEffect from "../../hooks/useDidMountEffect";
import cru from "../../services/cru";
import {useState} from "react";
import dayjs from "dayjs";
import {timeFormat} from "../../utils";

const CruModal = (props)=> {
    const [list, setList] = useState([]);
    const [page, setPageNum] = useState(1);
    const [total, setTotal] = useState(0);
    let tableList = [{
        ...props.data,
        key: 1
    }];

    useDidMountEffect(() => {
        if(!props.isShow) return;
        onSearch();
    }, [props.isShow])

    useDidMountEffect(() => {
        onSearch();
    }, [page])

    const onSearch = () => {
        const temp = {
            page,
            pageSize: 6,
            frnSeq: tableList[0].frnSeq,
            cruSeq: tableList[0].cruSeq,
            carSeq: tableList[0].carSeq,
            aggDay: tableList[0].aggDay,
            prFrnSeq: null,
        }
        if (props.tab === "drive") {
            fetchDetailDrive(temp);
        } else {
            fetchDetailAir(temp);
        }
    }

    const fetchDetailDrive = async (temp) => {
        try {
            await cru.safetyDriveList(temp).then(async (res) => {
                if (res.datas && res.datas.length > 0) {
                    setTotal(res.totalCnt);
                    setList(res.datas.map((item) => {
                        return {
                            ...item,
                            key: item.rownum,
                            date: dayjs(item.dt).format("YYYY-MM-DD HH:mm:ss"),
                            sdName: getSdName(item),
                            speed: `${item.preSpeed ?? 0} -> ${item.speed ?? 0}`,
                            steering: `${item.preSteering ?? 0} -> ${item.steering ?? 0}`,
                        }
                    }));
                } else {
                    setTotal(0);
                    setList([]);
                }
            });
        } catch {
            setTotal(0);
            setList([]);
        }
    }

    const fetchDetailAir = async (temp) => {
        try {
            await cru.airQualityList(temp).then(async (res) => {
                if (res.datas && res.datas.length > 0) {
                    setTotal(res.totalCnt);
                    setList(res.datas.map((item) => {
                        return {
                            ...item,
                            key: item.rownum,
                            date: dayjs(item.dt).format("YYYY-MM-DD HH:mm:ss"),
                            dustGrade: `${item.preDustGrade ?? 0} -> ${item.dustGrade ?? 0}`,
                            vocGrade: `${item.preVocGrade ?? 0} -> ${item.vocGrade ?? 0}`,
                            termTime: timeFormat(item.termTime)
                        }
                    }));
                } else {
                    setTotal(0);
                    setList([]);
                }
            });
        } catch {
            setTotal(0);
            setList([]);
        }
    }

    const onClosePopup = () => {
        props.onClose(() => {
            setList([]);
            setPageNum(1);
            setTotal(0);
            tableList = [];
        });
    }
    const getSdName = (list) => {
        const name = Object.keys(list).find((key) => key.includes("sd") && list[key] === true);
        return translator[name];
    }

    const firDriveHeader = ["aggDay", "carNo", "driveScore", "distance"];
    const firDriveColumns = firDriveHeader.map((key) => {
        return {
            title: translator[key],
            dataIndex: key,
            key,
            align: 'center',
            render: (v, d) => {
                if (key ==="driveScore") {
                    return <div>{v}점</div>
                } else if (key ==="distance") {
                    return <div>{v}km</div>
                } else {
                    return v
                }
            }
        }
    });

    const firAirHeader = ["aggDay", "carNo", "dustScore", "termTime"];
    const firAirColumns = firAirHeader.map((key) => {
        if (key === "termTime") {
            return {
                title: `총 ${translator[key]}`,
                dataIndex: key,
                key,
                align: 'center',
                render: (v) => <div>{timeFormat(v)}</div>
            }
        } else {
            return {
                title: translator[key],
                dataIndex: key,
                key,
                align: 'center',
                render: (v) => {
                    if (key ==="dustScore") {
                        return <div>{v}점</div>
                    } else {
                        return v
                    }
                }
            }
        }
    });

    const sdHeader = ["sdAcceleration", "sdStart", "sdBreak", "sdStop", "sdLane", "sdSpin", "sdUturn"];
    const sdColumns = sdHeader.map((key) => {
        return {
            title: translator[key],
            dataIndex: key,
            key,
            align: 'center',
            render: (v, d) => {
                return <div>{v ? `${v}회` : "0회"}</div>
            }
        }
    });

    const driveHeader = ["date", "sdName", "deviceCd", "speed", "steering", "odo"];
    const driveColumns = driveHeader.map((key) => {
        return {
            title: translator[key],
            dataIndex: key,
            key,
            align: 'center',
            render: (v, d) => {
                if (key === "odo") {
                    let value = v;
                    if (d.deviceCd === "GPS") {
                        value = v / 1000;
                    }
                    return value + 'km'
                } else {
                    return v
                }
            }
        }
    });

    const airHeader = ["date", "dustGrade", "vocGrade", "termTime"];
    const airColumns = airHeader.map((key) => {
        return {
            title: translator[key],
            dataIndex: key,
            key,
            align: 'center',
        }
    });

    const dustHeader = ["미세먼지", "VOC"];
    const childVocHeader = ["voc1", "voc2" , "voc3"];
    const childDustHeader = ["dust1", "dust2", "dust3"];

    const childVocAndDustHeader = (key) => {
        if (key === "VOC") {
            return childVocHeader;
        } else {
            return childDustHeader;
        }
    }

    const dustColumns = dustHeader.map((key) => {
        return {
            title: key,
            children: childVocAndDustHeader(key).map((k) => {
                return {
                    title: translator[k],
                    dataIndex: k,
                    key : k,
                    width: '110px',
                    align: 'center',
                    render: (v, d) => {
                        if (v === 0) {
                            return <div>0%</div>
                        }
                        if (k.includes("voc")) {
                            return <div>{(v * 100 / (d.termTime-d.voc0)).toFixed(1)}%</div>
                        } else {
                            return <div>{(v * 100 / (d.termTime-d.dust0)).toFixed(1)}%</div>
                        }
                    }
                }
            }),
        }
    });

    return (
        <>
            {props.isShow &&
                <div className="modal-wrap">
                    <div className="modal-content">
                        { props?.tab === "drive" ?
                            <div>
                                <div className="cru-modal-table empty-image-none">
                                    <Table
                                        dataSource={tableList}
                                        columns={firDriveColumns}
                                        pagination={false}
                                    />
                                </div>
                                <div className="cru-modal-table empty-image-none">
                                    <Table
                                        dataSource={tableList}
                                        columns={sdColumns}
                                        pagination={false}
                                    />
                                </div>
                                <div className="cru-modal-table">
                                    <Table
                                        dataSource={list}
                                        size={"small"}
                                        columns={driveColumns}
                                        pagination={{
                                            current: page,
                                            onChange: (page) => setPageNum(page),
                                            pageSize: 6,
                                            total: total,
                                            position: ["bottomCenter"],
                                            showSizeChanger: false,
                                        }}
                                    />
                                </div>
                            </div>
                            :
                            <div>
                                <div className="cru-modal-table empty-image-none">
                                    <Table
                                        dataSource={tableList}
                                        columns={firAirColumns}
                                        pagination={false}
                                    />
                                </div>
                                <div className="cru-modal-table empty-image-none">
                                    <Table
                                        dataSource={tableList}
                                        columns={dustColumns}
                                        pagination={false}
                                    />
                                </div>
                                <div className="cru-modal-table">
                                    <Table
                                        dataSource={list}
                                        size={"small"}
                                        columns={airColumns}
                                        pagination={{
                                            current: page,
                                            onChange: (page) => setPageNum(page),
                                            pageSize: 6,
                                            total: total,
                                            position: ["bottomCenter"],
                                            showSizeChanger: false,
                                        }}
                                    />
                                </div>
                            </div>
                        }
                    </div>
                    <div className="btn-wrap">
                        <button className="btn btn-navy" onClick={onClosePopup}>확인</button>
                    </div>
                </div>
            }
        </>
    )
}

export default CruModal;
