import { axiosInstance } from "../index";


const admin = {
    GetAdminUser: async (data) => {
        const result = await axiosInstance.post(`/admin/user/get`, data);
        return result.data;
    },
    AddAdminUser: async (data) => {
        const result = await axiosInstance.post(`/admin/user/add`, data);
        return result.data;
    },
    ModifyAdminUser: async (data) => {
        const result = await axiosInstance.post(`/admin/user/modify`, data);
        return result.data;
    },
    DeleteAdminUser: async (data) => {
        const result = await axiosInstance.post(`/admin/user/delete`, data);
        return result.data;
    }
}

export default admin;