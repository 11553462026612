import dayjs from "dayjs";
import moment from "moment";
export const stringSorter = (a, b, key) => {
    if (!a[key]) return 1;
    if (!b[key]) return -1;
    return a[key].localeCompare(b[key]);
};

export const dateSorter = (a, b, key) => {
    let aResult = a[key];
    let bResult = b[key];
    return dayjs(aResult).unix() - dayjs(bResult).unix();
};

export const setCurrentUrl = (url) => {
    let currentUrl = window.sessionStorage.getItem('CURRENT_URL');
    if (currentUrl) {
        window.sessionStorage.setItem('PREVIOUS_URL', currentUrl);
    }
    window.sessionStorage.setItem('CURRENT_URL', url);
}

export const getPreviousUrl = () => {
    return window.sessionStorage.getItem('PREVIOUS_URL');
}

export const removePreviousUrl = () => {
    return window.sessionStorage.removeItem('PREVIOUS_URL');
}

export const setSearchData = (data) => {
    window.sessionStorage.setItem('SEARCH', JSON.stringify(data));
}

export const getSearchData = () => {
    return JSON.parse(window.sessionStorage.getItem('SEARCH'));
}

export const removeSearchData = () => {
    return window.sessionStorage.removeItem('SEARCH');
}

export const trimObject = (obj) => {
    var regex = /\s/gi;
    Object.keys(obj).forEach((key) => {
        if (typeof(obj[key]) === "string" && !key.includes("Dt")) {
            obj[key] = obj[key].replace(regex, '').replace(/-/g, '');
        }
    })
    return obj;
}

export const timeFormat = (time) => {
    if (time < 60) {
        return '00:00:' + addZero(time)
    }
    var h = Math.floor(time / 3600);
    var m = Math.floor((time % 3600) / 60);
    var s = time % 60;
    function addZero(num) {
        return (num < 10 ? '0' : '') + num
    }
    return `${addZero(h)}:${addZero(m)}:${addZero(s)}`
}

export const minNumHypen = (str) => {
    let result;
    if (!str) str = "";
    str = str.replace(/[^0-9*]/g, '');
    if (str.length > 13) {
        result = str.slice(0, 13).replace(/^(\d{0,6})([\d\\*]{0,7})$/g, '$1-$2').replace(/-{1,2}$/g, '');
    } else {
        result = str.replace(/^(\d{0,6})([\d\\*]{0,7})$/g, '$1-$2').replace(/-{1,2}$/g, '');
    }

    return result.replace(/-(.)(.*)/g, function(match, p1, p2) {
        return "-" + p1 + "*".repeat(p2.length);
    });;
}

export const bizRegNumHypen = (str) => {
    if (!str) str = "";
    str = str.replace(/[^0-9]/g, '')
    if (str.length > 10) {
        return str.slice(0, 10).replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '');
    } else {
        return str.replace(/^(\d{0,3})(\d{0,2})(\d{0,5})$/g, '$1-$2-$3').replace(/-{1,2}$/g, '');
    }
}

export const phoneHypen = (str) => {
    if (!str) str = "";
    str = str.replace(/[^0-9]/g, '');
    if (str.length > 11) {
        str = str.slice(0, 11);
    }

    let tmp = '';
    if (str.substring(0, 2) == "02") {
        if (str.length < 3) {
            return str;
        } else if (str.length < 6) {
            tmp += str.substr(0, 2);
            tmp += '-';
            tmp += str.substr(2);
            return tmp;
        } else if (str.length < 10) {
            tmp += str.substr(0, 2);
            tmp += '-';
            tmp += str.substr(2, 3);
            tmp += '-';
            tmp += str.substr(5);
            return tmp;
        } else {
            tmp += str.substr(0, 2);
            tmp += '-';
            tmp += str.substr(2, 4);
            tmp += '-';
            tmp += str.substr(6, 4);
            return tmp;
        }
    } else {
        if (str.length < 4) {
            return str;
        } else if (str.length < 7) {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3);
            return tmp;
        } else if (str.length < 11) {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 3);
            tmp += '-';
            tmp += str.substr(6);
            return tmp;
        } else {
            tmp += str.substr(0, 3);
            tmp += '-';
            tmp += str.substr(3, 4);
            tmp += '-';
            tmp += str.substr(7);
            return tmp;
        }
    }
}

export const checkImg = (fileName) => {
    var reg = /(.*?)\.(jpg|jpeg|png|gif|bmp)$/;
    return fileName.match(reg)
}

export const checkEmail = (str) => {
    var regex = /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*\.[a-zA-Z]{2,3}$/i;
    return regex.test(str)
}

export const noneHypen = (str) => {
    if (!str) str = "";
    if (str !== "") {
        return str.replace(/[^0-9]/g, '');
    }
}

export const checkLen5 = (str) => {
    if (!str) str = "";
    str = str.replace(/[^0-9]/g, '');
    if (str.length > 5) {
        str = str.slice(0, 5);
    }
    return str
}

export const checkLen2 = (str) => {
    if (!str) str = "";
    str = str.replace(/[^0-9]/g, '');
    if (str.length > 2) {
        str = str.slice(0, 2);
    }
    return str
}
export const utcToLocalformat = (date) => {
    let datetime = moment(new Date(date)).format("YYYYMMDD000000");
    return datetime;
}
export const dateformat = (date) => {
    let datetime = moment(new Date(date)).format("YYYY-MM-DD");
    return datetime;
}

export const numberFormat = (num) => {
    return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export const hexToNum = (str, len) => {
    let i = 0;
    let arr = [];
    while (i < str.length) {
        arr.push(str.substr(i,len));
        i += len;
    }
    const numArr = arr.map((item) => {
        return parseInt(item, 16).toString();
    });
    return numArr;
}

export const numToHex2Byte = (num) => {
    var hex = parseInt(num).toString(16);
    while(hex.length < 2) {
        hex = "0" + hex;
    }
    return hex;
}
export const numToHex4Byte = (num) => {
    var hex = parseInt(num).toString(16);
    while(hex.length < 4) {
        hex = "0" + hex;
    }
    return hex;
}

export const getGraphLabel = (start, end) => {
    let date = [];
    let a = start;

    while (a !== end) {
        date.push(a);

        const aDate = new Date(a);
        const yesterday = new Date(aDate.setDate(aDate.getDate() + 1));
        a = dayjs(yesterday).format("YYYY-MM-DD");
    }
    date.push(end);
    return date;
}

export const getGraphData = (data, start) => {
    let graph = [];
    let bizName = "";
    let scoreType = ""
    let nameType = "bizNm"

    if (data[0]?.driveScore >= 0) {
        scoreType = "driveScore"
    }

    if (data[0]?.dustScore >= 0) {
        scoreType = "dustScore"
    }

    if (!data[0]?.bizNm) {
        nameType = "cruNm"
    }

    data.forEach((item) => {
        if (bizName === item[nameType]) return;
        bizName = item[nameType];

        let sameBizGraph = [0, 0, 0, 0, 0, 0, 0, 0];
        const sameBizData = data.filter((i) => i[nameType] === item[nameType]);
        sameBizData.forEach((item2) => {
            const idx = noneHypen(item2.aggDay) - noneHypen(start);
            sameBizGraph[idx] = item2[scoreType];
        })
        graph.push(
            {
                label: item[nameType],
                data: sameBizGraph,
                frnSeq: item.frnSeq
            }
        )
    })
    // 앞, 뒤로 2,2개씩만 출력
    if (graph.length > 4) {
        graph.splice(2, graph.length - 4);
    }
    return graph
}


