import "./ContentCard.scss";
import {ResponsivePie} from "@nivo/pie";
import DropDown from "../DropDown/DropDown";
import {Line} from "react-chartjs-2";

const ContentCard = (props)=>{
    return (
        <div onClick={props.onClick} ref={props.useRef}>
            {props.cardType === "normal" &&
                <div className="content-card-wrap">
                    <h2>{props.title}</h2>
                    {props.children}
                </div>
            }
            {props.cardType === "background" &&
                <div className="content-card-wrap card-background">
                    <h2>{props.title}</h2>
                    {props.children}
                </div>
            }
            {props.cardType === "fill" &&
                <div className="content-card-wrap card-fill">
                    <h2>{props.title}</h2>
                    {props.children}
                </div>
            }
            {props.cardType === "graph" &&
                <div className="content-card-wrap card-graph">
                    <h2>{props.title}</h2>
                    {props.children}
                </div>
            }
            {props.cardType === "wide" &&
                <div className="content-card-wrap card-wide">
                    <h2>{props.title}</h2>
                    {props.children}
                </div>
            }
            {props.cardType === "small"&&
                <div className={props.theme ? `${props.theme} content-card-wrap card-small`:"content-card-wrap card-small"}>
                    <h2>{props.title}{props.icon}</h2>
                    {props.children}
                </div>
            }
            {props.cardType === "mini"&&
                <div className={props.theme ? `${props.theme} content-card-wrap card-mini`:"content-card-wrap card-mini"}>
                    <h2>{props.icon}{props.title}</h2>
                    {props.children}
                </div>
            }
            {props.cardType === "wide-graph" &&
                <div className="content-card-wrap wide-graph">
                    <h2>{props.title}</h2>
                    <p>평균<span style={{color: props.color}} >{props.aggValue}</span>점</p>
                    <div className="graph-wrap">
                        <Line
                            data={props.graphData}
                            options={{
                                maintainAspectRatio: false,
                                responsive: true,
                                borderColor: props.color,
                                borderWidth: 1,
                                plugins: {
                                    legend: {
                                        display: true,
                                        position: "bottom",
                                        labels: { // labels 에 대한 스타일링
                                            padding: 15,
                                            color: '#333',
                                            font:{
                                                size: 10
                                            },
                                            usePointStyle: true,
                                            pointStyle: "circle",
                                        },
                                    },
                                    filler: {
                                        propagate: false, // 그라데이션을 그래프의 전체 영역으로 확장하지 않음
                                    },
                                    tooltip: {
                                        enabled: true,
                                        mode: 'index',
                                        intersect: false,
                                        backgroundColor: props.tooltipColor,
                                        bodyFont: {
                                            size: 12,
                                        },
                                        callbacks: {
                                            title: function (tooltipItems, data) {
                                                return tooltipItems[0].label;
                                            },
                                            label: function (tooltipItem, data) {
                                                return tooltipItem.dataset.label + " : " + tooltipItem.formattedValue + "점";
                                            },
                                        },
                                    },
                                },
                                scales:{
                                    x: {
                                        offset: 15,
                                        grid:{
                                            display: false
                                        },
                                        ticks: {
                                            autoSkip: false, // 자동 스킵 비활성화
                                        },
                                    },
                                    y: {
                                        grid:{
                                            display: false,
                                        },
                                        min: 0
                                    },
                                },

                            }}
                        />
                    </div>
                    {props.graphData?.datasets?.length == 0 &&
                        <>
                            <div className="not-graph-data"></div>
                            <div className="not-graph-data-text">최근 일주일간 데이터가 없습니다</div>
                        </>
                    }
                </div>
            }
            {props.cardType === "rank-drive" &&
                <div className="content-card-wrap card-rank">
                    <h2>{props.title}
                        <DropDown
                            width={170}
                            options={props.sortDropDown}
                            onChange={props.onChange}
                            value={props.selectValue}
                        />
                    </h2>
                    <div className="content-info-wrap">
                        <div className="content-info top">
                            <h3><img src="/assets/icons/icon_trophy.svg" style={{ marginRight: "8px" }} alt='주행별운행현황' />{props.list[0]?.name}</h3>
                            {props.selectValue === 0 &&
                                <>
                                    <h2><span>{props.list[0]?.distance ?? "0"}</span>km</h2>
                                    <div>
                                        <p><span>{props.list[0]?.numCnt ?? "0"}</span>회</p>
                                        <p>|</p>
                                        <p><span>{props.list[0]?.termTime ?? "0"}</span>시간</p>
                                    </div>
                                </>
                            }
                            {props.selectValue === 1 &&
                                <>
                                    <h2><span>{props.list[0]?.termTime ?? "0"}</span>시간</h2>
                                    <div>
                                        <p><span>{props.list[0]?.numCnt ?? "0"}</span>회</p>
                                        <p>|</p>
                                        <p><span>{props.list[0]?.distance ?? "0"}</span>km</p>
                                    </div>
                                </>
                            }
                            {props.selectValue === 2 &&
                                <>
                                    <h2><span>{props.list[0]?.numCnt ?? "0"}</span>회</h2>
                                    <div>
                                        <p><span>{props.list[0]?.termTime ?? "0"}</span>시간</p>
                                        <p>|</p>
                                        <p><span>{props.list[0]?.distance ?? "0"}</span>km</p>
                                    </div>
                                </>
                            }
                        </div>
                        {
                            props.list.map((v,i,a)=>{
                                return i>0 && (
                                    <div className="content-info">
                                        <h3>{v?.name}</h3>
                                        <div>
                                            <p><span>{v?.distance}</span>km</p>
                                            <p>|</p>
                                            <p><span>{v?.numCnt}</span>회</p>
                                            <p>|</p>
                                            <p><span>{v?.termTime}</span>시간</p>
                                        </div>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            }
            {props.cardType === "rank-grade" &&
                <div className="content-card-wrap card-rank">
                    <h2>{props.title}
                        <DropDown
                            width={170}
                            options={props.sortDropDown}
                            onChange={props.onChange}
                            value={props.selectValue}
                        />
                    </h2>
                    <div className="content-info-wrap">
                        <div className="content-info top">
                            <h3><img src="/assets/icons/icon_trophy.svg" style={{ marginRight: "8px" }} alt='점수별운행현황'/>{props.list[0]?.name}</h3>
                            <h2><img src="/assets/icons/icon_drive_blue.svg" alt='안전운전점수'/><span className="color-blue">{props.list[0]?.driveScore}</span>점 | <img src="/assets/icons/icon_drive_air.svg" alt='공기관리점수'/><span className="color-green">{props.list[0]?.dustScore}</span>점</h2>
                        </div>
                        {
                            props.list.map((v,i,a)=>{
                                return i > 0 && (
                                    <div className="content-info">
                                        <h3>{v?.name}</h3>
                                        <p><img src="/assets/icons/icon_drive_gray.svg" alt='안전운전점수'/><span>{v.driveScore}</span>점 | <img src="/assets/icons/icon_drive_air_gray.svg" alt='공기관리점수'/><span>{v.dustScore}</span>점</p>
                                    </div>
                                );
                            })
                        }
                    </div>
                </div>
            }
            {props.cardType === "pie-long" &&
                <div className="content-card-wrap card-long-pie">
                    <h2>{props.title}</h2>
                    <div className="graph-content">
                        <ResponsivePie
                            data={props.data}
                            animate={true}
                            enableArcLinkLabels={false}
                            innerRadius={0.5}
                            margin={{ top: Number(props.margin), bottom: 0 }}
                            tooltip={({datum}) => {
                                if (props.data.length === 1 && props.data[0].id === 0) return;
                                return (
                                    <div
                                        style={{
                                            background: `${datum.color}`,
                                            color: '#ffffff',
                                            padding: '4px 8px',
                                            border: '1px solid #ffffff',
                                            borderRadius: '6px'
                                        }}
                                    >
                                        {datum.data.name} {datum.value}명
                                    </div>
                                )
                            }}
                            legends={[
                                {
                                    anchor: 'bottom',
                                    direction: 'column',
                                    itemsSpacing: 0,
                                    itemWidth: 180,
                                    itemHeight: 16,
                                    symbolShape: 'circle',
                                    symbolSize: 14,
                                    itemTextColor: '#601F19',
                                    effects: [
                                        {
                                            on: 'hover',
                                            style: {
                                                itemTextColor: '#000'
                                            }
                                        }
                                    ],
                                },
                            ]}
                            colors={["#F04E3E", "#C03E32", "#902F25", "#601F19", "#F6958B", "#F37165"]}
                            theme={{
                                labels: {
                                    text: {display: "none",},
                                },
                                legends: {
                                    text: {
                                        fontSize: 12,
                                        fill: '#000000',
                                    },
                                },
                            }}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default ContentCard;