import "./Alert.scss";

const AlertMessage = (props)=>{
    //TODO token 필요한 페이지 만들어지면 jwt 만료로 테스트
    return (
        <>
            {props.isShow &&
                <div className="alert-wrap" style={{ height: props.height }}>
                    <div className="alert-header">
                        <h2 className="">{props.title ?? '알림'}</h2>
                        <i className="" onClick={props.onClose} />
                    </div>
                    {props.content}
                    <div className="btn-wrap">
                        <button className="btn-cancel" onClick={props.onClose}>취소</button>
                        <button className="btn-add" onClick={props.onOk}>확인</button>
                    </div>
                </div>
            }
        </>
    )
}

export default AlertMessage;