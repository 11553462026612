import "./DropDown.scss";
import React from "react";
import { ConfigProvider, Select } from 'antd';

const FrnSearchDrop = (props)=>{
    return (
        <ConfigProvider
            theme={{
                token: {
                    borderRadius: 12,
                    colorBorder: "#D3D4D5",
                    colorBgContainer: '#fff',
                    fontSize: 15,
                    colorPrimary: "#7688A8"

                },
                components: {
                    Select: {
                        optionFontSize: 15,
                        optionHeight: 48,
                        optionLineHeight: 2
                    }
                }
            }}
        >
            <Select
                showSearch
                style={{
                    width: 247,
                    height: 48
                }}
                disabled={props.readOnly}
                suffixIcon={props.readOnly ? <></> : <img src="/assets/icons/icon_search.svg" alt="search" />}
                placeholder="운수사 이름을 입력하세요"
                optionFilterProp="children"
                onChange={props.onChange}
                value={props.value}
                filterOption={(input, option) => (option?.label ?? '').includes(input)}
                filterSort={(optionA, optionB) => {
                    if (optionA?.value === 1) {
                        return -1
                    } else if (optionB?.value === 1) {
                        return 1
                    } else {
                        return (optionA?.value ?? '').toString().toLowerCase().localeCompare((optionB?.value ?? '').toString().toLowerCase());
                    }
                }}
                options={props.options}
                onInputKeyDown={props.onKeyPress}
            />
        </ConfigProvider>
    );
}

export default FrnSearchDrop;