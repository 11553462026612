import "./Alert.scss";
import translator from "../../utils/translator";

const ImageAlert = (props)=>{
    const onClick = ()=>{

    }
    const onLoad = (e)=>{
        console.log(e.target.width, e.target.height)
        if(e.target.width > e.target.height){
            let width = e.target.width;
            let height = e.target.height;
            e.target.style.transform = `rotate(90deg) translateX(-${height+ (width - height)}px)`;
            e.target.style.transformOrigin = "bottom left";
            e.target.style.height = height + (width - height) + "px";
            e.target.style.maxWidth = "none";
            e.target.style.width = Math.floor(width * width / height) + "px";
        }
    }
    return (
        <>
            {
                props.isShow &&
                <div className="image-alert-wrap" >
                    <h2>
                        <p>{props.title}<span>{props.carNo && props.carNo}</span></p>
                        {props.type && props.type == "stat" && (props.showStat == "R" || props.showStat == "N") &&
                            <div className="alert-stat-btn-wrap">
                                <div className="btn-small btn-line-gray" onClick={props.onAccept}>승인</div>
                                <div className="btn-small btn-line-gray" onClick={props.onRefuse}>승인 보류</div>
                                <div className="btn-small btn-line-gray" onClick={props.onClose}>닫기</div>
                            </div>
                        }
                        {props.type && props.type == "stat" && props.showStat !== "R" && props.showStat !== "N" &&
                            <img src="/assets/icons/icon_close.svg" alt="close" onClick={props.onClose} />
                        }
                        {!props.type &&
                            <img src="/assets/icons/icon_close.svg" alt="close" onClick={props.onClose} />
                        }
                    </h2>
                    <div className="alert-column">
                        {props.options &&
                            Object.keys(props.options).map((v) => (
                                // console.log(v)
                                <div className="alert-content" key={v.key}>
                                    <div className="key">{translator[v]}</div>
                                    <div className="value">{props.options[v]}</div>
                                </div>
                            ))}
                    </div>
                    <div className="alert-img">
                        {props.imgSrc &&
                            <a href={props.imgSrc} target="_blank">
                                <img src={props.imgSrc} onLoad={onLoad} />
                            </a>
                            ||
                            <div className="noneImg">
                                <p>등록된 이미지가 없습니다.</p>
                            </div>
                        }

                    </div>
                </div>
            }
        </>

    )
}

export default ImageAlert;
